import React, { useEffect, useState } from "react";
import Theme from "../../theme";
import Countdown from "react-countdown";
import PatoIcon from "../../assets/images/patoicon.png";
import api from "../../service";
import CloseIcon from "@mui/icons-material/Close";
import DeliveryLogo from "../../assets/images/deliveryIcon.svg";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

const formatDate = (date, hour) => {
  if (hour.includes(":")) hour = hour.split(":")[0];
  let eventDate = new Date(date);
  eventDate = new Date(
    eventDate.getUTCFullYear(),
    eventDate.getUTCMonth(),
    eventDate.getUTCDate(),
    hour,
    0,
    0
  );
  return (
    new Intl.DateTimeFormat("pt-br", {
      weekday: "long",
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    }).format(eventDate) +
    " às " +
    new Intl.DateTimeFormat("pt-br", {
      hour: "numeric",
      minute: "numeric",
    }).format(eventDate)
  );
};

function WaitingConfirm(order) {
  const [orderDate, setOrderDate] = useState();
  const [orderServices, setOrderServices] = useState();
  const [popUp, setPopUp] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [selectedHours, setSelectedHours] = useState([]);

  let showAddress = false;

  const renderer = ({ seconds, minutes, completed, formatted }) => {
    if (completed) {
      return (
        <p
          style={{
            fontWeight: 700,
            fontFamily: "Poppins",
            fontSize: 72,
          }}
        >
          00:00
        </p>
      );
    } else {
      return (
        <p
          style={{
            fontWeight: 700,
            fontFamily: "Poppins",
            fontSize: 72,
          }}
        >
          {formatted.minutes}:{formatted.seconds}
        </p>
      );
    }
  };

  //console.log(order);

  useEffect(() => {
    (async () => {
      let { data } = await api.provider.getUser();
      let user = data.data.info;

      const localDate = new Date();
      const orderDate = new Date(order.order.service_date);

      let category = order.order.services[0].service.category._id;
      let weekday = orderDate.getDay();
      const nowH = parseInt(new Date().getHours());

      const forToday =
        localDate.getDate() === orderDate.getDate() &&
        localDate.getMonth() === orderDate.getMonth() &&
        localDate.getFullYear() === orderDate.getFullYear();

      let result = user.schedule.filter(
        (schedule) => schedule.category === category
      );
      let weekDayHour = result[0].week[weekday];
      let openHours = [];

      if (result.length > 0 && weekDayHour && weekDayHour.enabled === true) {
        if (forToday) {
          for (let h = nowH; h <= 23; h++) {
            let hr = h <= 9 ? "0" + h + ":00" : h + ":00";
            if (!weekDayHour.off_hours.includes(hr)) openHours.push(hr);
          }
        } else {
          for (let h = 0; h <= 23; h++) {
            let hr = h <= 9 ? "0" + h + ":00" : h + ":00";
            if (!weekDayHour.off_hours.includes(hr)) openHours.push(hr);
          }
        }
      }
      setSchedule(openHours);
    })();
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#fff",
        border: "1px solid #E0E0E0",
        borderRadius: 10,
        width: "90%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        paddingBottom: 20,
        marginTop: "2%",
      }}
    >
      <div
        style={{
          width: "100%",
          height: 12,
          borderRadius: "8px 8px 0 0",
          position: "relative",
          top: 0,
          backgroundColor: Theme.colors.pink2,
        }}
      />
      <div
        style={{
          width: "100%",
          margin: "30px 0 10px",
          display: "flex",
          height: 70,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: Theme.colors.pink2,
            height: 20,
            width: 20,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              fontFamily: "Poppins",
              position: "relative",
              whiteSpace: "nowrap",
              top: -30,
            }}
          >
            Pedido {order.order.id} - Aguardando confirmação
          </p>
        </div>
        <div
          style={{
            backgroundColor: "#D2D2D2",
            height: 2,
            width: "20%",
          }}
        />
        <div
          style={{
            backgroundColor: "#fff",
            border: "2px solid #D2D2D2",
            height: 20,
            width: 20,
            borderRadius: "50%",
          }}
        ></div>
        <div
          style={{
            backgroundColor: "#D2D2D2",
            height: 2,
            width: "20%",
          }}
        />
        <div
          style={{
            backgroundColor: "#fff",
            border: "2px solid #D2D2D2",
            height: 20,
            width: 20,
            borderRadius: "50%",
          }}
        />
        <div
          style={{
            backgroundColor: "#D2D2D2",
            height: 2,
            width: "20%",
          }}
        />
        <div
          style={{
            backgroundColor: "#fff",
            border: "2px solid #D2D2D2",
            height: 20,
            width: 20,
            borderRadius: "50%",
          }}
        />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <p
            style={{
              fontFamily: "Poppins",
              fontSize: 16,
              marginBottom: 20,
              marginLeft: 60,
            }}
          >
            Serviços:
          </p>
          <div
            style={{
              maxHeight: 300,
              width: 350,
              overflow: "auto",
              direction: "rtl",
            }}
          >
            {order.order.delivery_service && (
              <div
                style={{
                  background: "#fafafa",
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  textAlign: "left",
                  borderRadius: 20,
                }}
              >
                <p
                  style={{
                    fontWeight: 600,
                    width: "100%",
                    fontFamily: "Poppins",
                    fontSize: 16,
                    textAlign: "center",
                  }}
                >
                  Serviço de busca
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 7,
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "90%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      flexDirection: "row",
                      alignItems: "start",
                      padding: "2%",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 12,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flext-start",
                        textAlign: "left",
                      }}
                    >
                      <p>
                        {order.order.address.street},{" "}
                        {order.order.address.number}{" "}
                      </p>
                      <p>
                        {order.order.address.district} -{" "}
                        {order.order.address.complement}
                      </p>
                      Hora: {console.log(new Date(order.order.delivery_date))}
                      {new Date(order.order.delivery_date).toLocaleTimeString(
                        "pt-BR",
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                          timeZone: "America/Sao_Paulo",
                        }
                      )}{" "}
                      - {order.order.hour}:00
                    </p>
                  </div>

                  <img
                    src={api.utils.getImage("delivery")}
                    style={{
                      width: 50,
                      height: 50,
                      marginRight: 10,
                    }}
                    onClick={() => console.log(order)}
                  />
                </div>

                <div style={{ display: "flex", width: "100%" }}>
                  <p
                    style={{
                      fontWeight: 600,
                      width: "100%",
                      fontSize: 20,
                      fontFamily: "Poppins",
                      fontSize: 16,
                      textAlign: "center",
                    }}
                  >
                    R${order.order.delivery_service_value}
                  </p>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 20,
                      marginRight: 5,
                    }}
                  ></p>
                </div>
              </div>
            )}
            {order.order.services.map((service) => {
              if (
                service.service.category.code === 5 ||
                service.service.category.code === 6
              )
                showAddress = true; // setShowAddress(true)
              return (
                <div
                  style={{
                    background: "#fafafa",
                    width: 300,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px 0px 10px 20px",
                    textAlign: "left",
                    borderRadius: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ display: "flex", marginBottom: 7 }}>
                    {/* <div
                    style={{
                      width: 50,
                      height: 50,
                      marginRight: 5,
                      backgroundColor: "red",
                    }}
                  /> */}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginRight: 20,
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 600,
                          fontFamily: "Poppins",
                          fontSize: 16,
                        }}
                      >
                        {service.service.category.code == 4
                          ? service.service.category.name
                          : service.service.sub_category.name}
                      </p>
                      <p
                        style={{
                          fontFamily: "Poppins",
                          fontSize: 12,
                          marginTop: 10,
                        }}
                      >
                        {" "}
                        {service.description != ""
                          ? service.description
                          : "Sem mais informações"}
                      </p>
                    </div>
                    <img
                      src={service?.service?.category?.image}
                      style={{
                        width: 50,
                        height: 50,
                        marginRight: 10,
                      }}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <p
                      style={{
                        fontWeight: 700,
                        fontFamily: "Poppins",
                        fontSize: 22,
                      }}
                    >
                      R$
                      {service.value.toString().includes(".") &&
                      service.value != 0
                        ? service.value.toString().split(".").length >= 2
                          ? service.value.toString().replace(".", ",")
                          : `${service.value.toString().replace(".", ",")}0`
                        : `${service.value},00`}
                    </p>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 20,
                        marginRight: 5,
                      }}
                    ></p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 3,
            marginLeft: 20,
          }}
        >
          <p
            style={{
              fontFamily: "Poppins",
              fontSize: 16,
              marginBottom: 20,
            }}
          >
            Detalhes:
          </p>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p
              style={{
                fontSize: 15,
                fontWeight: 600,
                fontFamily: "Poppins",
                marginTop: 20,
              }}
            >
              Cliente: {order.order.people.name}
            </p>
            <p
              style={{
                fontSize: 15,
                fontWeight: 600,
                fontFamily: "Poppins",
                marginTop: 20,
              }}
            >
              Data:{" "}
              {format(order.order.service_date, "EEEE, dd MMMM", {
                locale: ptBR,
                timeZone: "America/Sao_Paulo",
              })}{" "}
              às {order.order.hour}
            </p>
            <p
              style={{
                fontSize: 15,
                fontWeight: 600,
                fontFamily: "Poppins",
                marginTop: 20,
              }}
            >
              PET:
              <span> {order.order.pet.name}</span>
              {", "}
              {order.order.pet.breed.name
                ? order.order.pet.breed.name
                : "Sem raça definida"}
              , porte {order?.order?.pet?.size?.initials}
            </p>
            <div
              style={{
                display: showAddress ? "flex" : "none",
                justifyContent: "start",
                flexDirection: "row",
                alignItems: "start",
                marginTop: "1%",
              }}
            >
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: 14,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flext-start",
                }}
              >
                <p>
                  {order.order.address.street}, {order.order.address.number}{" "}
                </p>
                <p>
                  {order.order.address.district}{" "}
                  {order.order.address.complement}
                </p>
              </p>
            </div>
            <p
              style={{
                fontSize: 15,
                fontWeight: 600,
                fontFamily: "Poppins",
                marginTop: 20,
              }}
            >
              Total: R$
              {order.order.service_value + order.order.delivery_service_value}
            </p>
          </div>
        </div>

        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            margin: "0 30px 30px 0",
          }}
        >
          <div
            style={{
              display: "none",
              flex: 2,
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p style={{ fontFamily: "Poppins", fontSize: 12 }}>
              Tempo Restante
            </p>
            {/* <Countdown renderer={renderer} date={order.order.countdown} /> */}
          </div>
          <div
            style={{
              position: "relative",
              bottom: 0,
              display: "flex",
              flexDirection: "column",
              height: 190,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <button
              onClick={async () => {
                await api.provider.cancelOrder({
                  order: order.order._id,
                });
              }}
              style={{
                fontFamily: "Poppins",
                fontSize: 16,
                fontWeight: "bold",
                borderWidth: 3,
                borderStyle: "solid",
                borderColor: Theme.colors.pink2,
                backgroundColor: "#fff",
                color: Theme.colors.pink2,
                width: 300,
                height: 55,
                borderRadius: 12,
                cursor: "pointer",
              }}
            >
              Cancelar Compra
            </button>
            <button
              onClick={() => {
                setPopUp(true);
              }}
              style={{
                fontFamily: "Poppins",
                fontSize: 16,
                fontWeight: "bold",
                borderWidth: 3,
                borderStyle: "solid",
                borderColor: Theme.colors.pink2,
                backgroundColor: "#fff",
                color: Theme.colors.pink2,
                width: 300,
                height: 55,
                borderRadius: 12,
                cursor: "pointer",
              }}
            >
              Sugerir novo horário
            </button>
            <button
              onClick={async () => {
                await api.provider.confirmPendingSells({
                  order: order.order._id,
                });
              }}
              style={{
                fontFamily: "Poppins",
                fontSize: 16,
                fontWeight: "bold",
                backgroundColor: Theme.colors.pink2,
                border: "none",
                color: "#fff",
                width: 300,
                height: 55,
                borderRadius: 12,
                cursor: "pointer",
              }}
            >
              Confirmar Pet-in
            </button>
          </div>
          <div
            style={{
              display: popUp ? "flex" : "none",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                boxShadow: "0 0 100px 100px rgba(0, 0, 0, 0.5)",
                backgroundColor: "#fff",
                borderRadius: 10,
                width: 750,
                zIndex: 9,
              }}
            >
              <div
                style={{
                  height: 50,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: 20,
                }}
              >
                <h2
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: 24,
                  }}
                >
                  Selecione o novo horário que deseja sugerirgg
                </h2>
                <div
                  onClick={() => setPopUp(false)}
                  style={{
                    position: "relative",
                    top: -2,
                    backgroundColor: Theme.colors.pink2,
                    width: 35,
                    height: 35,
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <CloseIcon
                    style={{
                      fontSize: 28,
                      color: "#fff",
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  flexWrap: "wrap",
                  margin: "0 10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {schedule.map((hour, index) => {
                  return (
                    <button
                      onClick={() => {
                        if (selectedHours.includes(hour)) {
                          var filtered = selectedHours.filter(
                            (value) => value != hour
                          );
                          setSelectedHours(filtered);
                        } else if (!selectedHours.includes(hour)) {
                          setSelectedHours([...selectedHours, hour]);
                        }
                      }}
                      key={index}
                      style={{
                        width: 100,
                        height: 48,
                        padding: 10,
                        border: selectedHours.includes(hour)
                          ? "none"
                          : "1px solid #C5DBEC",
                        borderRadius: 8,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 5,
                        color: selectedHours.includes(hour)
                          ? "#000"
                          : "#011627",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        cursor: "pointer",
                        backgroundColor: selectedHours.includes(hour)
                          ? "lightgreen"
                          : "transparent",
                      }}
                    >
                      {hour}
                    </button>
                  );
                })}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "50px 0 20px",
                }}
              >
                <button
                  enabled={selectedHours.length !== 0}
                  onClick={async () => {
                    await api.provider.suggestTime({
                      order: order.order._id,
                      suggested_time: selectedHours,
                    });
                    setPopUp(false);
                  }}
                  style={{
                    fontFamily: "Poppins",
                    fontSize: 16,
                    fontWeight: "bold",
                    backgroundColor:
                      selectedHours.length !== 0
                        ? Theme.colors.pink2
                        : "#bfbfbf",
                    border: "none",
                    color: "#fff",
                    width: 250,
                    height: 50,
                    borderRadius: 12,
                    cursor: "pointer",
                  }}
                >
                  Sugerir horário
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WaitingConfirm;
