import React, { useState } from "react";
import { Render } from "@8_dev/sjson-web";
import Theme from "../../theme";
import Navbar from "../../components/Navbar";

let PasswordForgetConfirm = () => {
  return Render({
    component: "container",
    style: {
      width: "100vw",
      height: "100vh",
      backgroundColor: Theme.colors.backgroundBlue,
      display: "flex",
      flexDirection: "column",
    },
    children: [
      Navbar({
        isAdmin: false,
        context: "login",
      }),
      {
        component: "container",
        style: {
          width: "100%",
          display: "flex",
          justifyContent: "center",
          margin: "80px 0 0 0",
        },
        children: [
          {
            component: "text",
            text: "Redefinição de senha",
            style: {
              fontFamily: "Poppins",
              fontSize: 28,
              fontWeight: "bold",
              margin: "40px 0",
            },
          },
        ],
      },
      {
        component: "container",
        style: {
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        },
        children: [
          {
            component: "container",
            style: {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "45%",
              height: 490,
              backgroundColor: "#fff",
              borderRadius: 8,
              boxShadow: "0px 0px 25px rgba(182, 182, 188, 0.2)",
            },
            children: [
              {
                component: "text",
                text: `Enviamos um link para redefinição de senha para o e-mail [${localStorage.getItem(
                  "email"
                )}]`,
                style: {
                  margin: 60,
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: 20,
                  fontWeight: "bold",
                },
              },
              {
                // Oque fazer. Enviar email para supoerte informado ou it para whats ?
                // onclick
                component: "text",
                text: "Não recebi o e-mail",
                style: {
                  cursor: "pointer",
                  margin: 40,
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: Theme.colors.pink2,
                  textDecoration: "underline",
                },
              },
            ],
          },
        ],
      },
    ],
  });
};

export default PasswordForgetConfirm;
