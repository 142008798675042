import React, { Profiler, useEffect, useState } from "react";
import { Render } from "@8_dev/sjson-web";
import Fade from "react-reveal/Fade";
// import theme from "../theme";
import CurrencyInput from "react-currency-masked-input";
import { Slider, InputNumber } from "antd";
import api from "../../service";
import Navbar from "../../components/Navbar";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import AlertPopUp from "../../components/AlertPopUp";
import { useAlert } from "../../context/alertContext";
import Switch from "@mui/material/Switch";
import { useDropzone } from "react-dropzone";

//images
import EditProvider from "../../assets/images/editar.png";
import Close from "../../assets/images/close.png";
// import { TimePicker } from "@mui/lab";

import { TimePicker } from "antd";
import dayjs from "dayjs"; // Importe a biblioteca dayjs
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const label = { inputProps: { "aria-label": "Switch demo" } };

let TakeAndBring = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [update, setUpdate] = useState(false);

  const [updateErr, setUpdateErr] = useState(false);

  const [price, setPrice] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [deliveryRange, setDeliveryRange] = useState(5);
  const [workSchedule, setWorkSchedule] = useState([]);

  let marks = {};
  for (let i = 0; i <= 10; i += 0.5) {
    marks[i] = {
      style: { fontSize: 12 },
      label: i + "",
    };
  }

  const [profile, setProfile] = useState({});
  const [addrStateProfile, setAddrStateProfile] = useState("");
  const [addrCityProfile, setAddrCityProfile] = useState("");
  const [addrDistrictProfile, setAddrDistrictProfile] = useState("");
  const [addrStreetProfile, setAddrStreetProfile] = useState("");
  const [addrNumberProfile, setAddrNumberProfile] = useState("");
  const [addrComplementProfile, setAddrComplementProfile] = useState("");

  // useEffect(() => {
  //   (async () => {
  //     // let user = JSON.parse(await localStorage.getItem("user"));
  //     let { data } = await api.provider.getUser();

  //     setIsActive(data.data.info.delivery_service);
  //     setPrice(data.data.info.delivery_service_value);
  //     setDeliveryRange(data.data.info.delivery_range);
  //   })();
  // }, []);

  useEffect(() => {
    (async () => {
      let { data } = await api.provider.getUser();
      setProfile(data.data.info);

      setAddrStateProfile(data.data.info.address.state);
      // setAddrCityProfile(data.data.info.address.city);
      setAddrDistrictProfile(data.data.info.address.district);
      setAddrStreetProfile(data.data.info.address.street);
      setAddrNumberProfile(data.data.info.address.number);
      setAddrComplementProfile(data.data.info.address.complement);

      setIsActive(data.data.info.delivery_service);
      setPrice(data.data.info.delivery_service_value.toFixed(2));
      setDeliveryRange(data.data.info.delivery_range);

      let workTime = [];

      if (data.data.info.start_time?.length > 1)
        workTime[0] = dayjs(data.data.info.start_time, "HH:mm");
      if (data.data.info.end_time?.length > 1)
        workTime[1] = dayjs(data.data.info.end_time, "HH:mm");
      setWorkSchedule(workTime);
    })();
  }, []);

  const labelStyle = {
    width: "100%",
    textAlign: "flex-start",
    fontFamily: "Poppins",
    fontWeight: "normal",
    color: "black",
    fontWeight: "400",
    fontSize: "0.88vw",
  };

  const inputStyle = {
    maxHeight: 42,
    minHeight: "8%",
    width: "100%",
    height: "6vh",
    aspectRatio: 3,
    borderRadius: 8,
    border: "1px solid rgba(156, 163, 175, 1)",
    color: "rgba(156, 163, 175, 1)",
    fontSize: "1.1vw",
    fontFamily: "Poppins",
    paddingLeft: 4,
  };

  const [inputFileLabel, setInputFileLabel] = useState(
    "Clique para fazer o upload"
  );

  return Render({
    component: "container",
    style: {
      display: "flex",
      flexDirection: "column",
    },
    children: [
      Navbar({
        isAdmin: false,
        context: "provider",
        active: 6,
      }),
      {
        component: "container",
        style: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "100%",
        },
        children: [
          //Meus dados
          {
            component: "container",
            style: {
              width: "75%",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(155,155,155,0.1)",
              borderRadius: 10,
              padding: 20,
              marginTop: 20,
            },
            children: [
              {
                component: "container",
                style: {
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                },
                children: [
                  {
                    render: (
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <p
                            style={{
                              fontSize: 30,
                              width: "100%",
                              fontFamily: "Poppins",
                              fontWeight: "bold",
                              marginBottom: "3%",
                            }}
                          >
                            Meus Dados
                          </p>
                          <div
                            style={{
                              flex: 1,
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              borderRadius: "8px",
                            }}
                          >
                            {/* Provider */}
                            <div
                              style={{
                                flex: 1,
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                borderRadius: "8px",
                                backgroundColor: "rgba(111,111,111,.15)",
                                padding: "2%",
                              }}
                            >
                              {/*image*/}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  maxWidth: 300,
                                  maxHeight: 300,
                                }}
                              >
                                <img
                                  src={profile.company_icon_thumb}
                                  style={{
                                    borderRadius: 10,
                                    width: "100%",
                                    height: "55%",
                                    padding: 8,
                                    boxShadow:
                                      "0px 0px 10px 0px rgba(0,0,0,.3)",
                                    aspectRatio: 1,
                                  }}
                                />
                              </div>

                              <div
                                style={{
                                  flex: 1,
                                  marginLeft: "2%",
                                }}
                              >
                                {/*div NameProfile + EditProfile */}
                                <div
                                  style={{
                                    flex: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <p
                                    style={{
                                      width: "30%",
                                      fontSize: 24,
                                      fontWeight: "bold",
                                      fontFamily: "Poppins",
                                      margin: "2%",
                                    }}
                                  >
                                    {profile.nickname}
                                  </p>

                                  <div
                                    style={{
                                      width: 32,
                                      alignItems: "center",
                                      aspectRatio: "1/1",
                                      margin: "2%",
                                    }}
                                  >
                                    <img
                                      src={EditProvider}
                                      style={{
                                        width: 32,
                                        aspectRatio: "1/1",
                                        cursor: "pointer",
                                      }}
                                      onClick={async () => {
                                        setPopUp(true);
                                        setUpdate(false);
                                        // alert("estou funfando");
                                      }}
                                    />
                                  </div>
                                </div>

                                {/*inputCode*/}
                                <p>
                                  <InputMask
                                    mask="99.999.999/9999-99"
                                    value={profile.code}
                                    onChange={(e) => {
                                      setProfile({
                                        ...profile,
                                        cnpj: e.target.value,
                                      });
                                    }}
                                  >
                                    {() => (
                                      <input
                                        style={{
                                          fontSize: 20,
                                          fontFamily: "Poppins",
                                          textAlign: "center",
                                          width: "100%",
                                          height: "100%",
                                          border: "none",
                                          outline: "none",
                                          backgroundColor: "transparent",
                                        }}
                                      />
                                    )}
                                  </InputMask>
                                </p>

                                <div>
                                  <p
                                    style={{
                                      fontSize: 18,
                                      fontFamily: "Poppins",
                                      textAlign: "center",
                                      // estilo com borda de card moderno
                                      border: "1px solid #000",
                                      borderRadius: 10,
                                      padding: 10,
                                      margin: 10,
                                      boxShadow:
                                        "0px 0px 10px 0px rgba(0,0,0,0.1)",
                                    }}
                                  >
                                    {profile?.address?.street},{" "}
                                    {profile?.address?.number},{" "}
                                    {profile?.address?.complement},{" "}
                                    {profile?.address?.district},{" "}
                                    {profile?.address?.city},{" "}
                                    {profile?.address?.state},{" "}
                                    {profile?.address?.code}
                                  </p>
                                </div>

                                <div>
                                  <p
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      fontSize: 18,
                                      fontFamily: "Poppins",
                                      textAlign: "center",
                                      // estilo com borda de card moderno
                                      borderRadius: 10,
                                      padding: 8,
                                      margin: 8,
                                      marginTop: 30,
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: 16,
                                        fontFamily: "Poppins",
                                        marginRight: "1%",
                                      }}
                                    >
                                      Para receber uma notificação dos pedidos
                                      recebidos, digite seu whatsapp abaixo
                                    </p>
                                  </p>
                                </div>
                                <div>
                                  <p
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      fontSize: 18,
                                      fontFamily: "Poppins",
                                      textAlign: "center",
                                      // estilo com borda de card moderno
                                      // border: ".1px solid #000",
                                      borderRadius: 10,
                                      padding: 8,
                                      margin: 8,
                                    }}
                                  >
                                    <p>
                                      <InputMask
                                        mask="99 99999-9999"
                                        value={profile.whatsapp}
                                        onChange={async (e) => {
                                          setProfile({
                                            ...profile,
                                            whatsapp: e.target.value,
                                          });

                                          if (e.target.value.length < 10)
                                            return 0;

                                          let { data } =
                                            await api.provider.attProvider({
                                              whatsapp: e.target.value,
                                            });
                                          console.log(data.data.info);
                                          if (data.data.status) {
                                            await localStorage.setItem(
                                              "user",
                                              JSON.stringify(data.data.info)
                                            );
                                          }
                                        }}
                                      >
                                        {() => (
                                          <input
                                            placeholder="11 99999-9999"
                                            style={{
                                              width: 300,
                                              height: 50,
                                              borderRadius: 4,
                                              color: "rgba(100,100,100,1)",
                                              fontSize: 24,
                                              fontFamily: "Poppins",
                                              borderWidth: 1,
                                              backgroundColor:
                                                "rgba(100,100,100,0.2)",
                                              paddingLeft: "1%",
                                              paddingRight: "2%",
                                              textPadding: "2%",
                                            }}
                                          />
                                        )}
                                      </InputMask>
                                    </p>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                  },
                ],
              },
            ],
          },
          //display none
          {
            component: "container",
            style: {
              display: "none",
              width: "75%",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(155,155,155,0.1)",
              borderRadius: 10,
              padding: 20,
              marginTop: 20,
            },
            children: [
              {
                component: "container",
                style: {
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                },
                children: [
                  {
                    render: (
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <p
                            style={{
                              fontSize: 30,
                              width: "100%",
                              fontFamily: "Poppins",
                              fontWeight: "bold",
                              marginBottom: "3%",
                            }}
                          >
                            Horário de Funcionamento
                          </p>
                        </div>
                        <p
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <p
                            style={{
                              fontSize: 20,
                              fontFamily: "Poppins",
                              textAlign: "center",
                              margin: "2%",
                            }}
                          >
                            Selecione o horário de abertura e fechamento do seu
                            estabelecimento:
                          </p>
                          <p
                            style={{
                              fontSize: 14,
                              fontFamily: "Poppins",
                              textAlign: "center",
                              margin: "2%",
                            }}
                          ></p>
                          <TimePicker.RangePicker
                            format="HH:mm"
                            value={workSchedule}
                            // defaultValue={workSchedule}
                            onChange={async (time, timeString) => {
                              let { data } = await api.provider.workSchedule({
                                start: timeString[0],
                                end: timeString[1],
                              });
                              setWorkSchedule([
                                dayjs(timeString[0], "HH:mm"),
                                dayjs(timeString[1], "HH:mm"),
                              ]);
                            }}
                            picker="time"
                            allowClear={false}
                            // suffixIcon={
                            //   [
                            //     // <span key="start">Hora de início</span>,
                            //     // <span key="end">Hora de fim</span>,
                            //   ]
                            // }
                            placeholder={["Hora de início", "Hora de fim"]}
                            style={{
                              fontSize: "16px",
                              color: "#333",
                              borderRadius: "8px",
                              backgroundColor: "rgba(100,100,100,.15)",
                            }}
                          />
                        </p>
                      </div>
                    ),
                  },
                ],
              },
            ],
          },
          //leva e traz
          {
            component: "container",
            style: {
              width: "75%",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(155,155,155,0.1)",
              borderRadius: 10,
              padding: 20,
              marginTop: 20,
              marginBottom: 20,
            },
            children: [
              {
                component: "container",
                style: {
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                },
                children: [
                  {
                    render: (
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <p
                            style={{
                              fontSize: 30,
                              width: "100%",
                              fontFamily: "Poppins",
                              fontWeight: "bold",
                              marginBottom: "3%",
                            }}
                          >
                            Leva e Traz
                          </p>
                          <p
                            style={{
                              fontSize: 20,
                              fontFamily: "Poppins",
                              textAlign: "center",
                            }}
                          >
                            Se o seu estabelecimento possui serviço de retirada
                            e entrega do pet , configure as informações abaixo:
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "20px 0",
                          }}
                        >
                          <Switch
                            checked={isActive}
                            value={isActive}
                            onChange={async (e) => {
                              let { data } = await api.provider.attProvider({
                                delivery_service: !isActive,
                                delivery_service_value: price / 100,
                              });
                              if (data.data.status) {
                                await localStorage.setItem(
                                  "user",
                                  JSON.stringify(data.data.info)
                                );
                              }
                              setIsActive(!isActive);
                            }}
                            size="medium"
                            {...label}
                          />
                          <p
                            style={{
                              fontSize: 20,
                              fontFamily: "Poppins",
                              width: 1100,
                              textAlign: "center",
                            }}
                          >
                            {isActive ? "Serviço ativo" : "Serviço inativo"}
                          </p>
                        </div>
                        {isActive && (
                          <div>
                            <p
                              style={{
                                fontSize: 20,
                                fontFamily: "Poppins",
                                marginLeft: 10,
                                marginBottom: 20,
                              }}
                            >
                              Raio de atendimento
                            </p>
                            <div
                              style={{
                                width: "90%",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-start",
                                justifyContent: "center",
                                marginLeft: 20,
                                gap: 10,
                              }}
                            >
                              <InputNumber
                                min={0}
                                max={10}
                                style={{ flex: 0.2 }}
                                onChange={async (value) => {
                                  setDeliveryRange(value);
                                  let { data } = await api.provider.attProvider(
                                    {
                                      delivery_service: isActive,
                                      delivery_service_value: parseFloat(
                                        (price / 100).toFixed(2)
                                      ),
                                      delivery_range: value,
                                    }
                                  );
                                  if (data.data.status) {
                                    await localStorage.setItem(
                                      "user",
                                      JSON.stringify(data.data.info)
                                    );
                                  }
                                }}
                                value={deliveryRange}
                                addonAfter=" Km"
                                step="0.5"
                              />
                              <Slider
                                min={0}
                                max={10}
                                style={{
                                  flex: 1,
                                  fontSize: 12,
                                  marginLeft: 20,
                                }}
                                onChange={async (value) => {
                                  setDeliveryRange(value);
                                  let { data } = await api.provider.attProvider(
                                    {
                                      delivery_service: isActive,
                                      delivery_service_value: parseFloat(
                                        (price / 100).toFixed(2)
                                      ),
                                      delivery_range: value,
                                    }
                                  );
                                  if (data.data.status) {
                                    await localStorage.setItem(
                                      "user",
                                      JSON.stringify(data.data.info)
                                    );
                                  }
                                }}
                                value={deliveryRange}
                                tooltip={(value) => `${value} Km`}
                                step={0.5}
                                defaultValue={5}
                                marks={marks}
                              />
                            </div>
                            <div>
                              <p
                                style={{
                                  fontSize: 20,
                                  fontFamily: "Poppins",
                                  // fontWeight: "bold",
                                  marginLeft: 10,
                                  marginTop: 20,
                                  marginBottom: 4,
                                }}
                              >
                                Selecione o valor cobrado por kilometro
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  marginLeft: 20,
                                  marginTop: 4,
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: 30,
                                    fontFamily: "Poppins",
                                    marginRight: "1%",
                                  }}
                                >
                                  {" "}
                                  R${" "}
                                </p>
                                <CurrencyInput
                                  value={price}
                                  onChange={async (e, val) => {
                                    if (val.length > 5) return 0;
                                    else if (val === "") setPrice("");
                                    else {
                                      setPrice(val);
                                      let { data } =
                                        await api.provider.attProvider({
                                          delivery_service: isActive,
                                          delivery_service_value:
                                            parseFloat(val),
                                        });
                                      if (data.data.status) {
                                        await localStorage.setItem(
                                          "user",
                                          JSON.stringify(data.data.info)
                                        );
                                      }
                                    }
                                  }}
                                  style={{
                                    width: 150,
                                    height: 50,
                                    borderRadius: 4,
                                    color: "rgba(100,100,100,1)",
                                    fontSize: 24,
                                    fontFamily: "Poppins",
                                    borderWidth: 0,
                                    backgroundColor: "rgba(100,100,100,0.2)",
                                    paddingLeft: "1%",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ),
                  },
                ],
              },
            ],
          },
          //dados bancarios
          {
            component: "container",
            style: {
              width: "75%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(155,155,155,0.1)",
              borderRadius: 10,
              padding: 20,
              marginBottom: 20,
              gap: 12,
            },
            children: [
              //Title
              {
                component: "container",
                style: {
                  width: "100%",
                  height: "auto",
                  alignItems: "center",
                  justifyContent: "flex-start",
                },
                children: [
                  {
                    component: "text",
                    text: "Dados bancários",
                    style: {
                      fontSize: 30,
                      width: "100%",
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                    },
                  },
                ],
              },
              //Conteúdo bancário
              {
                component: "container",
                style: {
                  width: "100%",
                  display: "flex",
                  height: 88,
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: 12,
                  marginTop: 12,
                },
                children: [
                  //text banco
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      width: "100%",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      width: "100%",
                      gap: 8,
                    },
                    children: [
                      {
                        component: "text",
                        text: "Banco",
                        style: {
                          fontSize: 14,
                          width: "100%",
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                        },
                      },
                      {
                        component: "text",
                        text: profile.bank_account?.bank?.name || "",
                        style: {
                          fontSize: 14,
                          width: "100%",
                          fontFamily: "Poppins",
                          color: "gray",
                          fontWeight: "normal",
                        },
                      },
                    ],
                  },
                  //text Agencia
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      width: "100%",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      width: "100%",
                      gap: 8,
                    },
                    children: [
                      {
                        component: "text",
                        text: "Agência",
                        style: {
                          fontSize: 14,
                          width: "100%",
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                        },
                      },
                      {
                        component: "text",
                        text: profile.bank_account?.agency || "",
                        style: {
                          fontSize: 14,
                          width: "100%",
                          fontFamily: "Poppins",
                          color: "gray",
                          fontWeight: "normal",
                        },
                      },
                    ],
                  },
                  //text conta
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      width: "100%",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      width: "100%",
                      gap: 8,
                    },
                    children: [
                      {
                        component: "text",
                        text: "Conta",
                        style: {
                          fontSize: 14,
                          width: "100%",
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                        },
                      },
                      {
                        component: "text",
                        text: profile.bank_account?.account || "",
                        style: {
                          fontSize: 14,
                          width: "100%",
                          fontFamily: "Poppins",
                          color: "gray",
                          fontWeight: "normal",
                        },
                      },
                    ],
                  },
                  //text tipo de chave
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      width: "100%",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      width: "100%",
                      gap: 8,
                    },
                    children: [
                      {
                        component: "text",
                        text: "Tipo de chave",
                        style: {
                          fontSize: 14,
                          width: "100%",
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                        },
                      },
                      {
                        component: "text",
                        text: profile.bank_account?.pix_type?.name || "",
                        style: {
                          fontSize: 14,
                          width: "100%",
                          fontFamily: "Poppins",
                          color: "gray",
                          fontWeight: "normal",
                        },
                      },
                    ],
                  },
                  //text cnpj
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      width: "100%",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      width: "100%",
                      gap: 8,
                    },
                    children: [
                      {
                        component: "text",
                        text: "Chave",
                        style: {
                          fontSize: 14,
                          width: "100%",
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                        },
                      },
                      {
                        component: "text",
                        text: profile.bank_account?.pix_key,
                        style: {
                          fontSize: 14,
                          width: "100%",
                          fontFamily: "Poppins",
                          color: "gray",
                          fontWeight: "normal",
                        },
                      },
                    ],
                  },
                ],
              },
              //textPetin
              {
                component: "container",
                style: {
                  width: "100%",
                  height: "auto",
                  alignItems: "center",
                  justifyContent: "center",
                },
                children: [
                  {
                    component: "text",
                    text: "Para alterar os dados bancários entre em contato com a Petin",
                    style: {
                      fontSize: 16,
                      width: "auto",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                    },
                  },
                ],
              },
            ],
          },
          //EditProvider
          {
            component: "container",
            style: {
              display: popUp ? "flex" : "none",
              width: "100%",
              minHeight: "100vh",
              maxHeight: "100vh",
              position: "fixed",
              top: 0,
              left: 0,
              justifyContent: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              alignItems: "center",
              zIndex: 8,
            },
            children: [
              {
                render: (
                  <Fade>
                    {Render({
                      component: "container",
                      style: {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        boxShadow: "0px 0px 100px 10px rgba(0, 0, 0, 0.8)",
                        backgroundColor: "#fff",
                        padding: 24,
                        borderRadius: 10,
                        width: "60%",
                        zIndex: 9,
                        gap: 12,
                      },
                      children: [
                        //labelEdit
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            width: "100%",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          },
                          children: [
                            {
                              component: "text",
                              text: "Edite seu perfil",
                              style: {
                                fontFamily: "Poppins",
                                fontSize: 22,
                                textAlign: "center",
                                fontWeight: "600",
                              },
                            },
                            {
                              component: "image",
                              src: Close,
                              style: {
                                width: 24,
                                aspectRatio: "1/1",
                                alignItems: "center",
                                cursor: "pointer",
                              },
                              onClick: async () => {
                                setPopUp(false);
                                let { data } = await api.provider.getUser();
                                setProfile(data.data.info);
                              },
                            },
                          ],
                        },
                        //infoGeral
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            marginTop: 12,
                            width: "100%",
                            flexDirection: "column",
                            gap: 12,
                          },
                          children: [
                            //label
                            {
                              component: "text",
                              text: "Informações gerais",
                              style: {
                                width: "100%",
                                textAlign: "flex-start",
                                fontFamily: "Poppins",
                                fontWeight: "600",
                                color: "blue",
                              },
                            },
                            //inputs
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                padding: 4,
                                paddingLeft: 0,
                                gap: 12,
                                flexDirection: "row",
                              },
                              children: [
                                //name
                                {
                                  component: "container",
                                  style: {
                                    display: "flex",
                                    width: "100%",
                                    gap: 4,
                                    flexDirection: "column",
                                  },
                                  children: [
                                    //text
                                    {
                                      component: "text",
                                      text: "Nome fantasia",
                                      style: labelStyle,
                                    },
                                    //inputName
                                    {
                                      render: (
                                        <InputMask
                                          value={profile?.nickname}
                                          // mask={}
                                          // type={}
                                          style={inputStyle}
                                          onFocus={async (e) => {}}
                                          onBlur={async (e) => {}}
                                          onChange={async (text) => {
                                            let nickname = text.target.value;
                                            setProfile({
                                              ...profile,
                                              nickname,
                                            });
                                          }}
                                        />
                                      ),
                                    },
                                  ],
                                },
                                //whatsApp
                                {
                                  component: "container",
                                  style: {
                                    display: "flex",
                                    width: "100%",
                                    gap: 4,
                                    padding: 4,
                                    flexDirection: "column",
                                  },
                                  children: [
                                    //text
                                    {
                                      component: "text",
                                      text: "WhatsApp",
                                      style: labelStyle,
                                    },
                                    //inputWhats
                                    {
                                      render: (
                                        <InputMask
                                          value={profile?.whatsapp}
                                          mask={"(99) 99999-9999"}
                                          // type={}
                                          style={inputStyle}
                                          onFocus={async (e) => {}}
                                          onBlur={async (e) => {}}
                                          onChange={async (text) => {
                                            const whatsapp = text.target.value;
                                            setProfile({
                                              ...profile,
                                              whatsapp,
                                            });
                                          }}
                                        />
                                      ),
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        //Endereço
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            marginTop: 12,
                            width: "100%",
                            flexDirection: "column",
                            gap: 12,
                          },
                          children: [
                            //label
                            {
                              component: "text",
                              text: "Localização",
                              style: {
                                width: "100%",
                                textAlign: "flex-start",
                                fontFamily: "Poppins",
                                fontWeight: "600",
                                color: "blue",
                              },
                            },
                            //inputs
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                padding: 4,
                                paddingLeft: 0,
                                gap: 12,
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                              children: [
                                //cep
                                {
                                  component: "container",
                                  style: {
                                    display: "flex",
                                    width: "30%",
                                    gap: 4,
                                    flexDirection: "column",
                                  },
                                  children: [
                                    //text
                                    {
                                      component: "text",
                                      text: "CEP",
                                      style: labelStyle,
                                    },
                                    //inputCep
                                    {
                                      render: (
                                        <InputMask
                                          value={profile.address?.code}
                                          mask={"99999-999"}
                                          // type={}
                                          // placeholder="Burno"
                                          style={inputStyle}
                                          onFocus={async (e) => {}}
                                          onBlur={async (e) => {}}
                                          onChange={async (e) => {
                                            let code = e.target.value.replace(
                                              /[^A-Za-z0-9 ]+/g,
                                              ""
                                            );
                                            setProfile({
                                              ...profile,
                                              address: {
                                                ...profile?.address,
                                                code,
                                              },
                                            });
                                            //update
                                            if (code.length === 8) {
                                              const { data } =
                                                await api.utils.addressCode(
                                                  code
                                                );
                                              console.log("verData", data);
                                              if (data?.data?.cep) {
                                                setProfile({
                                                  ...profile,
                                                  address: {
                                                    ...profile.address,
                                                    code,
                                                    street: data.data.street,
                                                    state: data.data.state,
                                                    city: data.data.city,
                                                    district:
                                                      data.data.neighborhood,
                                                  },
                                                });
                                                setAddrStreetProfile(
                                                  data.data.street
                                                );
                                                setAddrStateProfile(
                                                  data.data.state
                                                );
                                                setAddrCityProfile(
                                                  data.data.city
                                                );
                                                setAddrDistrictProfile(
                                                  data.data.neighborhood
                                                );
                                              }
                                            } else {
                                            }
                                          }}
                                        />
                                      ),
                                    },
                                  ],
                                },
                                //logadouro
                                {
                                  component: "container",
                                  style: {
                                    display: "flex",
                                    width: "40%",
                                    gap: 4,
                                    padding: 4,
                                    flexDirection: "column",
                                  },
                                  children: [
                                    //text
                                    {
                                      component: "text",
                                      text: "Logadouro",
                                      style: labelStyle,
                                    },
                                    //inputLogadouro
                                    {
                                      render: (
                                        <InputMask
                                          value={addrStreetProfile}
                                          // mask={}
                                          // type={}
                                          style={inputStyle}
                                          onFocus={async (e) => {}}
                                          onBlur={async (e) => {}}
                                          onChange={async (e) => {
                                            let street = e.target.value;
                                            setAddrStreetProfile(street);
                                            console.log("verRua", street);
                                            setProfile({
                                              ...profile,
                                              address: {
                                                ...profile?.address,
                                                street,
                                              },
                                            });
                                          }}
                                        />
                                      ),
                                    },
                                  ],
                                },
                                //estado
                                {
                                  component: "container",
                                  style: {
                                    display: "flex",
                                    width: "24%",
                                    gap: 4,
                                    padding: 4,
                                    flexDirection: "column",
                                  },
                                  children: [
                                    //text
                                    {
                                      component: "text",
                                      text: "Estado",
                                      style: labelStyle,
                                    },
                                    //inputEstado
                                    {
                                      render: (
                                        <InputMask
                                          value={addrStateProfile}
                                          // type={}
                                          style={inputStyle}
                                          onFocus={async (e) => {}}
                                          onBlur={async (e) => {}}
                                          onChange={async (e) => {
                                            let state = e.target.value;
                                            setAddrStateProfile(state);
                                            setProfile({
                                              ...profile,
                                              address: {
                                                ...profile?.address,
                                                state,
                                              },
                                            });
                                          }}
                                        />
                                      ),
                                    },
                                  ],
                                },
                                //Numero
                                {
                                  component: "container",
                                  style: {
                                    display: "flex",
                                    width: "30%",
                                    gap: 4,
                                    flexDirection: "column",
                                  },
                                  children: [
                                    //text
                                    {
                                      component: "text",
                                      text: "Numero",
                                      style: labelStyle,
                                    },
                                    //inputNumero
                                    {
                                      render: (
                                        <InputMask
                                          value={addrNumberProfile}
                                          // type={}
                                          style={inputStyle}
                                          onFocus={async (e) => {}}
                                          onBlur={async (e) => {}}
                                          onChange={async (e) => {
                                            let number = e.target.value;
                                            setAddrNumberProfile(number);
                                            setProfile({
                                              ...profile,
                                              address: {
                                                ...profile?.address,
                                                number,
                                              },
                                            });
                                          }}
                                        />
                                      ),
                                    },
                                  ],
                                },
                                //Complemento
                                {
                                  component: "container",
                                  style: {
                                    display: "flex",
                                    width: "30%",
                                    gap: 4,
                                    padding: 4,
                                    flexDirection: "column",
                                  },
                                  children: [
                                    //text
                                    {
                                      component: "text",
                                      text: "Complemento",
                                      style: labelStyle,
                                    },
                                    //inputComplement
                                    {
                                      render: (
                                        <InputMask
                                          value={addrComplementProfile}
                                          // type={}
                                          style={inputStyle}
                                          onFocus={async (e) => {}}
                                          onBlur={async (e) => {}}
                                          onChange={async (e) => {
                                            let complement = e.target.value;
                                            setAddrComplementProfile(
                                              complement
                                            );
                                            setProfile({
                                              ...profile,
                                              address: {
                                                ...profile?.address,
                                                complement,
                                              },
                                            });
                                          }}
                                        />
                                      ),
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        //Foto de perfil
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            marginTop: 12,
                            width: "100%",
                            flexDirection: "column",
                            gap: 12,
                          },
                          children: [
                            //label
                            {
                              component: "text",
                              text: "Logotipo do parceiro",
                              style: {
                                width: "100%",
                                textAlign: "flex-start",
                                fontFamily: "Poppins",
                                fontWeight: "600",
                                color: "blue",
                              },
                            },
                            // upload
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                flexDirection: "column",
                                flexWrap: "wrap",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                padding: 12,
                                width: "100%",
                                marginTop: 4,
                                borderRadius: 8,
                                borderWidth: 0.1,
                                borderStyle: "solid",
                                borderColor: "rgba(100,100,100, 0.2)",
                              },
                              children: [
                                {
                                  render: (
                                    <div
                                      // {...getRootProps()}
                                      style={{
                                        width: "100%",
                                        height: 42,
                                        background: "rgba(219, 234, 254, 0.8)",
                                        borderRadius: 10,
                                        borderWidth: 2,
                                        borderStyle: "dashed",
                                        borderColor: "blue",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <input
                                        id="upload-file"
                                        type="file"
                                        style={{ display: "flex" }}
                                        onChange={async (e) => {
                                          const file = e.target.files[0];
                                          if (
                                            file &&
                                            file.type.startsWith("image/")
                                          ) {
                                            const reader = new FileReader();
                                            reader.onload = function (event) {
                                              // urlDa imagem
                                              const company_icon_thumb =
                                                event.target.result;
                                              // Atualizar o profile
                                              setProfile({
                                                ...profile,
                                                company_icon_thumb,
                                              });
                                            };
                                            reader.readAsDataURL(file);
                                          }
                                        }}
                                      />
                                      <p
                                        style={{
                                          color: "blue",
                                          fontSize: "1vw",
                                          fontFamily: "Poppins",
                                        }}
                                      ></p>
                                    </div>
                                  ),
                                },
                              ],
                            },
                          ],
                        },
                        //button save
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "8vh",
                          },
                          children: [
                            {
                              component: "button",
                              text: "Salvar",
                              style: {
                                backgroundColor: "blue",
                                alignItems: "center",
                                width: "24%",
                                height: "64%",
                                color: "white",
                                borderRadius: 6,
                                border: "none",
                                fontSize: 14,
                                cursor: "Pointer",
                                fontFamily: "Poppins",
                              },
                              onClick: async () => {
                                console.log("verInfo", profile);
                                const update = await api.utils.update({
                                  _id: profile._id,
                                  type: "provider",
                                  nickname: profile.nickname,
                                  address: profile.address,
                                  whatsapp: profile.whatsapp,
                                  company_icon_thumb:
                                    profile.company_icon_thumb,
                                  // company_icon_thumb:
                                  //   profile.company_icon_thumb,
                                });
                                console.log(update);
                                if (update?.data?.data?.status === true) {
                                  setUpdate(true);
                                  setUpdateErr(false);
                                  let { data } = await api.provider.getUser();
                                  setProfile(data.data.info);
                                } else {
                                  setUpdate(true);
                                  setUpdateErr(true);
                                }
                              },
                            },
                          ],
                        },
                      ],
                    })}
                  </Fade>
                ),
              },
            ],
          },
          //popUpUpdate
          {
            component: "container",
            style: {
              display: update ? "flex" : "none",
              width: "100%",
              minHeight: "100vh",
              maxHeight: "100vh",
              position: "fixed",
              top: 0,
              left: 0,
              justifyContent: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              alignItems: "center",
              zIndex: 999,
            },
            children: [
              {
                render: (
                  <Fade>
                    {Render({
                      component: "container",
                      style: {
                        display: "flex",
                        width: "32%",
                        height: "32",
                        position: "fixed",
                        flexDirection: "column",
                        backgroundColor: "white",
                        boxShadow: "0px 0px 100px 10px rgba(0, 0, 0, 0.8)",
                        padding: 18,
                        borderRadius: 10,
                        zIndex: 9999,
                      },
                      children: [
                        {
                          component: "text",
                          text:
                            updateErr === true
                              ? "Erro ao editar perfil"
                              : "Perfil atualizado com sucesso!",
                          style: {
                            fontFamily: "Poppins",
                            color:
                              updateErr === true
                                ? "rgba(220, 38, 38, 1)"
                                : "rgba(16, 185, 129, 1",
                            fontSize: "1.5vw",
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        },
                        //button
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "8vh",
                          },
                          children: [
                            {
                              component: "button",
                              text: "Fechar",
                              style: {
                                backgroundColor: "white",
                                alignItems: "center",
                                width: "24%",
                                height: "48%",
                                color: "blue",
                                borderRadius: 6,
                                border: "none",
                                fontSize: 14,
                                cursor: "Pointer",
                                fontFamily: "Poppins",
                              },
                              onClick: async () => {
                                setPopUp(false);
                                setUpdate(false);
                              },
                            },
                          ],
                        },
                      ],
                    })}
                  </Fade>
                ),
              },
            ],
          },
        ],
      },
    ],
  });
};

export default TakeAndBring;
