import React, { useEffect, useState } from "react";
import { Render } from "@8_dev/sjson-web";
import Theme from "../../theme";
import Navbar from "../../components/Navbar";
import { useActive } from "../../context/activeContext";
import api from "../../service";
import Switch from "@mui/material/Switch";
import { Slider, InputNumber } from "antd";

const formatter = (value) => `${value}%`;

let ProviderPromotion = () => {
  const { active, updateActive } = useActive();
  const [profile, setProfile] = useState({});
  const [category, setCategory] = useState([]);
  const [pageData, setPageData] = useState({});
  const [selectCategory, setSelectCategory] = useState({});

  useEffect(() => {
    (async () => {
      updateActive(4);

      const { data } = await api.provider.getServicesOptions();
      setCategory(data.data);

      let user = await api.provider.getUser();
      user = user.data.data.info;

      // setProfile(data.data.data.info);

      let promotionList = {};
      user.promotion.map((s) => {
        promotionList[s.category] = s;
      });

      setProfile(user);
      setPageData(promotionList);

      await localStorage.setItem("user", JSON.stringify(user));

      // console.log("user", user);
    })();
  }, []);

  const pageStyle = {
    category: (item) => {
      return {
        margin: "1%",
        width: "20%",
        minHeight: "14vh",
        fontSize: "18px",
        fontWeight: selectCategory._id === item._id ? "500" : "400",
        color: selectCategory._id === item._id ? "#011627" : "black",
        textDecorationLine:
          selectCategory._id === item._id ? "underline" : "none",
        backgroundColor: selectCategory._id === item._id ? "#f1f1f1" : "white",
        borderRadius: "16px",
        border:
          selectCategory._id === item._id
            ? pageData[item._id] && pageData[item._id].disabled === false
              ? "4px solid lightgreen"
              : "4px solid rgba(255,255,255,0)"
            : pageData[item._id] && pageData[item._id].disabled === false
            ? "4px solid lightgreen"
            : "4px solid rgba(255,255,255,0)",
        padding: "1%",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "no-wrap",
        boxShadow:
          selectCategory._id === item._id
            ? pageData[item._id] && pageData[item._id].disabled === false
              ? "0px 0px 3px 1px  lightgreen"
              : "0px 0px 3px 1px  rgba(0,0,0,.4)"
            : pageData[item._id] && pageData[item._id].disabled === false
            ? "0px 0px 2px 1px  lightgreen"
            : "0px 0px 2px 0px  rgba(0,0,0,.2)",
      };
    },
  };

  return Render({
    component: "container",
    style: {
      display: "flex",
      flexDirection: "column",
    },
    children: [
      Navbar({
        isAdmin: false,
        context: "provider",
        active: 4,
      }),
      {
        component: "container",
        style: {
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxwidth: "100%",
          height: "10%",
          minHeight: "20%",
          maxHeight: "95vh",
          overFlow: "none",
          padding: "5%",
          paddingTop: "2%",
        },
        children: [
          {
            render: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  flex: 1,
                  flexWrap: "wrap",
                }}
              >
                <p
                  style={{ fontSize: 24, fontWeight: "600", marginBottom: 20 }}
                >
                  Selecione categoria
                </p>

                <p style={{ fontSize: 18, fontWeight: "40", marginBottom: 20 }}>
                  Com promoções exclusivas, você pode atrair ainda mais
                  clientes! Todo desconto é integralmente revertido para o
                  usuário, que visualiza em tempo real as promoções! Obs: este
                  valor é adicional a taxa da Petin.
                </p>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    backgroundColor: "transparent",
                    border: "1px",
                    borderRadius: "8px",
                    wrap: "wrap",
                    width: "100%",
                    flex: 1,
                  }}
                >
                  {category.map((item, index) => {
                    if (item.code !== 4)
                      return (
                        <div
                          onClick={() => {
                            setSelectCategory(item);
                          }}
                          style={pageStyle.category(item)}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              flexDirection: "column",
                              flex: 1,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flex: 1,
                                width: "100%",
                              }}
                            >
                              <img
                                src={item.image}
                                style={{
                                  width: "40px",
                                  maxWidth: "35%",
                                  margin: "1%",
                                }}
                              />
                              <p style={{ marginLeft: "2%", flex: 1 }}>
                                {item.name}
                              </p>
                            </div>
                            {/* Switch */}
                            <div
                              style={{
                                flex: 1,
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Switch
                                  // checked={isActive}
                                  checked={
                                    pageData[item._id] &&
                                    pageData[item._id].disabled === false
                                  }
                                  value={
                                    pageData[item._id] &&
                                    pageData[item._id].disabled === false
                                  }
                                  onChange={async () => {
                                    let cpPageData = { ...pageData };

                                    if (!cpPageData[item._id])
                                      cpPageData[item._id] = {
                                        category: item._id,
                                        disabled: false,
                                        value: 10,
                                      };
                                    else
                                      cpPageData[item._id] = {
                                        ...cpPageData[item._id],
                                        disabled:
                                          !cpPageData[item._id].disabled,
                                      };

                                    setPageData(cpPageData);

                                    await api.provider.categoryPromotion(
                                      Object.values(cpPageData)
                                    );
                                  }}
                                  size="medium"
                                />
                                <div
                                  style={{
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                    textAlign: "center",
                                  }}
                                >
                                  {pageData[item._id]?.disabled === false ? (
                                    <p>Ativo</p>
                                  ) : (
                                    <p>Inativo</p>
                                  )}
                                </div>
                              </div>
                              <div
                                style={{
                                  display:
                                    pageData[item._id]?.disabled === false
                                      ? "flex"
                                      : "none",
                                  flexDirection: "column",
                                  width: "80%",
                                  alignItems: "center",
                                }}
                              >
                                <Slider
                                  min={1}
                                  max={90}
                                  style={{
                                    width: "100%",
                                  }}
                                  onChange={async (value) => {
                                    let cpPageData = { ...pageData };
                                    cpPageData[item._id] = {
                                      ...cpPageData[item._id],
                                      value,
                                    };
                                    setPageData(cpPageData);

                                    await api.provider.categoryPromotion(
                                      Object.values(cpPageData)
                                    );
                                  }}
                                  value={
                                    pageData[item._id]?.value
                                      ? pageData[item._id].value
                                      : 0
                                  }
                                  tooltip={{ formatter }}
                                />
                                <InputNumber
                                  min={1}
                                  max={90}
                                  style={{ width: "80%" }}
                                  onChange={async (value) => {
                                    let cpPageData = { ...pageData };
                                    cpPageData[item._id] = {
                                      ...cpPageData[item._id],
                                      value,
                                    };
                                    setPageData(cpPageData);

                                    await api.provider.categoryPromotion(
                                      Object.values(cpPageData)
                                    );
                                  }}
                                  value={
                                    pageData[item._id]?.value
                                      ? pageData[item._id].value
                                      : 0
                                  }
                                  addonAfter="%"
                                />
                              </div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      );
                  })}
                </div>
              </div>
            ),
          },
        ],
      },
    ],
  });
};

export default ProviderPromotion;
