import "dotenv";
import React, { useState, useEffect } from "react";
import Logo from "../assets/images/Logo.png";
import theme from "../theme";
import { useActive } from "../context/activeContext";
import { useAuth } from "../context/authContext";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { flexbox } from "@mui/system";
import { useAlert } from "../context/alertContext";
import AlertPopUp from "./AlertPopUp";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useSocket } from "../hooks/useSocket";
// import Select from "./select";
import Select from "./select/select2";

import api from "../service";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

let Navbar = ({ isAdmin, context }) => {
  const { connected, handleEvents } = useSocket();
  const { active, updateActive } = useActive();
  const navigate = useNavigate();
  const {
    snackAlert,
    setSnackAlert,
    snackMsg,
    snackTitle,
    errorPopUp,
    setErrorPopUp,
    alertMsg,
    alertTitle,
    closeTime,
  } = useAlert();
  const { socket } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [snackBarAlert, setSnackbarAlert] = useState({
    info: {
      time: 0,
      type: "",
      msg: "",
    },
  });
  const [profile, setProfile] = useState({});
  const [selectedProfile, setSelectedProfile] = useState("aaa");
  const [providerList, setProviderList] = useState([]);

  const SelectProvider = Select({
    title: "Estabelecimento",
    onChange: async (e) => {
      const token = await localStorage.getItem("token");
      const { data } = await api.auth.login({ token, id: e });

      if (data?.data?.raw?.status === true) {
        await localStorage.setItem("token", data.data.raw.data.token);
        await localStorage.setItem(
          "user",
          JSON.stringify(data.data.raw.data.user.info)
        );
        setProfile(data.data.raw.data.user.info);
        window.location.reload();
      }
    },
    style: {
      box: {
        width: 300,
        marginTop: 8,
      },
    },
    item: providerList.map((item) => {
      return {
        label: item.nickname,
        value: item._id,
        key: item._id,
        icon: item.company_icon_thumb,
        //style and box
      };
    }),
  });
  useEffect(() => {
    (async () => {
      let user = await localStorage.getItem("user");
      // to object
      user = JSON.parse(user);
      setProfile(user);
      if (user?._id) SelectProvider.setValue(user._id);
      const list = api.provider.list().then(({ data }) => {
        if (data?.data) setProviderList(data.data);
      });
    })();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  // console.log(profile);
  return {
    component: "nav",
    style: {
      width: "100vw",
      borderBottom: "1px solid #E0E0E0",
      padding: "10px 1%",
      display: "flex",
      flex: 1,
      justifyContent: "space-between",
      alignItems: "center",
      maxHeight: "7vh",
      height: "7vh",
      minHeight: "9vh",
      minHeight: 80,
    },
    children: [
      {
        render: (
          <AlertPopUp
            setTrigger={setErrorPopUp}
            show={errorPopUp}
            background="#F68989"
            color="#874356"
            title={alertTitle}
            message={alertMsg}
          />
        ),
      },
      {
        render: (
          <AlertPopUp
            type="snack"
            time={closeTime}
            setTrigger={setSnackAlert}
            show={snackAlert}
            background="#F68989"
            color="#874356"
            title={snackTitle}
            message={snackMsg}
          />
        ),
      },
      {
        component: "image",
        src: Logo,
        style: {
          height: "100%",
          maxHeight: 30,
          margin: ".5%",
        },
      },
      AdminActions({
        isAdmin,
        active,
        setActive: updateActive,
        context,
        navigate,
        profile,
      }),
      ProviderActions({
        isAdmin,
        active,
        setActive: updateActive,
        context,
        navigate,
        profile,
        providerList,
        setProfile,
        selectedProfile,
        SelectProvider,
      }),
    ],
  };
};

let AdminActions = ({
  isAdmin,
  active,
  setActive,
  context,
  navigate,
  profile,
}) => {
  return isAdmin
    ? {
        component: "container",
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginLeft: 50,
        },
        children: [
          {
            component: "button",
            text: "Cadastrar fornecedor",
            onClick: () => setActive(1),
            style: {
              width: 275,
              height: 60,
              border: active == 1 ? "1px solid #2EC4B6" : "1px solid #E0E0E0",
              backgroundColor:
                active == 1
                  ? "rgba(125, 226, 217, 0.25)"
                  : theme.colors.backgroundBlue,
              borderRadius: 8,
              margin: "0 10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Poppins",
              fontSize: 16,
              fontWeight: "bold",
              cursor: "pointer",
              color: active == 1 ? "#000" : "#BDBDBD",
            },
          },
          {
            component: "button",
            text: "Cadastrar empresa cliente",
            onClick: () => setActive(2),
            style: {
              width: 290,
              height: 60,
              border: active == 2 ? "1px solid #2EC4B6" : "1px solid #E0E0E0",
              backgroundColor:
                active == 2
                  ? "rgba(125, 226, 217, 0.25)"
                  : theme.colors.backgroundBlue,
              borderRadius: 8,
              margin: "0 10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Poppins",
              fontSize: 16,
              fontWeight: "bold",
              cursor: "pointer",
              color: active == 2 ? "#000" : "#BDBDBD",
            },
          },
          {
            component: "button",
            text: "Controle de pedidos",
            onClick: () => setActive(3),
            style: {
              width: 275,
              height: 60,
              border: active == 3 ? "1px solid #2EC4B6" : "1px solid #E0E0E0",
              backgroundColor:
                active == 3
                  ? "rgba(125, 226, 217, 0.25)"
                  : theme.colors.backgroundBlue,
              borderRadius: 8,
              margin: "0 10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Poppins",
              fontSize: 16,
              fontWeight: "bold",
              cursor: "pointer",
              color: active == 3 ? "#000" : "#BDBDBD",
            },
          },
          {
            render: (
              <div
                style={{
                  display: "flex",
                  width: "auto",
                  height: "auto",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 60,
                    height: 60,
                    border: "1px solid #E0E0E0",
                    borderRadius: 8,
                    marginRight: 5,
                  }}
                >
                  <LogoutIcon
                    style={{
                      fontSize: 24,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      localStorage.clear();
                      navigate("/");
                    }}
                  />
                </div>
                <p
                  style={{
                    fontSize: 12,
                    fontFamily: "Poppins",
                    color: "#BDBDBD",
                  }}
                >
                  {process.env.REACT_APP_VERSION}
                </p>
              </div>
            ),
          },
        ],
      }
    : false;
};

let ProviderActions = ({
  isAdmin,
  active,
  setActive,
  context,
  navigate,
  profile,
  providerList,
  setProfile,
  selectedProfile,
  SelectProvider,
}) => {
  return !isAdmin && context == "provider"
    ? {
        component: "container",
        style: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          flex: 1,
        },
        children: [
          // Buttons Menu
          {
            component: "container",
            style: {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              flex: 1,
              maxWidth: "60%",
            },
            children: [
              //Pedidos ativos
              {
                component: "button",
                text: "Pedidos ativos",
                onClick: () => {
                  setActive(1);
                  navigate("/dashboard-provider");
                },
                style: {
                  border: "none",
                  margin: "0 10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Poppins",
                  fontSize: 16,
                  fontWeight: "bold",
                  cursor: "pointer",
                  borderRadius: 4,
                  color: active == 1 ? "#000" : "#BDBDBD",
                  backgroundColor:
                    active == 1 ? "rgba(200,200,200,.2)" : "transparent",
                },
              },
              {
                component: "button",
                text: "Horários",
                onClick: () => {
                  setActive(2);
                  navigate("/dashboard-provider/schedule");
                },
                style: {
                  border: "none",
                  margin: "0 10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Poppins",
                  fontSize: 16,
                  fontWeight: "bold",
                  cursor: "pointer",
                  borderRadius: 4,
                  color: active == 2 ? "#000" : "#BDBDBD",
                  backgroundColor:
                    active == 2 ? "rgba(200,200,200,.2)" : "transparent",
                },
              },
              {
                component: "button",
                text: "Serviços",
                onClick: () => {
                  setActive(3);
                  navigate("/dashboard-provider/services");
                },
                style: {
                  border: "none",
                  margin: "0 10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Poppins",
                  fontSize: 16,
                  fontWeight: "bold",
                  cursor: "pointer",
                  borderRadius: 4,
                  color: active == 3 ? "#000" : "#BDBDBD",
                  backgroundColor:
                    active == 3 ? "rgba(200,200,200,.2)" : "transparent",
                },
              },
              {
                component: "button",
                text: "Promoção",
                onClick: () => {
                  setActive(4);
                  navigate("/dashboard-provider/promotion");
                },
                style: {
                  border: "none",
                  margin: "0 10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Poppins",
                  fontSize: 16,
                  fontWeight: "bold",
                  cursor: "pointer",
                  borderRadius: 4,
                  color: active == 4 ? "#000" : "#BDBDBD",
                  backgroundColor:
                    active == 4 ? "rgba(200,200,200,.2)" : "transparent",
                },
              },
              {
                component: "button",
                text: "Daycare",
                onClick: () => {
                  setActive(5);
                  navigate("/dashboard-provider/daycare");
                },
                style: {
                  border: "none",
                  margin: "0 10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Poppins",
                  fontSize: 16,
                  fontWeight: "bold",
                  cursor: "pointer",
                  borderRadius: 4,
                  color: active == 5 ? "#000" : "#BDBDBD",
                  backgroundColor:
                    active == 5 ? "rgba(200,200,200,.2)" : "transparent",
                },
              },
              {
                component: "button",
                text: "Perfil",
                onClick: () => {
                  setActive(6);
                  navigate("/dashboard-provider/take-and-bring");
                },
                style: {
                  border: "none",
                  margin: "0 10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Poppins",
                  fontSize: 16,
                  fontWeight: "bold",
                  cursor: "pointer",
                  borderRadius: 4,
                  color: active == 6 ? "#000" : "#BDBDBD",
                  backgroundColor:
                    active == 6 ? "rgba(200,200,200,.2)" : "transparent",
                },
              },
            ],
          },
          // action
          {
            render: (
              <div
                style={{
                  display: "flex",
                  // height: "auto",
                  // width: "auto",
                  alignItems: "center",
                  marginRight: "2%",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    marginLeft: 15,
                    border: "1px solid #fff",
                    borderRadius: 16,
                  }}
                ></div>
                <div
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    width: "100%",
                    marginLeft: 15,
                    marginRight: 30,
                    height: "100%",
                  }}
                >
                  {SelectProvider.component}
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 30,
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      padding: "1%",
                      marginLeft: 15,
                      marginRight: 15,
                      aspectRatio: "1/1",
                    }}
                  >
                    <LogoutIcon
                      style={{
                        cursor: "pointer",
                        width: "80%",
                        aspectRatio: "1/1",
                      }}
                      onClick={() => {
                        localStorage.clear();
                        navigate("/");
                      }}
                    />

                    <p
                      style={{
                        fontSize: 14,
                        fontFamily: "Poppins",
                        color: "#BDBDBD",
                      }}
                    >
                      Sair
                    </p>
                  </div>

                  <p
                    style={{
                      fontSize: 12,
                      fontFamily: "Poppins",
                      color: "#BDBDBD",
                      textAlign: "center",
                    }}
                  >
                    Versão {process.env.REACT_APP_VERSION}
                  </p>
                </div>
              </div>
            ),
          },
        ],
      }
    : !isAdmin && context == "business"
    ? {
        component: "container",
        style: {
          display: "flex",
          alignItems: "center",
        },
        children: [
          {
            component: "button",
            text: "Painel",
            onClick: () => {
              setActive(1);
              navigate("/dashboard-business");
            },
            style: {
              border: "none",
              backgroundColor: theme.colors.backgroundBlue,
              margin: "0 10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Poppins",
              fontSize: 16,
              fontWeight: "bold",
              cursor: "pointer",
              color: active == 1 ? "#000" : "#BDBDBD",
            },
          },
          {
            component: "button",
            text: "Cadastro",
            onClick: () => {
              setActive(2);
              navigate("/dashboard-business-people");
            },
            style: {
              border: "none",
              backgroundColor: theme.colors.backgroundBlue,
              margin: "0 10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Poppins",
              fontSize: 16,
              fontWeight: "bold",
              cursor: "pointer",
              color: active == 1 ? "#000" : "#BDBDBD",
            },
          },
          // Payments
          // {
          //   component: "button",
          //   text: "Pagamentos",
          //   onClick: () => {
          //     setActive(3);
          //     navigate("/dashboard-business-payment");
          //   },
          //   style: {
          //     border: "none",
          //     backgroundColor: theme.colors.backgroundBlue,
          //     margin: "0 10px",
          //     display: "flex",
          //     justifyContent: "center",
          //     alignItems: "center",
          //     fontFamily: "Poppins",
          //     fontSize: 16,
          //     fontWeight: "bold",
          //     cursor: "pointer",
          //     color: active == 1 ? "#000" : "#BDBDBD",
          //   },
          // },
          {
            render: (
              <div
                style={{
                  display: "flex",
                  height: "auto",
                  width: "auto",
                  alignItems: "center",
                  marginRight: 5,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 50,
                    height: 50,
                  }}
                >
                  <LogoutIcon
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      localStorage.clear();
                      navigate("/");
                    }}
                  />
                </div>
                <p
                  style={{
                    fontSize: 12,
                    fontFamily: "Poppins",
                    color: "#BDBDBD",
                  }}
                >
                  {process.env.REACT_APP_VERSION}
                </p>
              </div>
            ),
          },
        ],
      }
    : false;
};

export default Navbar;
