import React, { useState } from "react";
import WaitingConfirm from "./WaitingConfirm";
import WaitingConfirmOpen from "./WaitingConfirmOpen";
import WaitingPET from "./WaitingPET";
import ServiceInProgress from "./ServiceInProgress";
import NewServiceCard from "./NewServiceCard";
import OnTheWay from "./OnTheWay";
import PetWithdrawal from "./PetWithdrawal";
import FinishedCard from "./FinishedCard";
import SuggestedTime from "./SuggestedTime";
import CanceledCard from "./CanceledCard";
import WaitingDelivery from "./WaitingDelivery";

function OrderCard({ order }) {
  switch (order?.status?.code) {
    case 1002:
      return <WaitingConfirmOpen order={order} />;
    case 998:
      return <WaitingConfirm order={order} />;
    case 997:
      return <SuggestedTime order={order} />;
    case 996:
      return <NewServiceCard order={order} />;
    case 5:
      if (order.delivery_service) {
        return <WaitingDelivery order={order} />;
      } else if (!order.delivery_service) {
        return <WaitingPET order={order} />;
      }
    // return <WaitingDelivery order={order} />;
    case 4:
      return <WaitingPET order={order} />;
    case 3:
      return <ServiceInProgress order={order} />;
    case 2:
      if (order.delivery_service) {
        return <OnTheWay order={order} />;
      } else {
        return <PetWithdrawal order={order} />;
      }
    case 1:
      // return <FinishedCard />;
      return <p></p>;
    case 0:
      // return <CanceledCard order={order} />;
      return <p></p>;
    case 999:
      return <p></p>;
    case 1000:
      return <p></p>;
    case 1001:
      return <p></p>;
  }
}

export default OrderCard;
