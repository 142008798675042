import React, { useEffect, useState } from "react";
import theme from "../theme";
import { useActive } from "../context/activeContext";
import api from "../service";
import ApiCep from "../service/cep";
import FolderImage from "../assets/images/folder.png";
import DownloadIcon from "@mui/icons-material/Download";
import FileSaver from "file-saver";
import InputMask from "react-input-mask";
import * as XLSX from "xlsx";
import ClearIcon from "@mui/icons-material/Clear";
import AlertPopUp from "./AlertPopUp";
import { useAlert } from "../context/alertContext";
import { useDropzone } from "react-dropzone";

let FormBusiness = ({ show }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (e) => {
      for (let file of e) {
        if (e.length && e[0].name.split(".")[1] === "xlsx") {
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet);
            setNewCad({ ...newCad, file: json });
          };
          reader.readAsArrayBuffer(file);
          setInputFileLabel(
            " 🆗 [ " + file.name.split(".")[0] + " ] carregado! "
          );
        } else {
          setInputFileLabel(" 😕 Arquivo inválido, seleciona outro.");
        }
      }
    },
  });
  const [inputError, setInputError] = useState(0);
  const { active, updateActive } = useActive();
  const [popup, setPopup] = useState(false);
  const [replacedAdressCode, setReplacedAdressCode] = useState("");
  const { errorPopUp, setErrorPopUp, alertMsg, setAlertMsg, setAlertTitle } =
    useAlert();
  const [inputFileLabel, setInputFileLabel] = useState(
    "Arraste ou clique para fazer o upload"
  );
  const [alertMessage, setAlertMessage] = useState("");
  const [replacedCode, setReplacedCode] = useState("");
  const [newCad, setNewCad] = useState({
    nickname: "",
    name: "",
    code: "",
    email: "",
    database: "",
    password: "",
    address: {
      code: "",
      street: "",
      number: "",
      district: "",
      city: "",
      state: "",
      country: "",
      complement: "",
    },
    file: [],
  });

  const validateEmail = (email) => {
    let validation = /[a-zA-Z0-9]+@[a-zA-Z0-9.-]+\.[a-z]{2,8}(\.[a-z]{2,8})?/;
    if (validation.test(email)) {
      return true;
    } else {
      return false;
    }
  };

  if (show) {
    return {
      component: "container",
      style: {
        width: "100%",
        padding: "2% 10%",
      },
      children: [
        //Dados Pessoais
        {
          component: "text",
          text: "Informações básicas",
          style: {
            color: theme.colors.darkGreen,
            fontSize: 28,
            fontFamily: "Poppins",
          },
        },
        //Row
        {
          component: "container",
          style: {
            width: "100%",
            display: "flex",
            marginTop: "50px",
          },
          children: [
            //input
            {
              component: "container",
              style: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
              },
              children: [
                {
                  component: "text",
                  text: "Nome fantasia",
                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },
                {
                  component: "input",
                  placeholder: "Digite aqui",
                  value: newCad.nickname,
                  onChange: (e) => {
                    setNewCad({ ...newCad, nickname: e.target.value });
                  },
                  onBlur: (e) => {
                    if (e.target.value == "") {
                      setInputError(1);
                    } else {
                      setInputError(0);
                    }
                  },
                  style: {
                    height: 50,
                    width: "70%",
                    backgroundColor: "#EFF0F7",
                    border:
                      inputError == 1
                        ? `1px solid ${theme.colors.pink2}`
                        : "none",
                    borderRadius: 16,
                    padding: "0 20px",
                    fontFamily: "Poppins",
                    fontSize: 16,
                    marginTop: 8,
                  },
                },
                {
                  component: "text",
                  text: inputError == 1 ? "Preencha o campo corretamente" : "",
                  style: {
                    color: theme.colors.pink2,
                    fontSize: 12,
                    fontFamily: "Poppins",
                    marginLeft: 5,
                  },
                },
              ],
            },
            {
              component: "container",
              style: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
              },
              children: [
                {
                  component: "text",
                  text: "CNPJ",
                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },
                {
                  render: (
                    <InputMask
                      mask="99.999.999/9999-99"
                      value={newCad.code}
                      onChange={(e) =>
                        setNewCad({
                          ...newCad,
                          code: e.target.value,
                          database: e.target.value,
                        })
                      }
                      onBlur={(e) => {
                        if (
                          newCad.code
                            .replaceAll(".", "")
                            .replaceAll("/", "")
                            .replaceAll("-", "")
                            .replaceAll("_", "").length > 13
                        ) {
                          setInputError(0);
                          setReplacedCode(
                            newCad.code
                              .replaceAll(".", "")
                              .replaceAll("/", "")
                              .replaceAll("-", "")
                          );
                        } else {
                          setInputError(2);
                        }
                      }}
                    >
                      {() => (
                        <input
                          style={{
                            height: 50,
                            width: "70%",
                            backgroundColor: "#EFF0F7",
                            border:
                              inputError == 2
                                ? `1px solid ${theme.colors.pink2}`
                                : "none",
                            borderRadius: 16,
                            padding: "0 20px",
                            fontFamily: "Poppins",
                            fontSize: 16,
                            marginTop: 8,
                          }}
                          placeholder="Digite aqui"
                        />
                      )}
                    </InputMask>
                  ),
                },
                {
                  component: "text",
                  text: inputError == 2 ? "Insira um CNPJ válido" : "",
                  style: {
                    color: theme.colors.pink2,
                    fontSize: 12,
                    fontFamily: "Poppins",
                    marginLeft: 5,
                  },
                },
              ],
            },
          ],
        },
        //Row
        {
          component: "container",
          style: {
            width: "100%",
            display: "flex",
            marginTop: "50px",
          },
          children: [
            //input
            {
              component: "container",
              style: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
              },
              children: [
                {
                  component: "text",
                  text: "Email",
                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },
                {
                  component: "input",
                  value: newCad.email,
                  onBlur: () => {
                    if (!validateEmail(newCad.email)) {
                      setInputError(3);
                    } else {
                      setInputError(0);
                    }
                  },
                  onChange: (e) =>
                    setNewCad({ ...newCad, email: e.target.value }),
                  placeholder: "Digite aqui",
                  style: {
                    height: 50,
                    width: "70%",
                    backgroundColor: "#EFF0F7",
                    border:
                      inputError == 3
                        ? `1px solid ${theme.colors.pink2}`
                        : "none",
                    borderRadius: 16,
                    padding: "0 20px",
                    fontFamily: "Poppins",
                    fontSize: 16,
                    marginTop: 8,
                  },
                },
                {
                  component: "text",
                  text: inputError == 3 ? "Insira um email válido" : "",
                  style: {
                    color: theme.colors.pink2,
                    fontSize: 12,
                    fontFamily: "Poppins",
                    marginLeft: 5,
                  },
                },
              ],
            },
            {
              component: "container",
              style: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
              },
              children: [
                {
                  component: "text",
                  text: "Senha",
                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },
                {
                  component: "input",
                  placeholder: "Digite aqui",
                  value: newCad.password,
                  onBlur: () => {
                    if (newCad.password.length < 8) {
                      setInputError(4);
                    } else {
                      setInputError(0);
                    }
                  },
                  onChange: (e) =>
                    setNewCad({ ...newCad, password: e.target.value }),
                  type: "password",
                  style: {
                    height: 50,
                    width: "70%",
                    backgroundColor: "#EFF0F7",
                    border:
                      inputError == 4
                        ? `1px solid ${theme.colors.pink2}`
                        : "none",
                    borderRadius: 16,
                    padding: "0 20px",
                    fontFamily: "Poppins",
                    fontSize: 16,
                    marginTop: 8,
                  },
                },
                {
                  component: "text",
                  text: "A senha deve ter pelo menos 8 caracteres",
                  style: {
                    color:
                      inputError == 4
                        ? theme.colors.pink2
                        : theme.colors.darkBlue,
                    fontSize: 12,
                    fontFamily: "Poppins",
                    marginLeft: 5,
                  },
                },
              ],
            },
          ],
        },
        {
          component: "container",
          style: {
            margin: "40px 0",
            height: 1,
            width: "100%",
            backgroundColor: "#E0E0E0",
          },
        },
        //Endereço
        {
          component: "text",
          text: "Endereço",
          style: {
            color: theme.colors.darkGreen,
            fontSize: 28,
            fontFamily: "Poppins",
          },
        },
        //Row
        {
          component: "container",
          style: {
            width: "100%",
            display: "flex",
            marginTop: "50px",
          },
          children: [
            //input
            {
              component: "container",
              style: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
              },
              children: [
                {
                  component: "text",
                  text: "CEP",

                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },
                {
                  render: (
                    <InputMask
                      mask="99999-999"
                      onChange={(e) =>
                        setNewCad({
                          ...newCad,
                          address: {
                            ...newCad.address,
                            code: e.target.value,
                          },
                        })
                      }
                      onBlur={async (e) => {
                        try {
                          let res = await ApiCep.getByCep(e.target.value);
                          setNewCad({
                            ...newCad,
                            address: {
                              ...newCad.address,
                              city: res.data.data.city,
                              street: res.data.data.street,
                              state: res.data.data.state,
                              district: res.data.data.neighborhood,
                            },
                          });
                          setReplacedAdressCode(
                            newCad.address.code.replace("-", "")
                          );
                        } catch {
                          setAlertTitle("Error");
                          setAlertMsg("CEP inválido");
                          setErrorPopUp(true);
                        }
                      }}
                      value={newCad.address.code}
                    >
                      {() => (
                        <input
                          style={{
                            height: 50,
                            width: "70%",
                            backgroundColor: "#EFF0F7",
                            border: "none",
                            borderRadius: 16,
                            padding: "0 20px",
                            fontFamily: "Poppins",
                            fontSize: 16,
                            marginTop: 8,
                          }}
                          placeholder="Digite aqui"
                        />
                      )}
                    </InputMask>
                  ),
                },
              ],
            },
            //input
            {
              component: "container",
              style: {
                flex: 2,
                display: "flex",
                flexDirection: "column",
              },
              children: [
                {
                  component: "text",
                  text: "Rua",
                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },
                {
                  render: (
                    <input
                      value={newCad.address.street}
                      onChange={(e) =>
                        setNewCad({
                          ...newCad,
                          address: {
                            ...newCad.address,
                            street: e.target.value,
                          },
                        })
                      }
                      style={{
                        height: 50,
                        width: "70%",
                        backgroundColor: "#EFF0F7",
                        border: "none",
                        borderRadius: 16,
                        padding: "0 20px",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginTop: 8,
                      }}
                      placeholder="Digite aqui"
                    />
                  ),
                },
              ],
            },
            //input
            {
              component: "container",
              style: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
              },
              children: [
                {
                  component: "text",
                  text: "Estado",
                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },
                {
                  render: (
                    <input
                      value={newCad.address.state}
                      onChange={(e) =>
                        setNewCad({
                          ...newCad,
                          address: {
                            ...newCad.address,
                            state: e.target.value,
                          },
                        })
                      }
                      style={{
                        height: 50,
                        width: "70%",
                        backgroundColor: "#EFF0F7",
                        border: "none",
                        borderRadius: 16,
                        padding: "0 20px",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginTop: 8,
                      }}
                      placeholder="Digite aqui"
                    />
                  ),
                },
              ],
            },
          ],
        },
        //Row
        {
          component: "container",
          style: {
            width: "100%",
            display: "flex",
            marginTop: "50px",
          },
          children: [
            //input
            {
              component: "container",
              style: {
                display: "flex",
                flexDirection: "column",
                width: "25%",
                margin: "0 0.5% 0 0",
              },
              children: [
                {
                  component: "text",
                  text: "Número",
                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },
                {
                  render: (
                    <input
                      value={newCad.address.number}
                      onChange={(e) => {
                        if (isNaN(e.target.value)) {
                          return;
                        }
                        setNewCad({
                          ...newCad,
                          address: {
                            ...newCad.address,
                            number: Number(e.target.value.replaceAll(".", "")),
                          },
                        });
                      }}
                      onBlur={() => {
                        if (newCad.address.number.length == 0) {
                          setInputError(10);
                        } else {
                          setInputError(0);
                        }
                      }}
                      style={{
                        height: 50,
                        width: "70%",
                        backgroundColor: "#EFF0F7",
                        border:
                          inputError == 10
                            ? `1px solid ${theme.colors.pink2}`
                            : "none",
                        borderRadius: 16,
                        padding: "0 20px",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginTop: 8,
                      }}
                      placeholder="Digite aqui"
                    />
                  ),
                },
                {
                  component: "text",
                  text: inputError == 10 ? "O número não pode ser vazio" : "",
                  style: {
                    color: theme.colors.pink2,
                    fontSize: 12,
                    fontFamily: "Poppins",
                    marginLeft: 5,
                  },
                },
              ],
            },
            //input
            {
              component: "container",
              style: {
                display: "flex",
                flexDirection: "column",
                width: "25%",
              },
              children: [
                {
                  component: "text",
                  text: "Complemento",
                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },
                {
                  render: (
                    <input
                      value={newCad.address.complement}
                      onChange={(e) => {
                        setNewCad({
                          ...newCad,
                          address: {
                            ...newCad.address,
                            complement: e.target.value,
                          },
                        });
                      }}
                      style={{
                        height: 50,
                        width: "70%",
                        backgroundColor: "#EFF0F7",
                        border: "none",
                        borderRadius: 16,
                        padding: "0 20px",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginTop: 8,
                      }}
                      placeholder="Digite aqui"
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          component: "container",
          style: {
            margin: "40px 0",
            height: 1,
            width: "100%",
            backgroundColor: "#E0E0E0",
          },
        },

        //Endereço
        {
          component: "container",
          style: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          },
          children: [
            {
              component: "text",
              text: "Lista de funcionários",
              style: {
                color: theme.colors.darkGreen,
                fontSize: 28,
                fontFamily: "Poppins",
              },
            },

            {
              render: (
                <button
                  // onClick={() => {
                  //   FileSaver.saveAs("https://httpbin.org/image", "image.jpg");
                  // }}
                  style={{
                    width: 70,
                    height: 70,
                    borderStyle: "hidden",
                    borderRadius: "50%",
                    backgroundColor: theme.colors.pink2,
                    cursor: "pointer",
                  }}
                >
                  <DownloadIcon
                    style={{
                      color: "#fff",
                    }}
                    onClick={async () => {
                      api.download.sheetModel("modelo_cadastro.xlsx");
                    }}
                  />
                </button>
              ),
            },
          ],
        },

        //Row
        {
          component: "container",
          style: {
            width: "100%",
            display: "flex",
            marginTop: "50px",
          },
          children: [
            {
              render: (
                <div
                  {...getRootProps()}
                  style={{
                    width: "100%",
                    height: 200,
                    background: "#EFF0F7",
                    borderRadius: 10,
                    borderWidth: 2,
                    borderStyle: "dashed",
                    borderColor: theme.colors.lightGreen,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <img src={FolderImage} />
                  <input
                    {...getInputProps()}
                    id="upload-file"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setNewCad({ ...newCad, file: [] });
                      e.preventDefault();
                      if (
                        e.target.files.length &&
                        e.target.files[0].name.split(".")[1] === "xlsx"
                      ) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                          const data = e.target.result;
                          const workbook = XLSX.read(data, { type: "array" });
                          const sheetName = workbook.SheetNames[0];
                          const worksheet = workbook.Sheets[sheetName];
                          const json = XLSX.utils.sheet_to_json(worksheet);
                          setNewCad({ ...newCad, file: json });
                        };
                        reader.readAsArrayBuffer(e.target.files[0]);
                        setInputFileLabel(
                          " 🆗 [ " +
                            e.target.files[0].name.split(".")[0] +
                            " ] carregado! "
                        );
                      } else
                        setInputFileLabel(
                          " 😕 Arquivo inválido, seleciona outro."
                        );
                    }}
                  />
                  <p
                    style={{
                      color: theme.colors.darkBlue,
                      fontSize: 20,
                      fontFamily: "Poppins",
                      marginBottom: 5,
                      marginTop: 20,
                    }}
                  >
                    {inputFileLabel}
                  </p>
                </div>
              ),
            },
          ],
        },

        {
          component: "container",
          style: {
            margin: "40px 0",
            height: 1,
            width: "100%",
            backgroundColor: "#E0E0E0",
          },
        },
        {
          component: "container",
          style: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
          },
          children: [
            // {
            //   render: (
            //     <AlertPopUp
            //       title="Error"
            //       background="#F68989"
            //       color="#874356"
            //       show={errorPopUp}
            //       setTrigger={setErrorPopUp}
            //       message={alertMessage}
            //     />
            //   ),
            // },
            {
              render: (
                <AlertPopUp
                  title="Sucesso"
                  background="#fff"
                  color="#0f0f0f"
                  show={popup}
                  callback={() => window.location.reload()}
                  setTrigger={setPopup}
                  message={alertMsg}
                />
              ),
            },

            {
              component: "button",
              text: "Salvar",
              disabled:
                newCad.nickname === "" ||
                newCad.code.replace(/[^0-9 ]/g, "") === "" ||
                newCad.email === "" ||
                newCad.password.length < 8 ||
                newCad.address.code.replace(/[^0-9 ]/g, "") === "" ||
                newCad.address.street === "" ||
                newCad.address.state === "" ||
                newCad.address.number.length === 0,
              onClick: async () => {
                if (
                  newCad.nickname == "" ||
                  newCad.code.replace(/[^0-9 ]/g, "") == "" ||
                  newCad.email == "" ||
                  newCad.password.length < 8 ||
                  newCad.address.code.replace(/[^0-9 ]/g, "") == "" ||
                  newCad.address.street == "" ||
                  newCad.address.state == "" ||
                  newCad.address.number == 0
                )
                  return;
                try {
                  let provider_info = (
                    await api.manager.getDataByCnpj(replacedCode)
                  ).data;

                  if (provider_info.data.status === 400) {
                    setAlertTitle("Error");
                    setAlertMsg("CNPJ inválido");
                    setErrorPopUp(true);
                  }

                  await api.manager.addBusiness({
                    nickname: newCad.nickname,
                    name: provider_info.data.razao_social,
                    code: replacedCode,
                    email: newCad.email,
                    password: newCad.password,
                    database: newCad.database,
                    address: {
                      code: replacedAdressCode,
                      street: newCad.address.street,
                      number: newCad.address.number,
                      district: newCad.address.district,
                      city: newCad.address.city,
                      state: newCad.address.state,
                      country: newCad.address.country,
                      complement: newCad.address.complement,
                    },
                    file: newCad.file,
                  });
                  setAlertMsg("Cadastrado com sucesso");
                  setPopup(true);
                  // window.location.reload();
                } catch {
                  setAlertTitle("Error");
                  setAlertMsg(
                    "Falha ao realizar o cadastro da empresa cliente"
                  );
                  setErrorPopUp(true);
                }
              },
              style: {
                fontFamily: "Poppins",
                fontSize: 16,
                fontWeight: "bold",
                backgroundColor:
                  newCad.nickname === "" ||
                  newCad.code.replace(/[^0-9 ]/g, "") === "" ||
                  newCad.email === "" ||
                  newCad.password.length < 8 ||
                  newCad.address.code.replace(/[^0-9 ]/g, "") === "" ||
                  newCad.address.street === "" ||
                  newCad.address.state === "" ||
                  newCad.address.number === 0
                    ? "#BDBDBD"
                    : theme.colors.pink2,
                border: "none",
                color:
                  newCad.nickname === "" ||
                  newCad.code.replace(/[^0-9 ]/g, "") === "" ||
                  newCad.email === "" ||
                  newCad.password.length < 8 ||
                  newCad.address.code.replace(/[^0-9 ]/g, "") === "" ||
                  newCad.address.street === "" ||
                  newCad.address.state === "" ||
                  newCad.address.number === 0 
                    ? "#D3D3D3"
                    : "#fff",
                width: 325,
                height: 60,
                borderRadius: 16,
                cursor:
                  newCad.nickname === "" ||
                  newCad.code.replace(/[^0-9 ]/g, "") === "" ||
                  newCad.email === "" ||
                  newCad.password.length < 8 ||
                  newCad.address.code.replace(/[^0-9 ]/g, "") === "" ||
                  newCad.address.street === "" ||
                  newCad.address.state === "" ||
                  newCad.address.number === 0 
                    ? "unset"
                    : "pointer",
              },
            },
          ],
        },
      ],
    };
  } else {
    return {
      component: "container",
    };
  }
};

export default FormBusiness;
