import React, { useState } from "react";
import { Render } from "@8_dev/sjson-web";
import Theme from "../../theme";
import api from "../../service";
import Navbar from "../../components/Navbar";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import AlertPopUp from "../../components/AlertPopUp";
import { useAlert } from "../../context/alertContext";

let PasswordForget = () => {
  const navigate = useNavigate();
  const { errorPopUp, setErrorPopUp } = useAlert();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [replacedCode, setReplacedCode] = useState("");
  const [errorAlertName, setErrorAlertName] = useState("");

  return Render({
    component: "container",
    style: {
      width: "100vw",
      height: "100vh",
      backgroundColor: Theme.colors.backgroundBlue,
      display: "flex",
      flexDirection: "column",
    },
    children: [
      Navbar({
        isAdmin: false,
        context: "login",
      }),
      {
        component: "container",
        style: {
          width: "100%",
          display: "flex",
          justifyContent: "center",
          margin: "80px 0 0 0",
        },
        children: [
          {
            component: "text",
            text: "Redefinição de senha",
            style: {
              fontFamily: "Poppins",
              fontSize: 28,
              fontWeight: "bold",
              margin: "40px 0",
            },
          },
        ],
      },
      {
        component: "container",
        style: {
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        },
        children: [
          {
            component: "container",
            style: {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              width: "45%",
              height: 300,
              backgroundColor: "#fff",
              borderRadius: 8,
              boxShadow: "0px 0px 25px rgba(182, 182, 188, 0.2)",
            },
            children: [
              {
                component: "container",
                style: {
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "space-evenly",
                },
                children: [
                  // {
                  //   component: "container",
                  //   style: {
                  //     display: "flex",
                  //     flexDirection: "column",
                  //     margin: "20px 0",
                  //   },
                  //   children: [
                  //     {
                  //       component: "text",
                  //       text: "Insira um E-mail válido",
                  //       style: {
                  //         fontFamily: "Poppins",
                  //         fontSize: 20,
                  //         fontWeight: "bold",
                  //       },
                  //     },
                  //     {
                  //       component: "input",
                  //       placeholder: "Digite aqui",
                  //       onChange: (e) => setEmail(e.target.value),
                  //       style: {
                  //         height: 50,
                  //         width: "100%",
                  //         backgroundColor: "#EFF0F7",
                  //         border: "none",
                  //         borderRadius: 16,
                  //         padding: "0 20px",
                  //         fontFamily: "Poppins",
                  //         fontSize: 16,
                  //         marginTop: 8,
                  //       },
                  //     },
                  //   ],
                  // },
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      flexDirection: "column",
                      margin: "20px 0",
                    },
                    children: [
                      {
                        component: "text",
                        text: "Insira o CNPJ de cadastro",
                        style: {
                          fontFamily: "Poppins",
                          fontSize: 20,
                          fontWeight: "bold",
                        },
                      },
                      {
                        render: (
                          <InputMask
                            mask="99.999.999/9999-99"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            onBlur={() => {
                              setReplacedCode(
                                code
                                  .replaceAll(".", "")
                                  .replaceAll("/", "")
                                  .replaceAll("-", "")
                              );
                            }}
                          >
                            {() => (
                              <input
                                style={{
                                  height: 70,
                                  width: 325,
                                  backgroundColor: "#EFF0F7",
                                  border: "none",
                                  borderRadius: 16,
                                  padding: "0 20px",
                                  fontFamily: "Poppins",
                                  fontSize: 16,
                                  margin: "8px 0 16px",
                                }}
                                placeholder="Digite aqui"
                              />
                            )}
                          </InputMask>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                render: (
                  <AlertPopUp
                    title="Error"
                    background="#F68989"
                    color="#874356"
                    show={errorPopUp}
                    setTrigger={setErrorPopUp}
                    message={errorAlertName}
                  />
                ),
              },

              {
                component: "button",
                text: "Confirmar",
                onClick: async () => {
                  let { data } = await api.passwordforget.emailPost({
                    code: replacedCode,
                  });
                  if (data.data.status) {
                    console.log(data.data);
                    localStorage.setItem("email", data.data.email);
                    navigate("/password-forget/confirm");
                  } else {
                    // console.log(data);
                    setErrorAlertName(data.data.errors[0].name);
                    setErrorPopUp(true);
                  }
                },
                style: {
                  fontFamily: "Poppins",
                  fontSize: 16,
                  fontWeight: "bold",
                  backgroundColor: Theme.colors.pink2,
                  border: "none",
                  color: "#fff",
                  width: 325,
                  height: 70,
                  borderRadius: 16,
                  cursor: "pointer",
                  margin: "0 0 40px 0",
                },
              },
            ],
          },
        ],
      },
    ],
  });
};

export default PasswordForget;
