import React, { useState } from "react";
import { Render } from "@8_dev/sjson-web";
import Theme from "../../theme";
import Navbar from "../../components/Navbar";
import ContainerRegisterForm from "../../components/ContainerRegisterForm";

let Login = () => {
  return Render({
    component: "container",
    style: {
      width: "100vw",
      height: "100vh",
      maxWidth: "100%",
      maxHeight: "100%",
      backgroundColor: Theme.colors.backgroundBlue,
      display: "flex",
      flexDirection: "column",
    },
    children: [
      // Navbar({}),
      {
        component: "container",
        style: {
          width: "100%",
          display: "flex",
          justifyContent: "center",
        },
        children: [
          // {
          //   component: "text",
          //   text: "Olá! Que)",
          //   style: {
          //     fontFamily: "Poppins",
          //     fontSize: 28,
          //     fontWeight: "bold",
          //     margin: "40px 0",
          //   },
          // },
        ],
      },
      // {
      //   component: "container",
      //   style: {
      //     width: "100%",
      //     height: "100%",
      //     display: "flex",
      //     justifyContent: "center",
      //   },
      //   children: [ContainerRegisterForm()],
      // },
    ],
  });
};

export default Login;
