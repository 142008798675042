import React, { useEffect, useState } from "react";
import Theme from "../../theme";
import api from "../../service";
import CloseIcon from "@mui/icons-material/Close";
import AccessTime from "@mui/icons-material/AccessTime";
import DeliveryLogo from "../../assets/images/delivery.png";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
const formatDate = (date, hour) => {
  if (hour.includes(":")) hour = hour.split(":")[0];
  let eventDate = new Date(date);
  eventDate = new Date(
    eventDate.getUTCFullYear(),
    eventDate.getUTCMonth(),
    eventDate.getUTCDate(),
    hour,
    0,
    0
  );
  return (
    new Intl.DateTimeFormat("pt-br", {
      weekday: "long",
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    }).format(eventDate) +
    " às " +
    new Intl.DateTimeFormat("pt-br", {
      hour: "numeric",
      minute: "numeric",
    }).format(eventDate)
  );
};

function NewServiceCard(order) {
  // const deliveryLogo = api.getImage("provider");

  const [popUp, setPopUp] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [serviceSelected, setServiceSelected] = useState("");
  const [serviceSelectedObj, setServiceSelectedObj] = useState({});
  const [servicesSelected, setServicesSelected] = useState([]);
  let showAddress = false;

  return (
    <div
      style={{
        backgroundColor: "#fff",
        border: "1px solid #E0E0E0",
        borderRadius: 10,
        width: "90%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        paddingBottom: 20,
        marginTop: "2%",
      }}
    >
      <div
        style={{
          width: "100%",
          height: 12,
          borderRadius: "8px 8px 0 0",
          position: "relative",
          top: 0,
          backgroundColor: "#FF9E58",
        }}
      />
      <div
        style={{
          width: "100%",
          margin: "30px 0 10px",
          display: "flex",
          height: 70,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#FF9E58",
            height: 20,
            width: 20,
            borderRadius: "50%",
          }}
        />
        <div
          style={{
            backgroundColor: "#FF9E58",
            height: 2,
            width: "20%",
          }}
        ></div>
        <div
          style={{
            backgroundColor: "#FF9E58",
            height: 20,
            width: 20,
            borderRadius: "50%",
          }}
        >
          <p
            style={{
              display: "flex",
              fontFamily: "Poppins",
              position: "relative",
              whiteSpace: "nowrap",
              top: -30,
            }}
          >
            Pedido {order.order.id} - Aguardando aprovação
          </p>
        </div>
        <div
          style={{
            backgroundColor: "#D2D2D2",
            height: 2,
            width: "20%",
          }}
        />
        <div
          style={{
            backgroundColor: "#fff",
            border: "2px solid #D2D2D2",
            height: 20,
            width: 20,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        ></div>
        <div
          style={{
            backgroundColor: "#D2D2D2",
            height: 2,
            width: "20%",
          }}
        />
        <div
          style={{
            backgroundColor: "#fff",
            border: "2px solid #D2D2D2",
            height: 20,
            width: 20,
            borderRadius: "50%",
          }}
        />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <p
            style={{
              fontFamily: "Poppins",
              fontSize: 16,
              marginBottom: 20,
              marginLeft: 60,
            }}
          >
            Serviços:
          </p>

          <div
            style={{
              maxHeight: 300,
              width: 350,
              overflow: "auto",
              direction: "rtl",
            }}
          >
            {order.order.delivery_service && (
              <div
                style={{
                  background: "#fafafa",
                  width: 300,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px 0px 10px 20px",
                  textAlign: "left",
                  borderRadius: 20,
                  marginBottom: 20,
                  justifyContent: "flex-start",
                }}
              >
                <div style={{ display: "flex", marginBottom: 7 }}>
                  {/* <div
                    style={{
                      width: 50,
                      height: 50,
                      marginRight: 5,
                      backgroundColor: "red",
                    }}
                  /> */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      height: 50,
                      marginRight: 20,
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 600,
                        fontFamily: "Poppins",
                        fontSize: 16,
                      }}
                    >
                      Serviço de busca
                    </p>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 12,
                        marginTop: 10,
                      }}
                    >
                      {order.order.address.street}, {order.order.address.number}{" "}
                      {order.order.address.district}{" "}
                      {order.order.address.complement} entre{" "}
                      {new Date(order.order.service_date).toLocaleDateString(
                        "pt-br"
                      )}
                      {new Date(order.order.delivery_date).toLocaleTimeString(
                        "pt-br",
                        { hour: "2-digit", minute: "2-digit" }
                      )}{" "}
                      -
                      {new Date(order.order.service_date).toLocaleTimeString(
                        [],
                        { hour: "2-digit", minute: "2-digit" }
                      )}
                    </p>
                  </div>

                  <img
                    src={api.utils.getImage("delivery")}
                    style={{
                      width: 50,
                      height: 50,
                      marginRight: 10,
                    }}
                    onClick={() => console.log(order)}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <p
                    style={{
                      fontWeight: 700,
                      fontFamily: "Poppins",
                      fontSize: 22,
                    }}
                  >
                    R${order.order.delivery_service_value}
                  </p>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 20,
                      marginRight: 5,
                    }}
                  ></p>
                </div>
              </div>
            )}
            {order?.order?.services?.map((service) => {
              console.log("VerServiço", service);
              if (
                service?.service?.category?.code === 5 ||
                service?.service?.category?.code === 6
              )
                showAddress = true;

              return (
                <div
                  style={{
                    background: "#fafafa",
                    width: 300,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "10px 0px 10px 20px",
                    borderRadius: 20,
                    textAlign: "left",
                    marginBottom: 20,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginBottom: 7,
                      width: "100%",
                      alignItems: "flex-start",
                      justifyContent: "flex-end",
                    }}
                  >
                    {/* <div
                    style={{
                      width: 50,
                      height: 50,
                      marginRight: 5,
                      backgroundColor: "red",
                    }}
                  /> */}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginRight: 20,
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 600,
                          fontFamily: "Poppins",
                          fontSize: 16,
                        }}
                      >
                        {service?.service?.category?.code == 4
                          ? service?.service?.category?.name
                          : service?.service?.sub_category?.name}
                      </p>
                      <p
                        style={{
                          fontFamily: "Poppins",
                          fontSize: 12,
                          marginTop: 10,
                        }}
                      >
                        {" "}
                        {service.description != ""
                          ? service.description
                          : "Sem mais informações"}
                      </p>
                    </div>
                    <img
                      src={service?.service?.category?.image}
                      style={{
                        width: 50,
                        height: 50,
                        marginRight: 10,
                        justifyContent: "center",
                      }}
                    />
                  </div>

                  <div style={{ display: "flex" }}>
                    <p
                      style={{
                        fontWeight: 700,
                        fontFamily: "Poppins",
                        fontSize: 22,
                      }}
                    >
                      R${" "}
                      {service.value.toString().includes(".") &&
                      service.value != 0
                        ? service.value.toString().split(".").length >= 2
                          ? service.value.toString().replace(".", ",")
                          : `${service.value.toString().replace(".", ",")}0`
                        : `${service.value},00`}
                    </p>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 20,
                        marginRight: 5,
                      }}
                    ></p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/*infoOrder*/}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 3,
            marginLeft: 20,
          }}
        >
          <p
            style={{
              fontFamily: "Poppins",
              fontSize: 16,
              marginBottom: 20,
            }}
          >
            Detalhes:
          </p>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p
              style={{
                fontSize: 15,
                fontWeight: 600,
                fontFamily: "Poppins",
                marginTop: 20,
              }}
            >
              Cliente: {order.order.people.name}
            </p>
            <p
              style={{
                fontSize: 15,
                fontWeight: 600,
                fontFamily: "Poppins",
                marginTop: 20,
              }}
            >
              Data:{" "}
              {format(order.order.service_date, "EEEE, dd MMMM", {
                locale: ptBR,
                timeZone: "America/Sao_Paulo",
              })}{" "}
              às {order.order.hour}
            </p>
            <p
              style={{
                fontSize: 15,
                fontWeight: 600,
                fontFamily: "Poppins",
                marginTop: 20,
              }}
            >
              PET:
              <span> {order.order.pet.name}</span>
              {", "}
              {order.order.pet.breed.name
                ? order.order.pet.breed.name
                : "Sem raça definida"}
              , porte {order?.order?.pet?.size?.initials}
            </p>

            <div
              style={{
                display: showAddress ? "flex" : "none",
                justifyContent: "start",
                flexDirection: "row",
                alignItems: "start",
                marginTop: "1%",
              }}
            >
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: 14,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flext-start",
                }}
              >
                <p>
                  {order.order.address.street}, {order.order.address.number}{" "}
                </p>
                <p>
                  {order.order.address.district}{" "}
                  {order.order.address.complement}
                </p>
              </p>
            </div>

            <p
              style={{
                fontSize: 15,
                fontWeight: 600,
                fontFamily: "Poppins",
                marginTop: 20,
              }}
            >
              Total: R$
              {order.order.service_value + order.order.delivery_service_value}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "0 30px 30px 0",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: 10,
              height: 10,
            }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <AccessTime
              style={{
                fontSize: 100,
                color: "#FF9E58",
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: popUp ? "flex" : "none",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            boxShadow: "0 0 100px 100px rgba(0, 0, 0, 0.5)",
            backgroundColor: "#fff",
            borderRadius: 10,
            width: 800,
            zIndex: 9,
          }}
        >
          <div
            style={{
              height: 50,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: 20,
            }}
          >
            <h2
              style={{
                fontFamily: "Poppins",
                fontWeight: "bold",
                fontSize: 20,
                maxWidth: 550,
              }}
            >
              Selecione um ou mais serviços que desejar adicionar ao pedido
              original:
            </h2>
            <div
              onClick={() => {
                setServiceSelected("");
                setServiceSelectedObj({});
                setPopUp(false);
              }}
              style={{
                position: "relative",
                top: -2,
                backgroundColor: Theme.colors.pink2,
                width: 35,
                height: 35,
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <CloseIcon
                style={{
                  fontSize: 28,
                  color: "#fff",
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              margin: "30px 0",
              padding: "0 10%",
            }}
          >
            {selectOptions.map((item) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 15,
                  }}
                >
                  <input
                    type="checkbox"
                    onClick={() => {
                      if (servicesSelected.includes(item)) {
                        let array = servicesSelected.filter(
                          (e) => e._id !== item._id
                        );
                        setServicesSelected(array);
                      } else {
                        setServicesSelected((old) => [...old, item]);
                      }
                    }}
                  />
                  <p style={{ fontSize: 20, marginLeft: 10 }}>
                    {item.sub_category.name} - {item.description} - R$
                    {item.value}
                  </p>
                </div>
              );
            })}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              margin: "20px 0",
            }}
          >
            <button
              onClick={async () => {
                await api.provider.addNewService({
                  order: order.order._id,
                  service: servicesSelected.map((e) => {
                    return {
                      service: e._id,
                      description: e.description,
                      value: e.value,
                      amount: 1,
                    };
                  }),
                });
                setServiceSelected("");
                setServiceSelectedObj({});
                setPopUp(false);
              }}
              style={{
                background: servicesSelected.length
                  ? Theme.colors.pink2
                  : "#c3c3c3",
                width: 350,
                height: 50,
                padding: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                borderRadius: 10,
                fontSize: 18,
                color: "#fff",
                cursor: servicesSelected.length ? "pointer" : "auto",
              }}
              disabled={!servicesSelected.length}
            >
              Confirmar serviço adicional
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewServiceCard;
