import React from "react";
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import DashboardAdmin from "./pages/DashboardAdmin";
import DashboardProvider from "./pages/DashboardProvider";
import DashboardBusiness from "./pages/DashboardBusiness";
import DashboardPeople from "./pages/Business/BusinessPeople";
import DashboardPayment from "./pages/Business/BusinessPayment";
import ProviderServices from "./pages/ProviderServices";
import ProviderDaycare from "./pages/ProviderDaycare";
import ProviderSchedule from "./pages/ProviderSchedule";
import ProviderPromotion from "./pages/ProviderPromotion";

import Login from "./pages/Login";
import Invite from "./pages/Invite";
import ActiveProvider from "./context/activeContext";
import AuthProvider from "./context/authContext";
import Privacidade from "./pages/Privacidade";
import PasswordForget from "./pages/PasswordForget";
import PasswordForgetConfirm from "./pages/PasswordForgetConfirm";
import NewPassword from "./pages/NewPassword";
import AlertProvider, { useAlert } from "./context/alertContext";
import SocketProvider from "./context/socketContext";
import TakeAndBring from "./pages/TakeAndBring";

const isLoggedAsManager = () =>
  !!localStorage.getItem("token") && localStorage.getItem("type") == "manager";

const PrivateRouteManager = () => {
  return isLoggedAsManager() ? <Outlet /> : <Navigate to="/" />;
};

const isLoggedAsProvider = () =>
  !!localStorage.getItem("token") && localStorage.getItem("type") == "provider";

const PrivateRouteProvider = () => {
  return isLoggedAsProvider() ? <Outlet /> : <Navigate to="/" />;
};

const isLoggedAsBusiness = () =>
  !!localStorage.getItem("token") && localStorage.getItem("type") == "business";

const PrivateRouteBusiness = () => {
  return isLoggedAsBusiness() ? <Outlet /> : <Navigate to="/" />;
};
export default function App() {
  return (
    <AlertProvider>
      <SocketProvider>
        <AuthProvider>
          <ActiveProvider>
            <Router>
              <Switch>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/invite" element={<Invite />} />
                <Route
                  exact
                  path="/termo-de-privacidade"
                  element={<Privacidade />}
                />
                <Route
                  exact
                  path="/dashboard-admin"
                  element={<PrivateRouteManager />}
                >
                  <Route
                    exact
                    path="/dashboard-admin"
                    element={<DashboardAdmin />}
                  />
                </Route>
                <Route
                  exact
                  path="/dashboard-provider"
                  element={<PrivateRouteProvider />}
                >
                  <Route
                    exact
                    path="/dashboard-provider"
                    element={<DashboardProvider />}
                  />
                </Route>
                <Route
                  exact
                  path="/dashboard-provider/services"
                  element={<PrivateRouteProvider />}
                >
                  <Route
                    exact
                    path="/dashboard-provider/services"
                    element={<ProviderServices />}
                  />
                </Route>
                <Route
                  exact
                  path="/dashboard-provider/promotion"
                  element={<PrivateRouteProvider />}
                >
                  <Route
                    exact
                    path="/dashboard-provider/promotion"
                    element={<ProviderPromotion />}
                  />
                </Route>
                <Route
                  exact
                  path="/dashboard-provider/daycare"
                  element={<PrivateRouteProvider />}
                >
                  <Route
                    exact
                    path="/dashboard-provider/daycare"
                    element={<ProviderDaycare />}
                  />
                </Route>

                <Route
                  exact
                  path="/dashboard-provider/schedule"
                  element={<PrivateRouteProvider />}
                >
                  <Route
                    exact
                    path="/dashboard-provider/schedule"
                    element={<ProviderSchedule />}
                  />
                </Route>

                <Route
                  exact
                  path="/password-forget"
                  element={<PasswordForget />}
                ></Route>
                <Route
                  exact
                  path="/password-forget/confirm"
                  element={<PasswordForgetConfirm />}
                ></Route>
                <Route
                  exact
                  path="/password-forget/new-password"
                  element={<NewPassword />}
                ></Route>
                <Route
                  exact
                  path="/dashboard-provider/take-and-bring"
                  element={<TakeAndBring />}
                ></Route>
                <Route
                  exact
                  path="/dashboard-business"
                  element={<PrivateRouteBusiness />}
                >
                  <Route
                    exact
                    path="/dashboard-business"
                    element={<DashboardBusiness />}
                  />
                </Route>
                <Route
                  exact
                  path="/dashboard-business-people"
                  element={<PrivateRouteBusiness />}
                >
                  <Route
                    exact
                    path="/dashboard-business-people"
                    element={<DashboardPeople />}
                  />
                </Route>
                <Route
                  exact
                  path="/dashboard-business-payment"
                  element={<PrivateRouteBusiness />}
                >
                  <Route
                    exact
                    path="/dashboard-business-payment"
                    element={<DashboardPayment />}
                  />
                </Route>
                <Route
                  exact
                  path="/dashboard-business-payment"
                  element={<PrivateRouteBusiness />}
                >
                  <Route
                    exact
                    path="/dashboard-business-payment"
                    element={<DashboardPayment />}
                  />
                </Route>
              </Switch>
            </Router>
          </ActiveProvider>
        </AuthProvider>
      </SocketProvider>
    </AlertProvider>
  );
}
