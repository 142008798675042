import React, { useEffect, useState } from "react";
import { Render } from "@8_dev/sjson-web";
import Theme from "../../theme";
import Navbar from "../../components/Navbar";
import FormRegisterService from "../../components/FormRegisterService";
import { useActive } from "../../context/activeContext";
import { useAuth } from "../../context/authContext";
import FormSchedule from "../../components/FormSchedule";

let Privacidade = () => {
  return Render({
    component: "container",
    style: {
      width: "100vw",
      backgroundColor: Theme.colors.backgroundBlue,
      display: "flex",
      flexDirection: "column",
      padding: 20,
    },
    children: [
      {
        render: (
          <h1
            style={{
              textAlign: "center",
              fontSize: 20,
              fontWeight: "bold",
              marginBottom: 20,
            }}
          >
            INSTRUMENTO DE TERMOS E CONDIÇÕES DE USO DO APLICATIVO PETIN
          </h1>
        ),
      },
      {
        component: "text",
        style: {
          textAlign: "center",
        },
        text: `

        PETIN SOLUÇÕES DIGITAIS PARA SERVICOS PETS LTDA, empresa devidamente inscrita no CNPJ/ME sob o nº. 42.597.071/0001-63, com sede na Rua Campevas, nº. 363, Perdizes, São Paulo/SP, CEP 05016-010, para os efeitos deste instrumento será denominada “PETIN”.
        
        Pelo presente instrumento (“Termos e Condições de Uso”), os consumidores (“Usuários”) restam cientes e concordam que, ao utilizarem o aplicativo PETIN (“App”), aderem e se submetem integralmente às suas regras, em conformidade com a Lei nº 12.965/14 e a Lei nº 8.078/90, bem como o Decreto nº 8.771/16.
        
        
            •	OBJETO
        
            •	Este Termos e Condições de Uso se aplica para regular a autorização de uso do serviço oferecido através do App aos seus Usuários, qual seja, possibilitar a escolha e aquisição de serviços para animais domésticos (“Serviços”), divulgados/ofertados via App pelos Parceiros, adiante descritos.
        
            •	Ao aceitar o presente Termos e Condições de Uso, o Usuário anui e se declara ciente de todas as obrigações contidas no mesmo, em especial, no tocante à responsabilidade única e exclusiva dos Parceiros na prestação dos Serviços contratados através do App.
        
            •	Desde logo, fica esclarecido aos Usuários – os quais se declaram cientes –, que os Serviços oferecidos por meio do App se relacionam apenas à intermediação (com opção de pagamento on-line) para comercialização de Serviços a serem prestados por fornecedores e lojas parceiras (“Parceiros”). Sendo assim, a PETIN não se responsabiliza por nenhuma compra ou transação realizada entre Usuários e Parceiros no App.
        
            •	Os Serviços oferecidos por meio do App consistem, portanto, na intermediação, pela PETIN, entre os Usuários e os Parceiros cadastrados, possibilitando, assim, que os Usuários encaminhem aos Parceiros pedidos de compra dos Serviços que desejarem, ofertados pelos Parceiros no App.
        
        
            •	CADASTRO
        
            •	O Usuário, para utilizar os Serviços, deverá ter capacidade jurídica para atos civis, ser maior de 18 anos ou emancipado, e deverá, necessariamente, prestar as informações exigidas no cadastro (“Cadastro”), assumindo integralmente a responsabilidade (cível e criminal) pela exatidão e veracidade das informações fornecidas no Cadastro, as quais poderão ser verificadas a qualquer momento, pela PETIN.
        
            •	Em caso de informações incorretas, inverídicas, duplicadas ou não confirmadas, bem como na hipótese da negativa em corrigi-las ou enviar documentação que comprove a correção, a PETIN se reserva o direito de não concluir o cadastramento em curso ou, ainda, de bloquear o cadastro já existente, impedindo o Usuário de utilizar os serviços on-line até que, a critério da PETIN, a situação anômala esteja regularizada. A PETIN, também, se reserva o direito de impedir, a seu critério, novos Cadastros, ou cancelar os já efetuados, em caso de ser detectada inconsistência que, em sua análise, seja revestida de gravidade ou demonstre tentativa deliberada de burlar as regras aqui descritas, obrigatórias para todos os Usuários. Também agirá a PETIN de tal forma caso verifique descumprimento, pelo Usuário, de qualquer obrigação prevista no presente Termos e Condições de Uso.
        
            •	O Cadastro do Usuário é pessoal e intransferível, sendo vedado aos Usuários a cessão, venda, aluguel ou qualquer outra forma de transferência. O Usuário será o único responsável por sua conta de acesso e responderá por todos os atos praticados nela, e, para esse fim, se declara ciente do dever de notificar imediatamente a PETIN sobre o extravio, perda, roubo ou qualquer uso não autorizado de sua conta de acesso, além de qualquer quebra de segurança de seu conhecimento.
        
            •	Efetuado com sucesso o Cadastro, o Usuário terá acesso ao App por meio de login e senha, dados esses que se compromete a não divulgar a terceiros, ficando sob sua exclusiva responsabilidade qualquer solicitação de serviço que seja feita com o uso do login e senha de sua titularidade.
        
            •	OBRIGAÇÕES DO USUÁRIO
        
            •	Efetuado com o sucesso o Cadastro, o Usuário se obriga a não divulgar a terceiros seu login e senha de acesso, nem permitir o uso de tais informações por terceiros, responsabilizando-se por quaisquer consequências em virtude do uso da conta de acesso de sua titularidade.
        
            •	É obrigação do Usuário fornecer informações cadastrais totalmente verídicas e exatas, responsabilizando-se, exclusiva e integralmente (em todas as searas jurídicas), pelo conteúdo informado durante o Cadastro, obrigando-se a mantê-lo atualizado.
        
            •	O Usuário se obriga a pagar integralmente o preço dos Serviços que forem por si solicitados aos Parceiros e efetivamente contratados.
        
            •	O Usuário se obriga a não transferir à terceiros os dados de acesso (login e senha), bem como informar imediatamente à PETIN em caso de esquecimento ou usurpação dos dados de acesso.
        
            •	O Usuário se obriga, também, a não utilizar o App para realizar atos contrários à moral, à lei, à ordem pública e aos bons costumes contra a PETIN, Parceiros ou a terceiros.
        
            •	OBRIGAÇÕES DA PETIN
        
            •	Disponibilizar no App espaço virtual que permita ao Usuário, devidamente cadastrado, efetivar pedidos de Serviços, ofertados pelos Parceiros e, também, disponibilizar ao Usuário meios de pagamento do preço dos produtos e/ou serviços on-line.
        
            •	Proteger, por meio de armazenamento em servidores ou quaisquer outros meios magnéticos de alta segurança, a confidencialidade de todas as informações e cadastros relativos aos Usuários, assim como valores atinentes às operações financeiras advindas da operacionalização dos serviços previstos no presente Termos e Condições de Uso. Contudo, não responderá pela reparação de prejuízos que possam ser derivados de apreensão e cooptação de dados por parte de terceiros que, rompendo os sistemas de segurança, consigam acessar essas informações.
        
            •	Disponibilizar o endereço eletrônico XXX para que o Usuário possa estabelecer contato e dirigir qualquer comunicação à PETIN.
        
            •	RESPONSABILIDADES
        
            •	O Usuário assume a responsabilidade (cível e criminal) pela exatidão e veracidade das informações fornecidas no Cadastro.
        
            •	O Usuário assume responsabilidade por todos os atos e solicitações de Serviços que praticar no App, e, também, daqueles que terceiros praticarem em seu nome, por meio do uso de sua conta de acesso (seja a partir da informação de login senha seja pelo acesso direto dispositivo eletrônico com o App cadastrado, como aparelho celular).
        
            •	O Usuário se declara ciente de que a PETIN não detém a posse nem propriedade dos Serviços ou locais onde os mesmo serão realizados, e que as ofertas são realizadas em nome do respectivo Parceiro, não sendo, assim, a PETIN responsável pela análise de compra, análise pagamento, análise de recebimento, bem como sobre qualquer informação imprecisa, fraudulenta ou divergente nos materiais veiculados pelos Parceiros, não tendo, desta forma, responsabilidade ou ação decisória nos critérios utilizados pelos Parceiros.
        
            •	O Usuário se declara ciente de que a relação de consumo é gerada diretamente com os Parceiros, por isto, as reclamações sobre a garantia devem ser realizadas diretamente aos Parceiros, que têm a obrigação de cumprir com as reclamações nos termos aqui definidos. Desta forma, a PETIN não se responsabiliza por compras efetuadas entre Usuários e os Parceiros, não prestando garantia, em qualquer hipótese, dos Serviços adquiridos, restando isenta de quaisquer reivindicações ou ações judiciais propostas por Usuários ou terceiros.
        
            •	A PETIN não se responsabiliza, também, por qualquer ação ou omissão dos Usuários tomada com base em informações, anúncios ou outros materiais veiculados no App pelos Parceiros.
        
            •	A PETIN não se responsabiliza por:
        
        a) Conteúdo e funcionamento dos sites, apps ou lojas dos Parceiros;
        b) Negociações e transações comerciais feitas diretamente (fora do App) entre os Usuários e os Parceiros, assim como eventuais danos e prejuízos resultantes destas;
        c) Origem, qualidade, quantidade e entrega dos Serviços divulgados pelos Parceiros; 
        d) Obrigações de natureza tributária aplicáveis às transações entre os Usuários e os Parceiros, nos termos do artigo 110 e 123 do Código Tributário Nacional;
        e) Opiniões e reclamações de Usuários sobre os Parceiros, seus produtos ou serviços, sendo a responsabilidade exclusiva de quem as emitiu.
        
            •	O App e o conteúdo nele disponível poderão conter anúncios, informações de caráter comercial e publicitário e/ou links para outros websites, sem qualquer relação ou ligação com a PETIN, que, desde já, declara não se responsabilizar por esses conteúdos, não gerando qualquer relação jurídica em função deles. O acesso a eventuais links através do App será realizado por livre e inteira opção do Usuário e sob sua exclusiva responsabilidade.
        
            •	A PETIN utiliza em seu aplicativo as melhores e mais modernas tecnologias para a segurança de seus Usuários. No entanto, em decorrência de questões operacionais e de terceirização de serviços, o App pode estar sujeito a eventuais problemas de interrupção, falha técnica e indisponibilidade de funcionamento temporário. Assim, quando for razoavelmente possível, a PETIN advertirá previamente as interrupções do funcionamento de seu App aos seus Usuários.
        
            •	A PETIN não se responsabiliza pela eventual reprovação de crédito no momento da realização de compra, pois trata-se de procedimento da respectiva administradora de cartão de crédito, utilizado pelo Usuário. Informamos, também, que a PETIN não tem conhecimento do motivo da reprovação de crédito, portanto, caso ocorra a reprovação da compra, o Usuário deverá entrar em contato com a administradora do cartão, utilizado no momento da compra, para solucionar o incidente.
        
            •	A PETIN não se responsabiliza pela disponibilização de crédito na conta do Usuário, uma vez que o mesmo ocorrerá mediante pagamento específico, a ser realizado pelo Usuário ou terceiro em seu favor. Tão logo seja verificado o pagamento da eventual quantia, os valores serão disponibilizados na conta do Usuário respectivo.
        
            •	PROPRIEDADE INTELECTUAL
        
        6.1 	Ao aceitar este Termos e Condições de Uso, o Usuário reconhece e aceita que todas as informações, elementos, conteúdo e ferramentas contidas no App são de propriedade exclusiva da PETIN, estando sujeitas à proteção de direitos autorais/industriais sobre marcas, patentes, logotipos, insígnias, fotos, imagens, descrições, textos, “layout”, símbolos, sinais distintivos, manual (is) ou quaisquer outros materiais correlatos ao App, de acordo com as leis brasileiras e tratados e convenções internacionais dos quais o Brasil é signatário, especialmente pelas disposições contidas nas Leis nº 9.279/96, 9.609/98 e 9.610/98, e que não pleiteará ou reclamará a qualquer tempo, tais direitos de propriedade da PETIN como se seus fossem.
        
        6.2 	O Usuário reconhece, expressamente, que os conteúdos mencionados na Cláusula 6.1 não podem ser alterados, copiados, extraídos ou utilizados sem prévia e expressa autorização da PETIN, ficando ciente, desde já, que a utilização indevida dos conteúdos supramencionados pode acarretar sanções civis e criminais.
        
        6.3 	A eventual retirada de qualquer anúncio, artigo, vídeo, notícia ou fotografia reproduzido no App, pela PETIN, em decorrência de alguma reclamação, deverá ser sempre compreendida como uma tentativa de evitar qualquer desconforto aos Usuários e, jamais, como reconhecimento de qualquer infração ou culpa pela PETIN a direito de terceiro.
        
        6.4 	As fotos e imagens utilizadas no App podem não refletir com exatidão os Serviços ou a situação real do cenário reproduzido, sendo meramente ilustrativas.
        
            •	MODIFICAÇÕES DESTE TERMO
        
            •	O presente Termos e Condições de Uso poderá, a qualquer tempo, ter seu conteúdo, ou parte dele, modificado para adequações e inserções, tudo em prol do aprimoramento dos serviços disponibilizados.
        
            •	As novas condições entrarão em vigência assim que veiculadas no App, sendo possível ao Usuário manifestar oposição a quaisquer dos termos modificados, desde que o faça por escrito, através do e-mail XXX, o que gerará o cancelamento de seu Cadastro.
        
            •	ACEITAÇÃO DO TERMO DE USO
        
        8.1 	O Usuário declara ter lido, entendido e aceitado todas as regras, condições e obrigações estabelecidas no presente Termos e Condições de Uso.
        
        9.	DISPOSIÇÕES GERAIS
        
        9.1 	A tolerância do eventual descumprimento de quaisquer das cláusulas e condições do presente instrumento não constituirá novação das obrigações aqui estipuladas e tampouco impedirá ou inibirá a exigibilidade das mesmas a qualquer tempo.
        
        9.2 	O Usuário autoriza expressamente a PETIN a comunicar-se com este através de todos os canais disponíveis, incluindo correio eletrônico (e-mail), celular, SMS, entre outros, ficando ressaltado que a principal via de comunicação para com o Usuário é o App.
        
        9.3	Este Termos e Condições de Uso e quaisquer outras políticas divulgadas pela PETIN no App, estabelecem o pleno e completo acordo e entendimento com o Usuário, superando e revogando todos e quaisquer entendimentos, propostas, acordos, negociações e discussões havidos anteriormente entre as Partes.
        
        9.4	Na hipótese de qualquer item, termo ou disposição deste Termos e Condições de Uso vir a ser declarado nulo ou não aplicável, tal nulidade ou inexequibilidade não afetará quaisquer outros itens, termos ou disposições aqui contidos, quais permanecerão em pleno vigor e efeito.
        
        10. 	DA LEGISLAÇÃO APLICÁVEL E FORO DE ELEIÇÃO
        
        10.1 	As partes elegem o Foro da Comarca da Capital do Estado São Paulo como sendo o único competente para dirimir eventuais controvérsias que venham a surgir da interpretação e do cumprimento do presente Termos e Condições de Uso, renunciando a qualquer outro, por mais privilegiado que seja, sendo aplicáveis as Leis da República Federativa do Brasil.
        `,
      },
    ],
  });
};

export default Privacidade;
