import React, { useEffect, useState } from "react";
import theme from "../theme";
import api from "../service";
import { useAuth } from "../context/authContext";
import { useNavigate } from "react-router-dom";
import AlertPopUp from "./AlertPopUp";
import { useAlert } from "../context/alertContext";
import { autocompleteClasses } from "@mui/material";

let Page = ({ show }) => {
  let search = "";
  const navigate = useNavigate();

  let bkpInfo = {};
  const [info, setInfo] = useState({
    fee: 0,
    count: 0,
    order: 0,
    discount: 0,
    investment: 0,
    people: [],
  });

  const [peopleList, setPeopleList] = useState({
    name: [],
    code: [],
    phone: [],
    credit: [],
    action: [],
  });

  const [tableName, setTableName] = useState();

  const loadTable = (data) => {
    const component = "container";
    const peopleStyle = {
      color: "rgba(0,0,0,1)",
      fontSize: 20,
      fontFamily: "Poppins",
      padding: 5,
      marginBottom: 10,
      marginTop: "0",
      minHeight: 30,
      maxHeight: 30,

      borderRadius: 5,
      borderWidth: 20,
      borderBottom: "1px solid",
      borderColor: "rgba(100,100,100,0.2)",
      backgroundColor: "rgba(255,255,255,1)",
    };

    const i = {
      name: [
        {
          component,
          text: "Nome",
          style: peopleStyle,
        },
      ],
      code: [
        {
          component,
          text: "CPF",
          style: peopleStyle,
        },
      ],
      phone: [
        {
          component,
          text: "Telefone",
          style: peopleStyle,
        },
      ],
      credit: [
        {
          component,
          text: "Créditos",
          style: peopleStyle,
        },
      ],
      action: [
        {
          component,
          text: "",
          style: peopleStyle,
        },
      ],
    };

    data.people.map((p, idx) => {
      i.name.push({
        component,
        text: idx + " " + p.name,
        style: peopleStyle,
      });

      i.code.push({
        component,
        text: p.code,
        style: peopleStyle,
      });

      i.phone.push({
        component,
        text: p.contacts.map((c) => {
          return c.phone.area_code + " " + c.phone.number + p.contacts.length >
            1
            ? ", "
            : c.phone.area_code + " " + c.phone.number;
        }),
        style: peopleStyle,
      });

      i.credit.push({
        component,
        text: p.credit,
        style: peopleStyle,
        name: "asasass",
      });

      i.action.push({
        component,
        text: "x",
        style: { ...peopleStyle, fontSize: 20 },
      });
    });

    setPeopleList(i);
  };

  useEffect(() => {
    (async () => {
      const { data } = await api.business.home();
      await setInfo(data.data);
      bkpInfo = data.data;
      await loadTable(bkpInfo);
    })();
  }, []);

  return {
    component: "container",
    style: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      backgroundColor: "rgba(100,100,100,0.01)",
      marginTop: "0%",
      overflowX: "hidden",
      overflowY: "auto",
    },
    children: [
      // Indicatores
      {
        component: "container",
        style: {
          display: "flex",
          width: "60%",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          padding: "1%",
          marginBottom: "2%",
          marginTop: "2%",
          borderRadius: 5,
          borderWidth: 20,
          border: "1px solid",
          borderColor: "rgba(100,100,100,0.2)",
          backgroundColor: "rgba(255,255,255,1)",
        },
        children: [
          //Colaboradores
          {
            component: "container",
            style: {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              borderRight: "1px solid ",
              borderColor: "rgba(100,100,100,0.6)",
              padding: 5,
              height: "100%",
              width: "10vw",
            },
            children: [
              //Text
              {
                component: "text",
                text: "Colaboradores Ativos",
                style: {
                  color: "rgba(0,0,0,1)",
                  fontSize: "1vw",
                  fontWeight: 500,
                  fontFamily: "Poppins",
                  marginBottom: "5%",
                },
              },
              //Number
              {
                component: "text",
                text: info.people.length,
                style: {
                  color: "rgba(100,100,100,1)",
                  fontSize: 25,
                  fontFamily: "Poppins",
                },
              },
            ],
          },
          //Colaboradores
          {
            component: "container",
            style: {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              borderRight: "1px solid ",
              borderColor: "rgba(100,100,100,0.6)",
              padding: 5,
              height: "100%",
              width: "10vw",
            },
            children: [
              //Text
              {
                component: "text",
                text: "Colaboradores Ativos",
                style: {
                  color: "rgba(0,0,0,1)",
                  fontSize: "1vw",
                  fontWeight: 500,
                  fontFamily: "Poppins",
                  marginBottom: "5%",
                },
              },
              //Number
              {
                component: "text",
                text: info.people.length,
                style: {
                  color: "rgba(100,100,100,1)",
                  fontSize: 25,
                  fontFamily: "Poppins",
                },
              },
            ],
          },
          //Fee
          {
            component: "container",
            style: {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              borderRight: "1px solid ",
              borderColor: "rgba(100,100,100,0.6)",
              padding: 5,
              height: "100%",
              width: "11vw",
              marginLeft: "1vw",
            },
            children: [
              //Text
              {
                component: "text",
                text: "Fee por colaborador/mês",
                style: {
                  color: "rgba(0,0,0,1)",
                  fontSize: "1vw",
                  fontWeight: 500,
                  fontFamily: "Poppins",
                  marginBottom: "5%",
                },
              },
              //Value
              {
                component: "container",
                style: {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  padding: 5,
                },
                children: [
                  //Number
                  {
                    component: "text",
                    text: "R$ " + info.fee,
                    style: {
                      color: "rgba(100,100,100,1)",
                      fontSize: 25,
                      fontFamily: "Poppins",
                    },
                  },
                  //Number
                  {
                    component: "text",
                    text: " 00",
                    style: {
                      color: "rgba(100,100,100,1)",
                      fontSize: 10,
                      fontFamily: "Poppins",
                    },
                  },
                ],
              },
              //Text
              {
                component: "text",
                text: "Investimento R$ " + info.investment.toFixed(2),
                style: {
                  color: "rgba(100,100,100,1)",
                  fontSize: "0.7vw",
                  fontFamily: "Poppins",
                  marginBottom: "5%",
                },
              },
            ],
          },
          //Economize
          {
            component: "container",
            style: {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              borderRight: "1px solid ",
              borderColor: "rgba(100,100,100,0.6)",
              padding: 5,
              marginLeft: "1vw",
              height: "100%",
              width: "13vw",
              marginLeft: "1vw",
            },
            children: [
              //Text
              {
                component: "text",
                text: "Seus Colaboradores já economizaram",
                style: {
                  color: "rgba(0,0,0,1)",
                  fontSize: "1vw",
                  fontWeight: 500,
                  fontFamily: "Poppins",
                  marginBottom: "5%",
                },
              },
              //Value
              {
                component: "container",
                style: {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  padding: 5,
                },
                children: [
                  //Number
                  {
                    component: "text",
                    text: "R$ " + parseInt(info.saveMoney),
                    style: {
                      color: "rgba(0,0,255,0.5)",
                      fontSize: 25,
                      fontFamily: "Poppins",
                    },
                  },
                  //Number
                  {
                    component: "text",
                    text: (info.saveMoney % 1).toFixed(2).split(".")[1],
                    style: {
                      color: "rgba(0,0,255,0.5)",
                      fontSize: 10,
                      fontFamily: "Poppins",
                    },
                  },
                ],
              },
            ],
          },
          //Agend
          {
            component: "container",
            style: {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: 5,
              height: "100%",
              width: "10vw",
              marginLeft: "1vw",
            },
            children: [
              //Text
              {
                component: "text",
                text: "Número de Agendamentos",
                style: {
                  color: "rgba(0,0,0,1)",
                  fontSize: "1vw",
                  fontWeight: 500,
                  fontFamily: "Poppins",
                  marginBottom: "5%",
                },
              },
              //Number
              {
                component: "text",
                text: info.orderCount,
                style: {
                  color: "rgba(0,0,255,0.5)",
                  fontSize: 25,
                  fontFamily: "Poppins",
                },
              },
            ],
          },
        ],
      },
      // Table
      {
        component: "container",
        style: {
          width: "60%",
          height: "60%",
          maxheight: "60%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "1%",

          borderRadius: 5,
          borderWidth: 20,
          border: "1px solid",
          borderColor: "rgba(100,100,100,0.2)",
          backgroundColor: "rgba(255,255,255,1)",

          overflowX: "hidden",
          overflowY: "auto",
        },
        children: [
          //Top Bar
          {
            component: "container",
            style: {
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              padding: 5,
              margin: 5,
            },
            children: [
              {
                component: "text",
                text: "Lista de Colaboradoress",
                style: {
                  color: "rgba(0,0,0,1)",
                  fontSize: 20,
                  fontFamily: "Poppins",
                  padding: 5,
                },
              },
              //pesquisa
              {
                component: "input",
                placeholder: "Digite para pesquisar",
                onChange: async (e) => {
                  if (e.target.value === "" || !e.target.value)
                    loadTable(bkpInfo);
                  else
                    loadTable({
                      people: info.people.filter((item) => {
                        let word =
                          item.name.toLowerCase() +
                          item.nickname.toLowerCase().toLowerCase() +
                          item.code.toLowerCase();
                        return word.includes(e.target.value.toLowerCase());
                      }),
                    });
                },
                onBlur: async (e) => {},
                style: {
                  height: "4vh",
                  width: "20vw",
                  borderRadius: 5,
                  color: "rgba(150,150,150,1)",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  borderWidth: 0,
                  backgroundColor: "rgba(150,150,150,0.2)",
                },
              },
              {
                component: "button",
                onClick: async (e) => {
                  navigate("/dashboard-business-people");
                },
                text: "Adicionar Colaborador",
                style: {
                  fontSize: 14,
                  fontFamily: "Poppins",
                  marginLeft: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                  width: "auto",
                  height: "auto",
                  backgroundColor: theme.colors.pink3,
                  color: "rgba(255,255,255,1)",
                  borderRadius: 5,
                  borderWidth: 0,
                  textAlign: "center",
                  alignItems: "center",
                  cursor: "pointer",
                },
              },
            ],
          },
          //Rows
          {
            component: "container",
            style: {
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "flex-start",
              justifyContent: "space-between",
              padding: 5,
              margin: 5,
              marginTop: "1%",
              width: "100%",
            },
            children: [
              //Nome
              {
                component: "container",
                style: {
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  padding: 5,
                  margin: 5,
                },
                children: peopleList.name,
              },
              //CPF
              {
                component: "container",
                style: {
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  padding: 5,
                  margin: 5,
                  marginLeft: "5vw",
                },
                children: peopleList.code,
              },
              //Telefone
              {
                component: "container",
                style: {
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  padding: 5,
                  margin: 5,
                  marginLeft: "5vw",
                },
                children: peopleList.phone,
              },
              //Credito
              {
                component: "container",
                style: {
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  padding: 5,
                  margin: 5,
                  marginLeft: "5vw",
                },
                children: peopleList.credit,
              },
              // X
              {
                component: "container",
                style: {
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  padding: 5,
                  margin: 5,
                  marginLeft: "5vw",
                },
                children: peopleList.action,
              },
            ],
          },
          //Counter Pages
        ],
      },
    ],
  };
};

export default Page;
