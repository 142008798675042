import React, { useState, useEffect } from "react";
import theme from "../theme";
import { useActive } from "../context/activeContext";
import { useAlert } from "../context/alertContext";
import api from "../service";
import ApiCep from "../service/cep";
import InputMask from "react-input-mask";
import ClearIcon from "@mui/icons-material/Clear";
import FolderImage from "../assets/images/folder.png";
import DownloadIcon from "@mui/icons-material/Download";
import { IMaskInput } from "react-imask";
import AlertPopUp from "./AlertPopUp";
import { useDropzone } from "react-dropzone";
import { set } from "date-fns";
import Switch from "@mui/material/Switch";

// function tu validate CPF math
const validateCPF = (cpf) => {
  let sum;
  let rest;
  sum = 0;
  if (cpf == "00000000000") return false;

  for (let i = 1; i <= 9; i++)
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  rest = (sum * 10) % 11;

  if (rest == 10 || rest == 11) rest = 0;
  if (rest != parseInt(cpf.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  rest = (sum * 10) % 11;

  if (rest == 10 || rest == 11) rest = 0;
  if (rest != parseInt(cpf.substring(10, 11))) return false;
  return true;
};

let FormRegisterAdmin = ({ show }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (e) => {
      for (let file of e) {
        // encode the file using the FileReader API
        const reader = new FileReader();
        reader.onloadend = () => {
          setNewCad({
            ...newCad,
            company_icon: reader.result,
          });
        };
        reader.readAsDataURL(file);
      }
    },
  });
  const [inputError, setInputError] = useState(0);
  const { active, updateActive } = useActive();
  const { errorPopUp, setErrorPopUp, alertMsg, setAlertMsg, setAlertTitle } =
    useAlert();
  const [popup, setPopup] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [replacedAdressCode, setReplacedAdressCode] = useState("");
  const [replacedCode, setReplacedCode] = useState("");
  const [providerType, setProviderType] = useState(1);
  const [newCad, setNewCad] = useState({
    nickname: "",
    name: "",
    code: "",
    email: "",
    database: "",
    password: "",
    address: {
      code: "",
      street: "",
      number: "",
      district: "",
      city: "",
      state: "",
      country: "",
      complement: "",
    },
    bank_account: {
      bank: "",
      agency: "",
      agency_code: "",
      account: "",
      account_code: "",
    },
    company_icon: "",
  });

  const validateEmail = (email) => {
    let validation = /[a-zA-Z0-9]+@[a-zA-Z0-9.-]+\.[a-z]{2,8}(\.[a-z]{2,8})?/;
    if (validation.test(email)) {
      return true;
    } else {
      return false;
    }
  };

  const checkChar = (e) => {
    const char = String.fromCharCode(e.keyCode);
  };

  const accountMask = /^[a-zA-Z0-9\s]*$/;

  useEffect(() => {
    (async () => {
      let resBank = await api.manager.getBank();
      setBankList(resBank.data.data);
    })();
  }, []);

  if (show) {
    return {
      component: "container",
      style: {
        width: "100%",
        padding: "2% 10%",
      },
      children: [
        //Dados Pessoais
        {
          component: "text",
          text: "Informações básicas",
          style: {
            color: theme.colors.darkGreen,
            fontSize: 28,
            fontFamily: "Poppins",
          },
        },
        //Row
        {
          component: "container",
          style: {
            width: "100%",
            display: "flex",
            marginTop: "50px",
          },
          children: [
            //input
            {
              component: "container",
              style: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
              },
              children: [
                {
                  component: "text",
                  text: "Nome fantasia",
                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },
                {
                  component: "input",
                  placeholder: "Digite aqui",
                  value: newCad.nickname,
                  onChange: (e) => {
                    setNewCad({ ...newCad, nickname: e.target.value });
                  },
                  onBlur: (e) => {
                    if (e.target.value == "") {
                      setInputError(1);
                    } else {
                      setInputError(0);
                    }
                  },
                  style: {
                    height: 50,
                    width: "70%",
                    backgroundColor: "#EFF0F7",
                    border:
                      inputError == 1
                        ? `1px solid ${theme.colors.pink2}`
                        : "none",
                    borderRadius: 16,
                    padding: "0 20px",
                    fontFamily: "Poppins",
                    fontSize: 16,
                    marginTop: 8,
                  },
                },
                {
                  component: "text",
                  text: inputError == 1 ? "Preencha o campo corretamente" : "",
                  style: {
                    color: theme.colors.pink2,
                    fontSize: 12,
                    fontFamily: "Poppins",
                    marginLeft: 5,
                  },
                },
              ],
            },
            {
              component: "container",
              style: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
              },
              children: [
                {
                  component: "container",
                  style: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "1%",
                  },
                  children: [
                    {
                      component: "text",
                      text: providerType === 1 ? "CNPJ" : "CPF",
                      style: {
                        fontSize: providerType === 1 ? 24 : 24,
                        fontFamily: "Poppins",
                        marginRight: "3%",
                        minWidth: 100,
                      },
                    },

                    {
                      component: "text",
                      text: "Pessoa Física",
                      style: {
                        fontSize: 16,
                        fontFamily: "Poppins",
                      },
                    },
                    {
                      render: (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Switch
                            checked={providerType === 1 ? false : true}
                            // value={true}
                            onChange={async () => {
                              setProviderType(providerType === 1 ? 2 : 1);
                            }}
                            size="medium"
                            // mudar cor se providetype 2
                            sx={{
                              "& .MuiSwitch-switchBase.Mui-checked": {
                                color: theme.colors.pink1,
                              },
                              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track.MuiSwitch-track.MuiSwitch-track":
                                {
                                  backgroundColor: theme.colors.pink1,
                                },
                            }}
                          />
                        </div>
                      ),
                    },
                  ],
                },
                {
                  render: (
                    <InputMask
                      mask={
                        providerType === 1
                          ? "99.999.999/9999-99"
                          : "999.999.999-99"
                      }
                      value={newCad.code}
                      onChange={(e) =>
                        setNewCad({
                          ...newCad,
                          code: e.target.value,
                          database: e.target.value,
                        })
                      }
                      onBlur={(e) => {
                        const fVal = newCad.code
                          .replaceAll(".", "")
                          .replaceAll("/", "")
                          .replaceAll("-", "")
                          .replaceAll("_", "");
                        if (
                          (fVal.length > 13 && providerType === 1) ||
                          (fVal.length > 10 &&
                            providerType === 2 &&
                            validateCPF(fVal))
                        ) {
                          setInputError(0);
                          setReplacedCode(fVal);
                        } else {
                          setInputError(2);
                        }
                      }}
                      //Mascara do input
                    >
                      {() => (
                        <input
                          style={{
                            height: 50,
                            width: "70%",
                            backgroundColor: "#EFF0F7",
                            border:
                              inputError == 2
                                ? `1px solid ${theme.colors.pink2}`
                                : "none",
                            borderRadius: 16,
                            padding: "0 20px",
                            fontFamily: "Poppins",
                            fontSize: 16,
                            marginTop: 8,
                          }}
                          placeholder="Digite aqui"
                        />
                      )}
                    </InputMask>
                  ),
                },
                //dados
                {
                  component: "text",
                  text: inputError == 2 ? "Dado inválido !" : "",
                  style: {
                    color: theme.colors.pink2,
                    fontSize: 12,
                    fontFamily: "Poppins",
                    marginTop: 4,
                    marginLeft: 4,
                  },
                },
              ],
            },
          ],
        },
        //Row
        {
          component: "container",
          style: {
            width: "100%",
            display: "flex",
            marginTop: "50px",
          },
          children: [
            //input
            {
              component: "container",
              style: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
              },
              children: [
                {
                  component: "text",
                  text: "Email",
                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },
                {
                  component: "input",
                  value: newCad.email,
                  onBlur: () => {
                    if (!validateEmail(newCad.email)) {
                      setInputError(3);
                    } else {
                      setInputError(0);
                    }
                  },
                  onChange: (e) =>
                    setNewCad({ ...newCad, email: e.target.value }),
                  placeholder: "Digite aqui",
                  style: {
                    height: 50,
                    width: "70%",
                    backgroundColor: "#EFF0F7",
                    border:
                      inputError == 3
                        ? `1px solid ${theme.colors.pink2}`
                        : "none",
                    borderRadius: 16,
                    padding: "0 20px",
                    fontFamily: "Poppins",
                    fontSize: 16,
                    marginTop: 8,
                  },
                },
                {
                  component: "text",
                  text: inputError == 3 ? "Insira um email válido" : "",
                  style: {
                    color: theme.colors.pink2,
                    fontSize: 12,
                    fontFamily: "Poppins",
                    marginLeft: 5,
                  },
                },
              ],
            },
            {
              component: "container",
              style: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
              },
              children: [
                {
                  component: "text",
                  text: "Senha",
                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },
                {
                  component: "input",
                  placeholder: "Digite aqui",
                  value: newCad.password,
                  onBlur: () => {
                    if (newCad.password.length < 8) {
                      setInputError(4);
                    } else {
                      setInputError(0);
                    }
                  },
                  onChange: (e) =>
                    setNewCad({ ...newCad, password: e.target.value }),
                  type: "password",
                  style: {
                    height: 50,
                    width: "70%",
                    backgroundColor: "#EFF0F7",
                    border:
                      inputError == 4
                        ? `1px solid ${theme.colors.pink2}`
                        : "none",
                    borderRadius: 16,
                    padding: "0 20px",
                    fontFamily: "Poppins",
                    fontSize: 16,
                    marginTop: 8,
                  },
                },
                {
                  component: "text",
                  text: "A senha deve ter pelo menos 8 caracteres",
                  style: {
                    color:
                      inputError == 4
                        ? theme.colors.pink2
                        : theme.colors.darkBlue,
                    fontSize: 12,
                    fontFamily: "Poppins",
                    marginLeft: 5,
                  },
                },
              ],
            },
          ],
        },
        {
          component: "container",
          style: {
            margin: "40px 0",
            height: 1,
            width: "100%",
            backgroundColor: "#E0E0E0",
          },
        },
        //Dados bancários
        {
          component: "text",
          text: "Dados Bancários",
          style: {
            color: theme.colors.darkGreen,
            fontSize: 28,
            fontFamily: "Poppins",
          },
        },
        //input Bacno
        {
          component: "container",
          style: {
            width: "100%",
            display: "flex",
            marginTop: "50px",
          },
          children: [
            //input
            {
              component: "container",
              style: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
              },
              children: [
                {
                  component: "text",
                  text: "Banco",
                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },
                {
                  render: (
                    <select
                      style={{
                        height: 50,
                        width: "38%",
                        backgroundColor: "#EFF0F7",
                        border:
                          inputError == 5
                            ? `1px solid ${theme.colors.pink2}`
                            : "none",
                        borderRadius: 16,
                        padding: "0 20px",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginTop: 8,
                      }}
                      value={newCad.bank_account.bank}
                      onBlur={() => {
                        if (newCad.bank_account.bank == "") {
                          setInputError(5);
                        } else {
                          setInputError(0);
                        }
                      }}
                      onChange={(e) => {
                        if (e.target.value != "default") {
                          setNewCad({
                            ...newCad,
                            bank_account: {
                              ...newCad.bank_account,
                              bank: e.target.value,
                            },
                          });
                        }
                      }}
                    >
                      <option value="default">Selecione um banco</option>
                      {bankList.map((bank) => (
                        <option value={bank._id}>{bank.name}</option>
                      ))}
                    </select>
                  ),
                },
                {
                  component: "text",
                  text: inputError == 5 ? "Um banco deve ser selecionado" : "",
                  style: {
                    color: theme.colors.pink2,
                    fontSize: 12,
                    fontFamily: "Poppins",
                    marginLeft: 5,
                  },
                },
              ],
            },
          ],
        },
        {
          component: "container",
          style: {
            width: "100%",
            display: "flex",
            marginTop: "50px",
          },
          children: [
            //input
            {
              component: "container",
              style: {
                flex: 0.9,
                display: "flex",
                flexDirection: "column",
              },
              children: [
                {
                  component: "text",
                  text: "Agência",
                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },
                {
                  render: (
                    <InputMask
                      mask="9999"
                      value={newCad.bank_account.agency}
                      style={{
                        height: 50,
                        width: "70%",
                        backgroundColor: "#EFF0F7",
                        border: "none",
                        borderRadius: 16,
                        padding: "0 20px",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginTop: 8,
                      }}
                      onChange={(e) => {
                        setNewCad({
                          ...newCad,
                          bank_account: {
                            ...newCad.bank_account,
                            agency: e.target.value,
                          },
                        });
                      }}
                      onBlur={() => {
                        if (
                          newCad.bank_account.agency.replace("_", "").length < 4
                        ) {
                          setInputError(6);
                        } else {
                          setInputError(0);
                        }
                      }}
                    >
                      {() => (
                        <input
                          style={{
                            height: 50,
                            width: "70%",
                            backgroundColor: "#EFF0F7",
                            border:
                              inputError == 6
                                ? `1px solid ${theme.colors.pink2}`
                                : "none",
                            borderRadius: 16,
                            padding: "0 20px",
                            fontFamily: "Poppins",
                            fontSize: 16,
                            marginTop: 8,
                          }}
                          placeholder="Digite aqui"
                        />
                      )}
                    </InputMask>
                  ),
                },
                {
                  component: "text",
                  text:
                    inputError == 6 ? "Insira um codigo da agência válido" : "",
                  style: {
                    color: theme.colors.pink2,
                    fontSize: 12,
                    fontFamily: "Poppins",
                    marginLeft: 5,
                  },
                },
              ],
            },

            {
              component: "container",
              style: {
                flex: 0.9,
                display: "flex",
                flexDirection: "column",
              },
              children: [
                {
                  component: "text",
                  text: "Dígito(opcional)",
                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },

                {
                  render: (
                    <InputMask
                      mask="9"
                      value={newCad.bank_account.agency_code}
                      style={{
                        height: 50,
                        width: "70%",
                        backgroundColor: "#EFF0F7",
                        border: "none",
                        borderRadius: 16,
                        padding: "0 20px",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginTop: 8,
                      }}
                      onChange={(e) => {
                        setNewCad({
                          ...newCad,
                          bank_account: {
                            ...newCad.bank_account,
                            agency_code: e.target.value.replaceAll(".", ""),
                          },
                        });
                      }}
                    >
                      {() => (
                        <input
                          style={{
                            height: 50,
                            width: "70%",
                            backgroundColor: "#EFF0F7",
                            border: "none",
                            borderRadius: 16,
                            padding: "0 20px",
                            fontFamily: "Poppins",
                            fontSize: 16,
                            marginTop: 8,
                          }}
                          placeholder="Digite aqui"
                        />
                      )}
                    </InputMask>
                  ),
                },
              ],
            },

            {
              component: "container",
              style: {
                flex: 0.4,
                display: "flex",
                flexDirection: "column",
              },
            },

            {
              component: "container",
              style: {
                flex: 0.9,
                display: "flex",
                flexDirection: "column",
              },
              children: [
                {
                  component: "text",
                  text: "Conta",
                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },
                {
                  render: (
                    <IMaskInput
                      value={newCad.bank_account.account}
                      placeholder="Digite aqui"
                      mask={accountMask}
                      style={{
                        height: 50,
                        width: "70%",
                        backgroundColor: "#EFF0F7",
                        border:
                          inputError == 7
                            ? `1px solid ${theme.colors.pink2}`
                            : "none",
                        borderRadius: 16,
                        padding: "0 20px",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginTop: 8,
                      }}
                      onBlur={() => {
                        if (newCad.bank_account.account == "") {
                          setInputError(7);
                        } else {
                          setInputError(0);
                        }
                      }}
                      onAccept={(value) => {
                        setNewCad({
                          ...newCad,
                          bank_account: {
                            ...newCad.bank_account,
                            account: value,
                          },
                        });
                      }}
                    />
                  ),
                },
                {
                  component: "text",
                  text: inputError == 7 ? "A conta não pode ser vazia" : "",
                  style: {
                    color: theme.colors.pink2,
                    fontSize: 12,
                    fontFamily: "Poppins",
                    marginLeft: 5,
                  },
                },
              ],
            },

            {
              component: "container",
              style: {
                flex: 0.9,
                display: "flex",
                flexDirection: "column",
              },
              children: [
                {
                  component: "text",
                  text: "Dígito",
                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },
                {
                  render: (
                    <InputMask
                      mask="9"
                      value={newCad.bank_account.account_code}
                      style={{
                        height: 50,
                        width: "70%",
                        backgroundColor: "#EFF0F7",
                        border: "none",
                        borderRadius: 16,
                        padding: "0 20px",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginTop: 8,
                      }}
                      onChange={(e) => {
                        setNewCad({
                          ...newCad,
                          bank_account: {
                            ...newCad.bank_account,
                            account_code: e.target.value.replaceAll(".", ""),
                          },
                        });
                      }}
                      onBlur={() => {
                        if (
                          newCad.bank_account.account_code.replace("_", "")
                            .length == 0
                        ) {
                          setInputError(8);
                        } else {
                          setInputError(0);
                        }
                      }}
                    >
                      {() => (
                        <input
                          style={{
                            height: 50,
                            width: "70%",
                            backgroundColor: "#EFF0F7",
                            border:
                              inputError == 8
                                ? `1px solid ${theme.colors.pink2}`
                                : "none",
                            borderRadius: 16,
                            padding: "0 20px",
                            fontFamily: "Poppins",
                            fontSize: 16,
                            marginTop: 8,
                          }}
                          placeholder="Digite aqui"
                        />
                      )}
                    </InputMask>
                  ),
                },
                {
                  component: "text",
                  text: inputError == 8 ? "O dígito não pode ser vazio" : "",
                  style: {
                    color: theme.colors.pink2,
                    fontSize: 12,
                    fontFamily: "Poppins",
                    marginLeft: 5,
                  },
                },
              ],
            },
          ],
        },
        {
          component: "container",
          style: {
            margin: "40px 0",
            height: 1,
            width: "100%",
            backgroundColor: "#E0E0E0",
          },
        },
        //Endereço
        {
          component: "text",
          text: "Endereço",
          style: {
            color: theme.colors.darkGreen,
            fontSize: 28,
            fontFamily: "Poppins",
          },
        },
        //Row
        {
          component: "container",
          style: {
            width: "100%",
            display: "flex",
            marginTop: "50px",
          },
          children: [
            //input
            {
              component: "container",
              style: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
              },
              children: [
                {
                  component: "text",
                  text: "CEP",

                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },
                {
                  render: (
                    <InputMask
                      mask="99999-999"
                      onChange={(e) =>
                        setNewCad({
                          ...newCad,
                          address: {
                            ...newCad.address,
                            code: e.target.value,
                          },
                        })
                      }
                      onBlur={async (e) => {
                        try {
                          let res = await ApiCep.getByCep(e.target.value);

                          setNewCad({
                            ...newCad,
                            address: {
                              ...newCad.address,
                              city: res.data.data.city,
                              street: res.data.data.street,
                              state: res.data.data.state,
                              district: res.data.data.neighborhood,
                            },
                          });
                          setReplacedAdressCode(
                            newCad.address.code.replace("-", "")
                          );
                        } catch {
                          setAlertTitle("Error");
                          setAlertMsg("CEP inválido");
                          setErrorPopUp(true);
                        }
                      }}
                      value={newCad.address.code}
                    >
                      {() => (
                        <input
                          style={{
                            height: 50,
                            width: "70%",
                            backgroundColor: "#EFF0F7",
                            border: "none",
                            borderRadius: 16,
                            padding: "0 20px",
                            fontFamily: "Poppins",
                            fontSize: 16,
                            marginTop: 8,
                          }}
                          placeholder="Digite aqui"
                        />
                      )}
                    </InputMask>
                  ),
                },
              ],
            },
            //input
            {
              component: "container",
              style: {
                flex: 2,
                display: "flex",
                flexDirection: "column",
              },
              children: [
                {
                  component: "text",
                  text: "Rua",
                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },
                {
                  render: (
                    <input
                      value={newCad.address.street}
                      onChange={(e) =>
                        setNewCad({
                          ...newCad,
                          address: {
                            ...newCad.address,
                            street: e.target.value,
                          },
                        })
                      }
                      style={{
                        height: 50,
                        width: "70%",
                        backgroundColor: "#EFF0F7",
                        border: "none",
                        borderRadius: 16,
                        padding: "0 20px",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginTop: 8,
                      }}
                      placeholder="Digite aqui"
                    />
                  ),
                },
              ],
            },
            //input
            {
              component: "container",
              style: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
              },
              children: [
                {
                  component: "text",
                  text: "Estado",
                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },
                {
                  render: (
                    <input
                      value={newCad.address.state}
                      onChange={(e) =>
                        setNewCad({
                          ...newCad,
                          address: {
                            ...newCad.address,
                            state: e.target.value,
                          },
                        })
                      }
                      style={{
                        height: 50,
                        width: "70%",
                        backgroundColor: "#EFF0F7",
                        border: "none",
                        borderRadius: 16,
                        padding: "0 20px",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginTop: 8,
                      }}
                      placeholder="Digite aqui"
                    />
                  ),
                },
              ],
            },
          ],
        },
        //Row
        {
          component: "container",
          style: {
            width: "100%",
            display: "flex",
            marginTop: "50px",
          },
          children: [
            //input
            {
              component: "container",
              style: {
                display: "flex",
                flexDirection: "column",
                width: "25%",
                margin: "0 0.5% 0 0",
              },
              children: [
                {
                  component: "text",
                  text: "Número",
                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },
                {
                  render: (
                    <input
                      value={newCad.address.number}
                      onChange={(e) => {
                        if (isNaN(e.target.value)) {
                          return;
                        }
                        setNewCad({
                          ...newCad,
                          address: {
                            ...newCad.address,
                            number: Number(e.target.value.replaceAll(".", "")),
                          },
                        });
                      }}
                      onBlur={() => {
                        if (newCad.address.number.length == 0) {
                          setInputError(10);
                        } else {
                          setInputError(0);
                        }
                      }}
                      style={{
                        height: 50,
                        width: "70%",
                        backgroundColor: "#EFF0F7",
                        border:
                          inputError == 10
                            ? `1px solid ${theme.colors.pink2}`
                            : "none",
                        borderRadius: 16,
                        padding: "0 20px",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginTop: 8,
                      }}
                      placeholder="Digite aqui"
                    />
                  ),
                },
                {
                  component: "text",
                  text: inputError == 10 ? "O número não pode ser vazio" : "",
                  style: {
                    color: theme.colors.pink2,
                    fontSize: 12,
                    fontFamily: "Poppins",
                    marginLeft: 5,
                  },
                },
              ],
            },
            //input
            {
              component: "container",
              style: {
                display: "flex",
                flexDirection: "column",
                width: "25%",
              },
              children: [
                {
                  component: "text",
                  text: "Complemento",
                  style: {
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                  },
                },
                {
                  render: (
                    <input
                      value={newCad.address.complement}
                      onChange={(e) =>
                        setNewCad({
                          ...newCad,
                          address: {
                            ...newCad.address,
                            complement: e.target.value,
                          },
                        })
                      }
                      style={{
                        height: 50,
                        width: "100%",
                        backgroundColor: "#EFF0F7",
                        border: "none",
                        borderRadius: 16,
                        padding: "0 20px",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginTop: 8,
                      }}
                      placeholder="Digite aqui"
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          component: "container",
          style: {
            margin: "40px 0",
            height: 1,
            width: "100%",
            backgroundColor: "#E0E0E0",
          },
        },
        {
          component: "container",
          style: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          },
          children: [
            {
              component: "text",
              text: "Logo do parceiro",
              style: {
                marginBottom: 10,
                color: theme.colors.darkGreen,
                fontSize: 28,
                fontFamily: "Poppins",
              },
            },
          ],
        },
        {
          component: "container",
          style: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          },
          children: [
            {
              component: "text",
              text: "Lembre-se, essa imagem será mostrada no aplicativo",
              style: {
                color: theme.colors.darkBlue,
                fontSize: 16,
                fontFamily: "Poppins",
              },
            },
          ],
        },

        //Row
        {
          component: "container",
          style: {
            width: "100%",
            display: "flex",
            marginTop: "20px",
          },
          children: [
            {
              render: (
                <div
                  {...getRootProps()}
                  style={{
                    width: "100%",
                    height: 200,
                    background: "#EFF0F7",
                    borderRadius: 10,
                    borderWidth: 2,
                    borderStyle: "dashed",
                    borderColor: theme.colors.lightGreen,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    style={{
                      display: newCad.company_icon == "" ? "flex" : "none",
                    }}
                    src={FolderImage}
                  />
                  <img
                    src={newCad.company_icon}
                    style={{
                      display: newCad.company_icon !== "" ? "flex" : "none",
                      width: 90,
                      height: 90,
                    }}
                  />
                  <input
                    {...getInputProps()}
                    id="upload-file"
                    type="file"
                    multiple={false}
                    style={{ display: "none" }}
                    onChange={async (e) => {
                      e.preventDefault();
                      for (let file of e.target.files) {
                        // encode the file using the FileReader API
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          setNewCad({
                            ...newCad,
                            company_icon: reader.result,
                          });
                        };
                        reader.readAsDataURL(file);
                      }
                    }}
                  />
                  <p
                    style={{
                      color: theme.colors.darkBlue,
                      fontSize: 20,
                      fontFamily: "Poppins",
                      marginBottom: 5,
                      marginTop: 20,
                    }}
                  >
                    Arraste ou clique para fazer o upload
                  </p>
                </div>
              ),
            },
          ],
        },
        {
          component: "container",
          style: {
            margin: "40px 0",
            height: 1,
            width: "100%",
            backgroundColor: "#E0E0E0",
          },
        },
        {
          component: "container",
          style: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
          },
          children: [
            {
              render: (
                <AlertPopUp
                  title="Sucesso"
                  background="#fff"
                  color="#0f0f0f"
                  show={popup}
                  setTrigger={setPopup}
                  callback={() => window.location.reload()}
                  message={alertMsg}
                />
              ),
            },
            // {
            //   render: (
            //     <AlertPopUp
            //       title="Error"
            //       background="#F68989"
            //       color="#874356"
            //       show={errorPopUp}
            //       setTrigger={setErrorPopUp}
            //       message={alertMessage}
            //     />
            //   ),
            // },
            {
              component: "button",
              text: "Salvar",
              disabled:
                newCad.nickname == "" ||
                newCad.code.replace(/[^0-9 ]/g, "") == "" ||
                newCad.email == "" ||
                newCad.password.length < 8 ||
                newCad.address.code.replace(/[^0-9 ]/g, "") == "" ||
                newCad.address.street == "" ||
                newCad.address.state == "" ||
                newCad.address.number == 0 ||
                newCad.bank_account.bank == "" ||
                newCad.bank_account.agency.replace(/[^0-9 ]/g, "") == 0 ||
                newCad.bank_account.account.replace(/[^0-9 ]/g, "") == 0 ||
                newCad.bank_account.account_code.replace(/[^0-9 ]/g, "") == 0 ||
                newCad.company_icon == "",
              onClick: async () => {
                if (
                  newCad.nickname == "" ||
                  newCad.code.replace(/[^0-9 ]/g, "") == "" ||
                  newCad.email == "" ||
                  newCad.password.length < 8 ||
                  newCad.address.code.replace(/[^0-9 ]/g, "") == "" ||
                  newCad.address.street == "" ||
                  newCad.address.state == "" ||
                  newCad.address.number == 0 ||
                  newCad.bank_account.bank == "" ||
                  newCad.bank_account.agency.replace(/[^0-9 ]/g, "") == 0 ||
                  newCad.bank_account.account.replace(/[^0-9 ]/g, "") == 0 ||
                  newCad.bank_account.account_code.replace(/[^0-9 ]/g, "") ==
                    "" ||
                  newCad.company_icon == ""
                )
                  return;
                try {
                  let provider_info = (
                    await api.manager.getDataByCnpj(replacedCode)
                  ).data;

                  // if (provider_info.data.status === 400) {
                  //   setAlertTitle("Error");
                  //   setAlertMsg("CNPJ inválido");
                  //   setErrorPopUp(true);
                  //   return;
                  // }

                  let add_provider_data = await api.manager.addProvider({
                    nickname: newCad.nickname,
                    name:
                      providerType === 1
                        ? provider_info.data.razao_social
                        : newCad.nickname,
                    code: replacedCode,
                    email: newCad.email,
                    password: newCad.password,
                    database: newCad.database,
                    address: {
                      code: replacedAdressCode,
                      street: newCad.address.street,
                      number: newCad.address.number,
                      district: newCad.address.district,
                      city: newCad.address.city,
                      state: newCad.address.state,
                      country: newCad.address.country,
                      complement: newCad.address.complement,
                    },
                    bank_account: {
                      bank: newCad.bank_account.bank,
                      agency: newCad.bank_account.agency,
                      agency_code: newCad.bank_account.agency_code,
                      account: newCad.bank_account.account,
                      account_code: newCad.bank_account.account_code,
                    },
                    company_icon: newCad.company_icon,
                  });
                  setAlertMsg("Cadastrado com sucesso");
                  setPopup(true);
                  // clear fields
                  setNewCad({
                    nickname: "",
                    name: "",
                    code: "",
                    email: "",
                    database: "",
                    password: "",
                    address: {
                      code: "",
                      street: "",
                      number: "",
                      district: "",
                      city: "",
                      state: "",
                      country: "",
                      complement: "",
                    },
                    bank_account: {
                      bank: "",
                      agency: "",
                      agency_code: "",
                      account: "",
                      account_code: "",
                    },
                    company_icon: "",
                  });
                } catch {
                  setAlertTitle("Error");
                  setAlertMsg("Falha ao cadastrar fornecedor");
                  setErrorPopUp(true);
                }
              },
              style: {
                fontFamily: "Poppins",
                fontSize: 16,
                fontWeight: "bold",
                backgroundColor:
                  newCad.nickname == "" ||
                  newCad.code.replace(/[^0-9 ]/g, "") == "" ||
                  newCad.email == "" ||
                  newCad.password.length < 8 ||
                  newCad.address.code.replace(/[^0-9 ]/g, "") == "" ||
                  newCad.address.street == "" ||
                  newCad.address.state == "" ||
                  newCad.address.number == 0 ||
                  newCad.bank_account.bank == "" ||
                  newCad.bank_account.agency.replace(/[^0-9 ]/g, "") == 0 ||
                  newCad.bank_account.account.replace(/[^0-9 ]/g, "") == 0 ||
                  newCad.bank_account.account_code.replace(/[^0-9 ]/g, "") ==
                    0 ||
                  newCad.company_icon == ""
                    ? "#BDBDBD"
                    : theme.colors.pink2,
                border: "none",
                color:
                  newCad.nickname == "" ||
                  newCad.code.replace(/[^0-9 ]/g, "") == "" ||
                  newCad.email == "" ||
                  newCad.password.length < 8 ||
                  newCad.address.code.replace(/[^0-9 ]/g, "") == "" ||
                  newCad.address.street == "" ||
                  newCad.address.state == "" ||
                  newCad.address.number == 0 ||
                  newCad.bank_account.bank == "" ||
                  newCad.bank_account.agency.replace(/[^0-9 ]/g, "") == 0 ||
                  newCad.bank_account.account.replace(/[^0-9 ]/g, "") == 0 ||
                  newCad.bank_account.account_code.replace(/[^0-9 ]/g, "") ==
                    0 ||
                  newCad.company_icon == ""
                    ? "#D3D3D3"
                    : "#fff",
                width: 325,
                height: 60,
                borderRadius: 16,
                cursor:
                  newCad.nickname == "" ||
                  newCad.code.replace(/[^0-9 ]/g, "") == "" ||
                  newCad.email == "" ||
                  newCad.password.length < 8 ||
                  newCad.address.code.replace(/[^0-9 ]/g, "") == "" ||
                  newCad.address.street == "" ||
                  newCad.address.state == "" ||
                  newCad.address.number == 0 ||
                  newCad.bank_account.bank == "" ||
                  newCad.bank_account.agency.replace(/[^0-9 ]/g, "") == 0 ||
                  newCad.bank_account.account.replace(/[^0-9 ]/g, "") == 0 ||
                  newCad.bank_account.account_code.replace(/[^0-9 ]/g, "") ==
                    0 ||
                  newCad.company_icon == ""
                    ? "unset"
                    : "pointer",
              },
            },
          ],
        },
      ],
    };
  } else {
    return {
      component: "container",
    };
  }
};

export default FormRegisterAdmin;
