import React, { useEffect, useState } from "react";
import theme from "../theme";
import api from "../service";
import { useAuth } from "../context/authContext";
import { useNavigate } from "react-router-dom";
import AlertPopUp from "./AlertPopUp";
import { useAlert } from "../context/alertContext";

let RegisterForm = () => {
  let navigate = useNavigate();
  const { token, setToken, handleLogin, autoLogin } = useAuth();
  const { errorPopUp, setErrorPopUp } = useAlert();
  const [inputError, setInputError] = useState(0);

  useEffect(() => {
    (async () => {
      await autoLogin(navigate);
    })();
  }, []);
  const [login, setLogin] = useState({
    login_code: "",
    login_password: "",
  });
  return {
    component: "container",
    style: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    children: [
      {
        component: "container",
        style: {
          display: "flex",
          flexDirection: "column",
          margin: "20px 0",
        },
        children: [
          {
            component: "text",
            text: "CNPJ",
            style: {
              fontFamily: "Poppins",
              fontSize: 20,
              fontWeight: "bold",
            },
          },
          {
            component: "input",
            placeholder: "Digite aqui",
            onChange: (e) => {
              const val =
                e.target.value.length === 14
                  ? e.target.value.slice(0, 14)
                  : e.target.value;
              setLogin({
                ...login,
                login_code: e.target.value.toString().replace(/[^\w\s]/gi, ""),
              });
            },
            onBlur: () => {
              if (login.login_code.length == 0) {
                setInputError(1);
              } else {
                setInputError(0);
              }
            },
            style: {
              height: 50,
              width: 325,
              backgroundColor: "#EFF0F7",
              border:
                inputError == 1 ? `1px solid ${theme.colors.pink2}` : "none",
              borderRadius: 16,
              padding: "0 20px",
              fontFamily: "Poppins",
              fontSize: 16,
              marginTop: 8,
            },
          },
          {
            component: "text",
            text: inputError == 1 ? "Esse campo não pode ser vazio" : "",
            style: {
              color: theme.colors.pink2,
              fontSize: 12,
              fontFamily: "Poppins",
              marginLeft: 5,
            },
          },
        ],
      },
      {
        component: "container",
        style: {
          display: "flex",
          flexDirection: "column",
          margin: "20px 0",
        },
        children: [
          {
            component: "text",
            text: "Senha",
            style: {
              fontFamily: "Poppins",
              fontSize: 20,
              fontWeight: "bold",
            },
          },
          {
            component: "input",
            placeholder: "Digite aqui",
            type: "password",
            onChange: (e) =>
              setLogin({ ...login, login_password: e.target.value }),
            onBlur: () => {
              if (login.login_password.length == 0) {
                setInputError(2);
              } else {
                setInputError(0);
              }
            },
            style: {
              height: 50,
              width: 325,
              backgroundColor: "#EFF0F7",
              border:
                inputError == 2 ? `1px solid ${theme.colors.pink2}` : "none",
              borderRadius: 16,
              padding: "0 20px",
              fontFamily: "Poppins",
              fontSize: 16,
              marginTop: 8,
            },
          },
          {
            component: "text",
            text: inputError == 2 ? "Esse campo não pode ser vazio" : "",
            style: {
              color: theme.colors.pink2,
              fontSize: 12,
              fontFamily: "Poppins",
              marginLeft: 5,
            },
          },
        ],
      },
      {
        component: "container",
        style: {
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          marginBottom: "5%",
        },
        children: [
          {
            component: "text",
            text: "Esqueci minha senha",
            onClick: () => navigate("/password-forget"),
            style: {
              fontFamily: "Poppins",
              fontSize: 16,
              fontWeight: "bold",
              textDecoration: "underline",
              color: theme.colors.pink1,
              marginLeft: -145,
              cursor: "pointer",
            },
          },
        ],
      },
      {
        component: "container",
        style: {
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        },
        children: [
          {
            render: (
              <AlertPopUp
                title="Error"
                show={errorPopUp}
                setTrigger={setErrorPopUp}
                background="#F68989"
                color="#874356"
                message="Dados inválidos"
                callback={() => console.log("callback function")}
              />
            ),
          },
          {
            component: "button",
            text: "Entrar",
            onClick: async () => {
              try {
                let { data } = await api.auth.login(login);
                handleLogin(
                  data.data.token,
                  data.data.type,
                  data.data.user,
                  navigate
                );

                let res = (await api.provider.serviceCategory()).data;
                res.data.map((service_category) => {
                  localStorage.setItem(
                    service_category._id,
                    service_category.image
                  );
                });
              } catch (error) {
                console.log(error);
                setErrorPopUp(true);
              }
            },
            style: {
              fontFamily: "Poppins",
              fontSize: 16,
              fontWeight: "bold",
              backgroundColor: theme.colors.pink2,
              border: "none",
              color: "#fff",
              width: 325,
              height: 60,
              borderRadius: 16,
              cursor: "pointer",
            },
          },
        ],
      },
    ],
  };
};

let ContainerRegisterForm = () => {
  return {
    component: "container",
    style: {
      width: "45%",
      height: 490,
      backgroundColor: "#fff",
      borderRadius: 8,
      boxShadow: "0px 0px 25px rgba(182, 182, 188, 0.2)",
    },
    children: [RegisterForm()],
  };
};

export default ContainerRegisterForm;
