import React, { useEffect, useState } from "react";
import { Render } from "@8_dev/sjson-web";
import Theme from "../../theme";
import Navbar from "../../components/Navbar";
import { useActive } from "../../context/activeContext";
import { useAuth } from "../../context/authContext";
import { useAlert } from "../../context/alertContext";
import theme from "../../theme";
import api from "../../service";
import DogSound from "../../assets/sounds/dog.mp3";
import { DataGrid } from "@mui/x-data-grid";
import { Howl, Howler } from "howler";
import Countdown from "react-countdown";
import Core from "@8_dev/front_web_lbry";
import OrderCard from "../../components/OrderCard/OrderCard";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useSocket } from "../../hooks/useSocket";
import AlertPopUp from "../../components/AlertPopUp";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import DogAudio from "../../assets/sounds/dog.mp3";
import CalendarIcon from "../../assets/images/calendar.svg";
import CalendarView from "../../components/suggestHour";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

let DashboardProvider = () => {
  const [counterNew, setCounterNew] = useState([]);
  const [counterInProgress, setCounterInProgress] = useState([]);
  const [counterFinished, setCounterFinished] = useState([]);

  const [pageView, setPageView] = useState("new");
  const [category, setCategory] = useState();
  const { handleEvents, connected } = useSocket();
  const { active } = useActive();
  const { suggestPopUp, setSuggestPopUp, errorPopUp, setErrorPopUp } =
    useAlert();
  const [lastSells, setLastSells] = useState([]);
  const [finished, setFinished] = useState(false);
  const [sellsPenddings, setSellsPenddings] = useState([]);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [open, setOpen] = React.useState(false);
  const [snackBarAlert, setSnackbarAlert] = useState({
    info: {
      time: 0,
      type: "",
      msg: "",
    },
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  let columns = [
    { field: "client", headerName: "Cliente", flex: 1 },
    { field: "cel", headerName: "Celular", flex: 1 },
    {
      field: "service",
      headerName: "Serviço",
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              padding: 0,
              height: 60,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p style={{ margin: 0, height: 15 }}>
              {cellValues.value[0].service.category.name}
            </p>
            <p style={{ margin: 0, height: 10 }}>
              {cellValues.value.map((service, index) => {
                if (index >= 1) {
                  return `, ${service.description}`;
                } else {
                  return service.description;
                }
              })}
            </p>
          </div>
        );
      },
    },
    { field: "schedule", headerName: "Agendamento", flex: 0.5 },
    { field: "time", headerName: "Horário", flex: 0.5 },
    // { field: "location", headerName: "Estabelecimento", flex: 1 },
    {
      field: "total",
      headerName: "Valor total",
      flex: 0.5,
      renderCell: (cellValues) => {
        if (
          cellValues.value.toString().includes(".") &&
          cellValues.value != 0
        ) {
          return <p>R$ {cellValues.value.toString().replace(".", ",")}</p>;
        } else if (cellValues.value != 0) {
          return <p>R$ {cellValues.value},00</p>;
        } else {
          return "-";
        }
      },
    },
    {
      field: "delivery_service_value",
      headerName: "Frete",
      flex: 0.5,
      renderCell: (cellValues) => {
        if (
          cellValues.value.toString().includes(".") &&
          cellValues.value != 0
        ) {
          return <p>R$ {cellValues.value.toString().replace(".", ",")}</p>;
        } else if (cellValues.value != 0) {
          return <p>R$ {cellValues.value},00</p>;
        } else {
          return "-";
        }
      },
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (cellValues) => {
        if (cellValues.value == 1) {
          return (
            <div
              style={{
                fontSize: 16,
                display: "flex",
                alignItems: "center",
                padding: 3,
                width: 300,
                height: "50%",
                color: "#004FC6",
              }}
            >
              {cellValues.row.data.status.name}
            </div>
          );
        } else if (cellValues.value == 0 || cellValues.value >= 1000) {
          return (
            <div
              style={{
                fontSize: 16,
                display: "flex",
                alignItems: "center",
                padding: 3,
                width: 300,
                height: "50%",
                color: Theme.colors.pink2,
              }}
            >
              {cellValues.row.data.status.name}
            </div>
          );
        }
      },
    },
  ];

  let generateRows = () => {
    let rows = [];
    for (let order of counterFinished) {
      if (
        order.status.code == 0 ||
        order.status.code == 1 ||
        order.status.code >= 1000
      ) {
        rows.push({
          id: order.id,
          client: order?.people?.name,
          cel: order?.people?.contacts.map((contact, index) => {
            if (index >= 1) {
              return ` (${contact.phone.area_code}) ${contact.phone.number
                .toString()
                .slice(0, 5)}-${contact.phone.number.toString().slice(5, 9)}`;
            } else {
              return `(${contact.phone.area_code}) ${contact.phone.number
                .toString()
                .slice(0, 5)}-${contact.phone.number.toString().slice(5, 9)}`;
            }
          }),
          service: order?.services,
          // service: order?.services?.map((services) => {
          //   return service.description;
          // }),
          schedule: `${format(order.service_date, " dd/MM", {
            locale: ptBR,
            timeZone: "America/Sao_Paulo",
          })}`,
          time: `${order.hour}`,
          // location: order?.provider?.nickname,
          total: order?.service_value + order?.delivery_service_value,
          delivery_service_value: order?.delivery_service_value,
          status: order?.status?.code,
          data: order,
        });
      }
    }
    return rows;
  };

  let soundClips = DogAudio;

  let soundPlay = async (src, time) => {
    const sound = new Howl({
      src,
      html5: true,
    });
    for (let i = 0; i < time; i++) {
      await sleep(550 * i);
      sound.play();
    }
  };

  useEffect(() => {
    if (!connected) return;
    handleEvents("update_order", async (data) => {
      soundPlay(DogSound, 2);
      let resSells = await api.provider.getLastSells();
      setLastSells(resSells.data.data.reverse());

      let counter = {
        new: [],
        inProgress: [],
        finished: [],
      };

      resSells.data.data.map((order) => {
        if (order.status.code >= 996 && order.status.code <= 998) {
          if (order.suggested_by === "provider") counter.inProgress.push(order);
          else counter.new.push(order);
        } else if (order.status.code < 996 && order.status.code > 1)
          counter.inProgress.push(order);
        else counter.finished.push(order);

        if (order.status.code == 997 && order.suggested_by == "people") {
          setSuggestPopUp(true);
        }
      });

      setCounterNew(counter.new);
      setCounterInProgress(counter.inProgress);
      setCounterFinished(counter.finished);
    });
  }, [connected]);

  useEffect(() => {
    if (!connected) return;
    handleEvents("new_order", async () => {
      soundPlay(DogSound, 2);
      let resSells = await api.provider.getLastSells();
      setLastSells(resSells.data.data.reverse());
    });
  }, [connected]);

  useEffect(() => {
    (async () => {
      let resSells = await api.provider.getLastSells();
      setLastSells(resSells.data.data.reverse());

      let counter = {
        new: [],
        inProgress: [],
        finished: [],
      };
      resSells.data.data.map((order) => {
        if (
          (order.status.code >= 996 && order.status.code <= 998) ||
          order.status.code === 1002
        ) {
          if (order.suggested_by === "provider") counter.inProgress.push(order);
          else counter.new.push(order);
        } else if (order.status.code < 996 && order.status.code > 1)
          counter.inProgress.push(order);
        else counter.finished.push(order);

        if (order.status.code == 997 && order.suggested_by == "people")
          setSuggestPopUp(true);
      });
      setCounterNew(counter.new);
      setCounterInProgress(counter.inProgress);
      setCounterFinished(counter.finished);

      // let resSellsPending = await api.provider.getPendingSells();
      // setSellsPenddings(resSellsPending.data.data.reverse());
      // setCounterNew(resSellsPending.data.data);
    })();
  }, []);
  //console.log(lastSells);
  // let renderOrder = ({ show }) => {
  //   let renderServiceLine = (data) => {
  //     //console.log(data, "pokémon");
  //     let lines = [];

  //     for (let service of data) {
  //       //console.log(service);
  //       lines.push({
  //         component: "container",
  //         style: {
  //           width: 400,
  //           display: "flex",
  //           justifyContent: "space-between",
  //           margin: "10px 0",
  //         },
  //         children: [
  //           {
  //             component: "text",
  //             text: service.service.category.name,
  //             style: {
  //               color: theme.colors.darkBlue,
  //               fontSize: 20,
  //               fontFamily: "Poppins",
  //               fontWeight: "bold",
  //             },
  //           },
  //           {
  //             component: "text",
  //             text:
  //               "R$ " +
  //               service.value * (service.amount == 0 ? 1 : service.amount),
  //             style: {
  //               color: theme.colors.darkGreen,
  //               fontSize: 20,
  //               fontFamily: "Poppins",
  //               fontWeight: "bold",
  //             },
  //           },
  //         ],
  //       });
  //     }
  //     return lines;
  //   };
  //   return show
  //     ? {
  //         component: "container",
  //         style: {
  //           width: "90%",
  //           height: 550,
  //           padding: "3% 0%",
  //           backgroundColor: "#fff",
  //           border: "1px solid #E0E0E0",
  //           borderRadius: 8,
  //           marginTop: 120,
  //           display: "flex",
  //           flexDirection: "column",
  //           alignItems: "center",
  //         },
  //         children: [
  //           {
  //             component: "text",
  //             text:
  //               sellsPenddings[0].pet.name +
  //               " fez um Pet-in e está aguardando o seu aceite.",
  //             style: {
  //               color: theme.colors.darkBlue,
  //               fontSize: 20,
  //               fontFamily: "Poppins",
  //             },
  //           },
  //           {
  //             component: "text",
  //             text: "Confirme a sua venda em:",
  //             style: {
  //               color: theme.colors.darkBlue,
  //               fontSize: 32,
  //               fontFamily: "Poppins",
  //               marginTop: 20,
  //               fontWeight: "bold",
  //             },
  //           },
  //           {
  //             component: "container",
  //             style: {
  //               marginTop: sellsPenddings.length ? 40 : 120,
  //             },
  //             children: [
  //               {
  //                 render: (
  //                   <Countdown
  //                     zeroPadTime={2}
  //                     date={sellsPenddings[0].date + 1200000}
  //                     renderer={(props) => {
  //                       return renderer(props);
  //                     }}
  //                   />
  //                 ),
  //               },
  //             ],
  //           },
  //           {
  //             component: "button",
  //             text: finished ? "Você perdeu sua venda" : "Confirmar Pet-in",
  //             onClick: async () => {
  //               // socket.emit("", () => {
  //               //   return {order: sellsPenddings[0]._id}
  //               // })
  //               let { data } = await api.provider.confirmPenddingSells({
  //                 order: sellsPenddings[0]._id,
  //               });
  //               setSellsPenddings(data.data);
  //               window.location.reload();
  //             },
  //             style: {
  //               color: "#fff",
  //               backgroundColor: theme.colors.pink1,
  //               width: 340,
  //               height: 60,
  //               fontSize: 24,
  //               fontFamily: "Poppins",
  //               marginTop: 30,
  //               border: "none",
  //               borderRadius: 8,
  //               cursor: "pointer",
  //             },
  //           },
  //           {
  //             component: "container",
  //             style: {
  //               backgroundColor: "#E0E0E0",
  //               width: "100%",
  //               height: 2,
  //               marginTop: 20,
  //             },
  //           },
  //           {
  //             component: "text",
  //             text: "Resumo da venda:",
  //             style: {
  //               color: theme.colors.darkBlue,
  //               fontSize: 22,
  //               fontFamily: "Poppins",
  //               marginTop: 20,
  //               marginBottom: 25,
  //               fontWeight: "bold",
  //             },
  //           },
  //           ...renderServiceLine(sellsPenddings[0].services),

  //           {
  //             component: "container",
  //             style: {
  //               width: 400,
  //               display: "flex",
  //               justifyContent: "space-between",
  //               margin: "10px 0",
  //             },
  //             children: [
  //               {
  //                 component: "text",
  //                 text: "Comissão Petin:",
  //                 style: {
  //                   color: theme.colors.darkBlue,
  //                   fontSize: 20,
  //                   fontFamily: "Poppins",
  //                   fontWeight: "bold",
  //                 },
  //               },
  //               {
  //                 component: "text",
  //                 text: "R$ " + sellsPenddings[0].commission_value,
  //                 style: {
  //                   color: theme.colors.pink1,
  //                   fontSize: 20,
  //                   fontFamily: "Poppins",
  //                   fontWeight: "bold",
  //                 },
  //               },
  //             ],
  //           },
  //           {
  //             component: "container",
  //             style: {
  //               width: 400,
  //               display: "flex",
  //               justifyContent: "space-between",
  //               margin: "15px 0 10px 0",
  //             },
  //             children: [
  //               {
  //                 component: "text",
  //                 text: "Valor final:",
  //                 style: {
  //                   color: theme.colors.darkBlue,
  //                   fontSize: 28,
  //                   fontFamily: "Poppins",
  //                   fontWeight: "bold",
  //                 },
  //               },
  //               {
  //                 component: "text",
  //                 text: "R$ " + getFullValue(sellsPenddings[0].services),
  //                 onClick: () => {
  //                   getFullValue(sellsPenddings[0].services);
  //                 },
  //                 style: {
  //                   color: theme.colors.darkBlue,
  //                   fontSize: 28,
  //                   fontFamily: "Poppins",
  //                   fontWeight: "bold",
  //                 },
  //               },
  //             ],
  //           },
  //         ],
  //       }
  //     : { render: <p>Ola</p> };
  // };
  // let getFullValue = (services) => {
  //   let fullValue = 0;

  //   for (let item of services) {
  //     let val = item.value * (item.amount == 0 ? 1 : item.amount);
  //     fullValue += val;
  //   }
  //   //console.log(fullValue);
  //   return fullValue;
  // };

  return Render({
    component: "container",
    style: {
      width: "100vw",
      overflow: "none",
      display: "flex",
      flexDirection: "column",
    },
    children: [
      Navbar({
        isAdmin: false,
        context: "provider",
      }),
      {
        component: "container",
        style: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          marginTop: "1%",
          overflow: "none",
        },
        children: [
          {
            render: (
              <Snackbar
                open={open}
                autoHideDuration={snackBarAlert.info.time}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity={snackBarAlert.info.type}
                  sx={{ width: "100%" }}
                >
                  {snackBarAlert.info.msg}
                </Alert>
              </Snackbar>
            ),
          },
          // Menu, 1 Novos - Em Andamento - Finalizados
          {
            component: "container",
            style: {
              backgroundColor: "#fff",
              border: "1px solid #E0E0E0",
              borderRadius: 8,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "1%",
              overflow: "none",
            },
            children: [
              // Novos
              {
                component: "container",
                style: {
                  backgroundColor: "#fff",
                  border:
                    pageView === "new"
                      ? "3px solid #a3a3a3"
                      : "1px solid #E0E0E0",
                  borderRadius: 8,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "40px",
                  marginRight: "20px",
                  height: 100,
                },
                onClick: () => {
                  setPageView("new");
                },
                children: [
                  {
                    component: "text",
                    text: "Novos",
                    style: {
                      color: theme.colors.darkBlue,
                      fontSize: 20,
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      marginRight: "10px",
                    },
                  },
                  // counter bal notify
                  {
                    component: "container",
                    style: {
                      aspectRatio: "1/1",
                      backgroundColor: theme.colors.pink1,
                      borderRadius: 50,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: 50,
                    },
                    children: [
                      {
                        component: "text",
                        text: counterNew.length,
                        style: {
                          color: "#fff",
                          fontSize: 20,
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                        },
                      },
                    ],
                  },
                ],
              },
              // Em Andamento
              {
                component: "container",
                style: {
                  backgroundColor: "#fff",
                  border:
                    pageView === "inProgress"
                      ? "3px solid #a3a3a3"
                      : "1px solid #E0E0E0",
                  borderRadius: 8,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "40px",
                  marginRight: "20px",
                  height: 100,
                },
                onClick: () => {
                  setPageView("inProgress");
                },
                children: [
                  {
                    component: "text",
                    text: "Andamento",
                    style: {
                      color: theme.colors.darkBlue,
                      fontSize: 20,
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      marginRight: "10px",
                    },
                  },
                  // counter bal notify
                  {
                    component: "container",
                    style: {
                      aspectRatio: "1/1",
                      backgroundColor: theme.colors.pink1,
                      borderRadius: 50,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: 50,
                    },
                    children: [
                      {
                        component: "text",
                        text: counterInProgress.length,
                        style: {
                          color: "#fff",
                          fontSize: 20,
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                        },
                      },
                    ],
                  },
                ],
              },
              // Finalizados
              {
                component: "container",
                style: {
                  backgroundColor: "#fff",
                  border:
                    pageView === "finished"
                      ? "3px solid #a3a3a3"
                      : "1px solid #E0E0E0",
                  borderRadius: 8,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "40px",
                  marginRight: "5px",
                  height: 100,
                },
                onClick: () => {
                  setPageView("finished");
                },
                children: [
                  {
                    component: "text",
                    text: "Finalizados",
                    style: {
                      color: theme.colors.darkBlue,
                      fontSize: 20,
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      marginRight: "10px",
                    },
                  },
                ],
              },

              // Calendario
              {
                component: "container",
                style: {
                  backgroundColor: "#fff",
                  border:
                    pageView === "calendar"
                      ? "3px solid #a3a3a3"
                      : "1px solid #E0E0E0",
                  borderRadius: 4,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "10px",
                  marginLeft: "50px",
                  marginRight: "5px",
                  height: 100,
                  display: "none",
                },
                onClick: () => {
                  setPageView("calendar");
                },
                children: [
                  {
                    render: (
                      <img
                        src={CalendarIcon}
                        style={{
                          width: 50,
                          height: 50,
                          marginRight: 10,
                        }}
                        onClick={() => console.log()}
                      />
                    ),
                  },
                  {
                    component: "text",
                    text: "Agenda",
                    style: {
                      color: theme.colors.darkBlue,
                      fontSize: 20,
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      marginRight: "10px",
                    },
                  },
                ],
              },
            ],
          },
          // Calendar

          // CalendarView({}),

          // Novos
          {
            component: "container",
            style: {
              overflow: "auto",
              display: pageView === "new" ? "flex" : "none",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "80%",
              maxWidth: "80%",
              // maxHeight: "75vh",
              marginTop: "1%",
              paddingBottom: "1%",
              border: "1px solid #E0E0E0",
            },
            children: [
              {
                render: counterNew.map((order) => {
                  return <OrderCard order={order} />;
                }),
              },
              // sem pedidos
              {
                component: "text",
                text: "Sem novos pedidos",
                style: {
                  display: counterNew.length === 0 ? "flex" : "none",
                  fontSize: 30,
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  marginRight: "10px",
                  margin: "5%",
                },
              },
            ],
          },
          // Em Andamento
          {
            component: "container",
            style: {
              overflow: "auto",
              display: pageView === "inProgress" ? "flex" : "none",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "80%",
              maxWidth: "80%",
              // maxHeight: "75vh",
              marginTop: "1%",
              paddingBottom: "1%",
              border: "1px solid #E0E0E0",
            },
            children: [
              {
                render: counterInProgress.map((order) => {
                  return <OrderCard order={order} />;
                }),
              },
            ],
          },
          // Finalizados
          {
            component: "container",
            style: {
              width: "90%",
              backgroundColor: "#fefefe",
              display: pageView === "finished" ? "flex" : "none",
              border: "1px solid #E0E0E0",
              borderRadius: 8,
              marginTop: "2%",
              flexDirection: "column",
              marginBottom: "4%",
            },
            children: [
              {
                component: "text",
                text: "Pedidos Finalizados",
                style: {
                  color: theme.colors.darkBlue,
                  fontSize: 25,
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  marginLeft: 40,
                  marginTop: 30,
                  marginBottom: 30,
                },
              },
              {
                component: "container",
                style: { width: "100%" },
                children: [
                  {
                    render: (
                      <DataGrid
                        disableSelectionOnClick
                        pagination={false}
                        autoHeight
                        rows={generateRows()}
                        columns={columns}
                        rowHeight={60}
                      />
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  });
};

export default DashboardProvider;
