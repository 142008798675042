import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export default (param) => {
  let keyVal = {};
  const [value, setValue] = useState(param.default || "");

  const handleChange = async (event) => {
    setValue(event.target.value);
    console.log("val", event.target.value);
    if (param.onChange) setValue(event.target.value);
    try {
      param.onChange(keyVal[event.target.value]);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    setValue: async (val) => setValue(val),
    component: (
      <div style={param?.style?.box || {}} onClick={param.onClick || null}>
        <Box sx={{ minWidth: "100%" }}>
          <FormControl fullWidth>
            <InputLabel id="lbl">{param.title || ""}</InputLabel>
            <Select
              labelId="lbl"
              label={param.title || ""}
              onChange={handleChange}
              value={value}
              defaultValue={param.default}
            >
              {param.item?.map((item, i) => {
                keyVal[item.key || i] = item.value;

                return (
                  <MenuItem value={item.key || i}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        // padding: "10px",
                        // borderBottom: "1px solid #ccc",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          height: 40,
                          aspectRatio: "1/1",
                          padding: "1%",
                          marginRight: "3%",
                        }}
                      >
                        {item.icon ? (
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                            src={item.icon}
                          />
                        ) : (
                          ""
                        )}
                      </div>

                      <div
                        style={{
                          flex: 5,
                          padding: 8,
                        }}
                      >
                        {item.label}
                      </div>
                    </div>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </div>
    ),
  };
};
