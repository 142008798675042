import { privateHttp, publicHttp, Req, baseURL } from "./http";

export default {
  download: {
    sheetModel: async (file) =>
      window.open(
        process.env.REACT_APP_API_URL + "/api/media/doc/sheet/" + file
      ),
  },
  auth: {
    login: async (body) => publicHttp.post("/auth/signin", body),
  },
  passwordforget: {
    emailPost: async (body) => {
      return privateHttp.post("password_recovery", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      });
    },
    newPasswordSend: async (body) => {
      return privateHttp.post("set_new_password", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      });
    },
  },
  manager: {
    addProvider: async (body) =>
      privateHttp.post("/provider?key=jquivh78ev7w8yvgygwyvwbvRQ@R", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    addBusiness: async (body) =>
      privateHttp.post("/business?key=jquivh78ev7w8yvgygwyvwbvRQ@R", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    setSchedule: async (body) =>
      privateHttp.post("/set_schedule", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    getDataByCnpj: async (code) =>
      privateHttp.get("/search_client_code?code=" + code, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    getBank: async (code) =>
      privateHttp.get("/bank", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    getProviderSchedule: async (category, weekday) =>
      privateHttp.get(
        "/provider_schedule?id=category" + category + "&weekday=" + weekday,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "Error",
          },
        }
      ),
  },
  provider: {
    getServicesOptions: async () =>
      privateHttp.get("/service_category", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    getAnimalsType: async () =>
      privateHttp.get("/animal_category", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    attProvider: async (payload) =>
      privateHttp.post("/provider_delivery_service", payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    getProviderService: async () =>
      privateHttp.get("/provider_service", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    addNewService: async (body) =>
      privateHttp.post("/order_service_update", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    getServicesOptionsSubcategory: async (category) =>
      privateHttp.get(
        category
          ? "/service_sub_category?category=" + category
          : "/service_sub_category",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "Error",
          },
        }
      ),
    getPetSize: async (category) =>
      privateHttp.get(
        category ? "/animal_size?category=" + category : "/animal_size",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "Error",
          },
        }
      ),
    addService: async (body) =>
      await Req.Post({
        path: "/provider_service",
        data: body,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),

    listService: async (body) =>
      privateHttp.get("/service", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    getService: async (_id) =>
      privateHttp.get("/service?animal_category=" + _id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    getLastSells: async () =>
      privateHttp.get("/order_buy", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    getPendingSells: async () =>
      privateHttp.get("/order_pending", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    confirmPendingSells: async (body) =>
      privateHttp.post("/confirm_order_pending", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    confirmPendingSellsTime: async (hour, body) =>
      privateHttp.post("/confirm_order_pending?hour=" + hour, body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    cancelOrder: async (body) => {
      privateHttp.post("/cancel_order", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      });
    },
    suggestTime: async (body) => {
      privateHttp.post("suggested_time", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      });
    },
    startDelivery: async (body) => {
      privateHttp.post("/start_delivery", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      });
    },
    petDelivered: async (body) => {
      privateHttp.post("/pet_delivered", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      });
    },
    serviceCategory: async () =>
      privateHttp.get("/service_category", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    petDelivered: async (body) => {
      privateHttp.post("/pet_delivered", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      });
    },
    finalizeService: async (body) => {
      privateHttp.post("finalize_service", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      });
    },
    finalizeOrder: async (body) => {
      privateHttp.post("finalize_order", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      });
    },
    getOrders: async () =>
      privateHttp.get("/order", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    setStatus: async (body) =>
      privateHttp.post("/confirm_order_pending", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    getProviderServiceToShow: async (id) =>
      privateHttp.get("/additional_service?animal_category=" + id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    list: async () =>
      privateHttp.get("/provider", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    getUser: async () =>
      privateHttp.get("/get_user", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),

    workSchedule: async (body) =>
      privateHttp.post("/provider_work_schedule", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    categoryHour: async (id, date) =>
      privateHttp.get(
        "/provider_category_schedule?id=" + id + "&date=" + date,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "Error",
          },
        }
      ),
    categorySchedule: async (body) =>
      privateHttp.post("/provider_category_schedule", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    categoryPromotion: async (body) =>
      privateHttp.post("/provider_category_promotion", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    categoryDaycare: async (body) =>
      privateHttp.post("/provider_daycare", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
  },
  business: {
    registerPeople: async (body) =>
      privateHttp.post(
        "/register_people?key=jquivh78ev7w8yvgygwyvwbvRQ@R",
        body,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "Error",
          },
        }
      ),
    home: async () =>
      privateHttp.get("/business_home", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
  },
  utils: {
    addressCode: async (code) =>
      await publicHttp.get(`/search_address_code?code=` + code),
    update: (body) =>
      privateHttp.post("/update_info", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "Error",
        },
      }),
    getImage: (name) => baseURL + `/media/assets/icon/` + name + ".png",
  },
};
