import React, { useEffect, useState } from "react";
import theme from "../../theme";
import api from "../../service";
import { Render } from "@8_dev/sjson-web";
import { useAuth } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import AlertPopUp from "../../components/AlertPopUp";
import Navbar from "../../components/Navbar";
import { useAlert } from "../../context/alertContext";

let Page = () => {
  const addPeople = {};

  return Render({
    component: "container",
    style: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(100,100,100,0.1)",
    },
    children: [
      Navbar({
        isAdmin: false,
        context: "business",
      }),
    ],
  });
};

export default Page;
