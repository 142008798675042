import React, { useEffect, useState } from "react";
import { Render } from "@8_dev/sjson-web";
import Theme from "../../theme";
import Navbar from "../../components/Navbar";
import api from "../../service";
import { useAuth } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import AlertPopUp from "../../components/AlertPopUp";
import { useAlert } from "../../context/alertContext";

let NewPassword = () => {
  let navigate = useNavigate();
  const [inputError, setInputError] = useState(0);
  const { handleLogin } = useAuth();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [token, setToken] = useState("");
  const { errorPopUp, setErrorPopUp } = useAlert();
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    if (token === "") {
      console.log("não ha token");
      let urltoken = window.location.href.split("=")[1];
      //console.log(urltoken);
      setToken(urltoken);
      //console.log(token);
    }
  });

  return Render({
    component: "container",
    style: {
      width: "100vw",
      height: "100vh",
      backgroundColor: Theme.colors.backgroundBlue,
      display: "flex",
      flexDirection: "column",
    },
    children: [
      Navbar({
        isAdmin: false,
        context: "login",
      }),
      {
        render: (
          <AlertPopUp
            title="Error"
            show={errorPopUp}
            setTrigger={setErrorPopUp}
            background="#F68989"
            color="#874356"
            message={alertMessage}
            callback={() => console.log("callback function")}
          />
        ),
      },
      {
        component: "container",
        style: {
          width: "100%",
          display: "flex",
          justifyContent: "center",
          margin: "80px 0 0 0",
        },
        children: [
          {
            component: "text",
            text: "Redefinição de senha",
            style: {
              fontFamily: "Poppins",
              fontSize: 28,
              fontWeight: "bold",
              margin: "40px 0",
            },
          },
        ],
      },
      {
        component: "container",
        style: {
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        },
        children: [
          {
            component: "container",
            style: {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              width: "45%",
              height: 490,
              backgroundColor: "#fff",
              borderRadius: 8,
              boxShadow: "0px 0px 25px rgba(182, 182, 188, 0.2)",
            },
            children: [
              {
                component: "container",
                style: {
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                },
                children: [
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      flexDirection: "column",
                      margin: "20px 0",
                    },
                    children: [
                      {
                        component: "text",
                        text: "Nova senha",
                        style: {
                          fontFamily: "Poppins",
                          fontSize: 20,
                          fontWeight: "bold",
                        },
                      },
                      {
                        component: "input",
                        placeholder: "Digite aqui",
                        type: "password",
                        value: password,
                        onChange: (e) => setPassword(e.target.value),
                        onBlur: () => {
                          if (password.length < 8) {
                            setInputError(1);
                          } else {
                            setInputError(0);
                          }
                        },
                        style: {
                          height: 50,
                          width: 325,
                          backgroundColor: "#EFF0F7",
                          border:
                            inputError == 1
                              ? `1px solid ${Theme.colors.pink2}`
                              : "none",
                          borderRadius: 16,
                          padding: "0 20px",
                          fontFamily: "Poppins",
                          fontSize: 16,
                          marginTop: 8,
                        },
                      },
                      {
                        component: "text",
                        text: "A senha deve ter pelo menos 8 caracteres",
                        style: {
                          color:
                            inputError == 1
                              ? Theme.colors.pink2
                              : Theme.colors.darkBlue,
                          fontSize: 12,
                          fontFamily: "Poppins",
                          margin: 5,
                        },
                      },
                    ],
                  },
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      flexDirection: "column",
                      margin: "20px 0",
                    },
                    children: [
                      {
                        component: "text",
                        text: "Confirme sua nova senha",
                        style: {
                          fontFamily: "Poppins",
                          fontSize: 20,
                          fontWeight: "bold",
                        },
                      },
                      {
                        component: "input",
                        placeholder: "Digite aqui",
                        type: "password",
                        value: passwordConfirm,
                        onChange: (e) => setPasswordConfirm(e.target.value),
                        style: {
                          height: 50,
                          width: 325,
                          backgroundColor: "#EFF0F7",
                          border: "none",
                          borderRadius: 16,
                          padding: "0 20px",
                          fontFamily: "Poppins",
                          fontSize: 16,
                          marginTop: 8,
                        },
                      },
                    ],
                  },

                  {
                    component: "container",
                    style: {
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "center",
                    },
                    children: [
                      {
                        component: "button",
                        disabled:
                          password !== passwordConfirm ||
                          password.length < 8 ||
                          passwordConfirm.length < 8,
                        text: "Redefinir senha",
                        style: {
                          fontFamily: "Poppins",
                          fontSize: 16,
                          fontWeight: "bold",
                          backgroundColor:
                            password == passwordConfirm &&
                            password.length >= 8 &&
                            passwordConfirm.length >= 8
                              ? Theme.colors.pink2
                              : "#DFDFDE",
                          border: "none",
                          color: "#fff",
                          width: 325,
                          height: 60,
                          borderRadius: 16,
                          cursor:
                            password == passwordConfirm &&
                            password.length >= 8 &&
                            passwordConfirm.length >= 8
                              ? "pointer"
                              : "unset",
                        },
                        onClick: async () => {
                          let data = await api.passwordforget.newPasswordSend({
                            token: token,
                            password: password,
                          });
                          // console.log(data.data.data);
                          if (data.data.status) {
                            handleLogin(
                              data.data.data.token,
                              data.data.data.type,
                              data.data.data.user,
                              navigate
                            );
                            let res = (await api.provider.serviceCategory())
                              .data;
                            res.data.map((service_category) => {
                              localStorage.setItem(
                                service_category._id,
                                service_category.image
                              );
                            });
                          } else if (!data.data.data.status) {
                            setAlertMessage(data.data.data.errors[0].name);
                            setErrorPopUp(true);
                          }
                          //console.log(data);
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  });
};

export default NewPassword;
