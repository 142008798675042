import React from "react";
import { Render } from "@8_dev/sjson-web";
import Theme from "../../theme";
import Navbar from "../../components/Navbar";
import homePage from "../../components/BusinessHome";
import { useActive } from "../../context/activeContext";
import AlertPopUp from "../../components/AlertPopUp";
import { useAlert } from "../../context/alertContext";

let DashboardBusiness = () => {
  const { setErrorPopUp, errorPopUp, alertMsg, alertTitle } = useAlert();
  const { active } = useActive();

  return Render({
    component: "container",
    style: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      backgroundColor: "rgba(100,100,100,0.1)",
    },
    children: [
      Navbar({
        context: "business",
      }),
      homePage({ show: true }),
    ],
  });
};

export default DashboardBusiness;
