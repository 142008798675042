import React, { useState, useEffect } from "react";
import theme from "../theme";
import { useActive } from "../context/activeContext";
import api from "../service";
import { red } from "@mui/material/colors";
import { DataGrid } from "@mui/x-data-grid";
import { useAuth } from "../context/authContext";
import { dividerClasses } from "@mui/material";
import OrderCard from "./OrderCard/OrderCard";
import Countdown from "react-countdown";
import Theme from "../theme";
import CloseIcon from "@mui/icons-material/Close";
import AlertPopUp from "./AlertPopUp";
import { useAlert } from "../context/alertContext";
import { useSocket } from "../hooks/useSocket";

function OrderControl({ show }) {
  const { connected, handleEvents } = useSocket();
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("Alert");
  const { socket } = useAuth();
  const { errorPopUp, setErrorPopUp } = useAlert();
  const [selectedRow, setSelectedRow] = useState();
  const [orders, setOrders] = useState([]);
  const [popUp, setPopUp] = useState(false);
  const [scheduleData, setScheduleData] = useState();
  const [cancelPopup, setCancelPopup] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [selectedHours, setSelectedHours] = useState([]);
  const [schedule, setSchedule] = useState([]);
  let showAddress = false;

  const renderer = ({ seconds, minutes, completed, formatted }) => {
    if (completed) {
      return <p>Encerrado</p>;
    } else {
      return (
        <p>
          {formatted.minutes}:{formatted.seconds}
        </p>
      );
    }
  };

  const setProviderSchedule = async (cellValues) => {
    let data = await api.manager.getProviderSchedule(
      cellValues.row.data.provider._id
    );
    switch (cellValues.row.data.services[0].service.category.id) {
      case 1:
        setSchedule(data.data.data.schedule_bath[0].hours);
        break;
      case 2:
        setSchedule(data.data.data.schedule_vet[0].hours);
        break;
      case 3:
        setSchedule(data.data.data.schedule_hotel[0].hours);
        break;
      default:
        break;
    }
  };

  let columns = [
    { field: "num", headerName: "ID", flex: 0.1 },
    { field: "client", headerName: "Cliente", flex: 1 },
    { field: "cel", headerName: "Celular", flex: 1 },
    {
      field: "service",
      headerName: "Serviço",
      flex: 2,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              padding: 0,
              height: 60,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p style={{ margin: 0, height: 15 }}>
              {cellValues.value[0].service == null
                ? cellValues.value[1].service.category.name
                : cellValues.value[0].service.category.name}
            </p>
            <p style={{ margin: 0, height: 10 }}>
              {cellValues.value.map((service, index) => {
                if (index >= 1) {
                  return `, ${service.description}`;
                } else {
                  return service.description;
                }
              })}
            </p>
          </div>
        );
      },
    },
    { field: "schedule", headerName: "Agendamento", flex: 0.7 },
    // { field: "time", headerName: "Horário", flex: 0.5 },
    { field: "location", headerName: "Estabelecimento", flex: 1 },
    {
      field: "total",
      headerName: "Valor total",
      flex: 0.5,
      renderCell: (cellValues) => {
        if (
          cellValues.value.toString().includes(".") &&
          cellValues.value != 0
        ) {
          return <p>R$ {cellValues.value.toString().replace(".", ",")}</p>;
        } else if (cellValues.value != 0) {
          return <p>R$ {cellValues.value},00</p>;
        } else {
          return "-";
        }
      },
    },
    {
      field: "delivery_service_value",
      headerName: "Frete",
      flex: 0.5,
      renderCell: (cellValues) => {
        if (
          cellValues.value.toString().includes(".") &&
          cellValues.value != 0
        ) {
          return <p>R$ {cellValues.value.toString().replace(".", ",")}</p>;
        } else if (cellValues.value != 0) {
          return <p>R$ {cellValues.value},00</p>;
        } else {
          return "-";
        }
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (cellValues) => {
        if (cellValues.value == 999) {
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p style={{ marginTop: -15 }}>
                {cellValues.row.data.status.name}
              </p>
              <select
                onClick={() => setSelectedRow(cellValues)}
                onChange={(e) => {
                  setSelectedRow(cellValues);
                  if (e.target.value == "Sugerir Horário") {
                    setPopUp(true);
                    setProviderSchedule(cellValues);
                  } else if (e.target.value == "Aguardando") {
                  } else if (e.target.value == "Confirmar") {
                    setConfirmPopup(true);
                  } else {
                    setCancelPopup(true);
                  }
                }}
                style={{
                  width: "100%",
                  height: 26,
                  backgroundColor: "",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#FFBC11",
                  backgroundColor: "#FFE6A7",
                  borderRadius: 5,
                  marginTop: -20,
                }}
              >
                <option>Selecione uma opção </option>
                <option>Cancelar</option>
              </select>
            </div>
          );
        } else if (cellValues.value <= 5 && cellValues.value > 0) {
          return (
            <div
              style={{
                fontSize: 13,
                display: "flex",
                alignItems: "center",
                padding: 3,
                width: 300,
                height: "50%",
                backgroundColor: "#C8E8C8",
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#09B763",
                borderRadius: 5,
              }}
            >
              {cellValues.row.data.status.name}
            </div>
          );
        } else if (cellValues.value > 999) {
          return (
            <div
              style={{
                fontSize: 13,
                display: "flex",
                alignItems: "center",
                padding: 3,
                width: 300,
                height: "50%",
                backgroundColor: theme.colors.pink3,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: theme.colors.pink2,
                borderRadius: 5,
              }}
            >
              {cellValues.row.data.status.name}
            </div>
          );
        } else if (cellValues.value == 996) {
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p style={{ marginTop: -15 }}>
                {cellValues.row.data.status.name}
              </p>
              <select
                onClick={() => {
                  setSelectedRow(cellValues);
                }}
                onChange={(e) => {
                  setSelectedRow(cellValues);
                  setCancelPopup(true);
                }}
                style={{
                  width: "100%",
                  height: 26,
                  backgroundColor: "",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#FFBC11",
                  backgroundColor: "#FFE6A7",
                  borderRadius: 5,
                  marginTop: -20,
                }}
              >
                <option>Selecione uma opção </option>
                <option>Cancelar</option>
              </select>
            </div>
          );
        } else if (cellValues.value == 997 || cellValues.value == 998) {
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p style={{ marginTop: -15 }}>
                {cellValues.row.data.status.name}
              </p>
              <select
                onClick={() => {
                  setSelectedRow(cellValues);
                }}
                onChange={(e) => {
                  setSelectedRow(cellValues);
                  if (e.target.value == "Sugerir Horário") {
                    setPopUp(true);
                    setProviderSchedule(cellValues);
                  } else if (e.target.value == "Aguardando") {
                  } else if (e.target.value == "Confirmar") {
                    setConfirmPopup(true);
                  } else {
                    setCancelPopup(true);
                  }
                }}
                style={{
                  width: "100%",
                  height: 26,
                  backgroundColor: "",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#FFBC11",
                  backgroundColor: "#FFE6A7",
                  borderRadius: 5,
                  marginTop: -20,
                }}
              >
                <option>Selecione uma opção </option>
                <option>Sugerir Horário</option>
                <option>Confirmar</option>
                <option>Cancelar</option>
              </select>
            </div>
          );
        } else if (cellValues.value == 0) {
          return (
            <div
              style={{
                fontSize: 13,
                display: "flex",
                alignItems: "center",
                padding: 3,
                width: 300,
                height: "50%",
                backgroundColor: theme.colors.pink3,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: theme.colors.pink2,
                borderRadius: 5,
              }}
            >
              {cellValues.row.data.status.name}
            </div>
          );
        }
      },
    },
    {
      field: "countdown",
      headerName: "Tempo Restante",
      flex: 1,
      renderCell: (cellValues) => {
        return <Countdown renderer={renderer} date={cellValues.value} />;
      },
    },
  ];

  useEffect(async () => {
    if (!connected) return;

    handleEvents("update_order", async () => {
      let ordersRes = await api.provider.getOrders();
      setOrders(ordersRes.data.data.reverse());
    });
  }, [connected]);

  // useEffect(() => {
  //   if (!connected) return;
  //   handleEvents("notify_new", async (data) => {
  //     setAlertTitle(data.info.title);
  //     setAlertMessage(data.info.msg);
  //     setErrorPopUp(true);
  //   });
  // }, [connected]);

  useEffect(() => {
    (async () => {
      let ordersRes = await api.provider.getOrders();
      setOrders(ordersRes.data.data.reverse());
    })();
  }, []);

  let generateRows = () => {
    let rows = [];
    for (let order of orders) {
      rows.push({
        id: order._id,
        num: order.id,
        client: order?.people?.nickname || order?.people?.name || "",
        cel: order?.people?.contacts.map((contact, index) => {
          if (index >= 1) {
            return ` (${contact.phone.area_code}) ${contact.phone.number
              .toString()
              .slice(0, 5)}-${contact.phone.number.toString().slice(5, 9)}`;
          } else {
            return `(${contact.phone.area_code}) ${contact.phone.number
              .toString()
              .slice(0, 5)}-${contact.phone.number.toString().slice(5, 9)}`;
          }
        }),
        service: order?.services,
        schedule: `${
          new Date(order.service_date).getUTCDate() < 10
            ? `0${new Date(order.service_date).getUTCDate()}`
            : new Date(order.service_date).getUTCDate()
        }/${
          new Date(order.service_date).getMonth() + 1 < 10
            ? `0${new Date(order.service_date).getMonth() + 1}`
            : new Date(order.service_date).getMonth() + 1
        } (${order.hour == "" ? "00" : order.hour}:00)`,
        // time: `${order.hour == "" ? "00" : order.hour}:00`,
        countdown: order?.countdown,
        location: order?.provider?.nickname,
        total: order?.service_value + order?.delivery_service_value,
        delivery_service_value: order?.delivery_service_value,
        order_value: order?.order_value,
        status: order?.status?.code,
        data: order,
      });
    }
    return rows;
  };

  if (show) {
    return {
      component: "container",
      style: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        marginTop: 80,
      },
      children: [
        {
          render: (
            <AlertPopUp
              title={alertTitle}
              message={alertMessage}
              show={errorPopUp}
              setTrigger={setErrorPopUp}
            />
          ),
        },
        {
          component: "container",
          style: {
            width: "90%",
            backgroundColor: "#fff",
            border: "1px solid #E0E0E0",
            borderRadius: 8,
            display: "flex",
            flexDirection: "column",
            marginBottom: 50,
          },
          children: [
            {
              component: "text",
              text: "Controle de vendas",
              style: {
                color: theme.colors.darkBlue,
                fontSize: 25,
                fontFamily: "Poppins",
                fontWeight: "bold",
                marginLeft: 40,
                marginTop: 30,
              },
            },
            {
              component: "container",
              style: { width: "100%", marginTop: 30 },
              children: [
                {
                  render: (
                    <DataGrid
                      disableSelectionOnClick
                      pagination={false}
                      autoHeight
                      rows={generateRows()}
                      columns={columns}
                      rowHeight={60}
                    />
                  ),
                },
              ],
            },
            {
              render: (
                <div
                  style={{
                    display: popUp ? "flex" : "none",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      boxShadow: "0 0 100px 100px rgba(0, 0, 0, 0.5)",
                      backgroundColor: "#fff",
                      borderRadius: 10,
                      width: 750,
                      zIndex: 9,
                    }}
                  >
                    <div
                      style={{
                        height: 50,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        margin: 20,
                      }}
                    >
                      <h2
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          fontSize: 24,
                        }}
                      >
                        Selecione o novo horário que deseja sugerir
                      </h2>
                      <div
                        onClick={() => setPopUp(false)}
                        style={{
                          position: "relative",
                          top: -2,
                          backgroundColor: Theme.colors.pink2,
                          width: 35,
                          height: 35,
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <CloseIcon
                          style={{
                            fontSize: 28,
                            color: "#fff",
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        flexWrap: "wrap",
                        margin: "0 10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {schedule.map((hour, index) => {
                        if (hour.enable) {
                          return (
                            <button
                              onClick={() => {
                                if (selectedHours.includes(hour.label)) {
                                  var filtered = selectedHours.filter(
                                    (value) => value != hour.label
                                  );
                                  setSelectedHours(filtered);
                                } else if (
                                  !selectedHours.includes(hour.label)
                                ) {
                                  setSelectedHours([
                                    ...selectedHours,
                                    hour.label,
                                  ]);
                                }
                              }}
                              key={index}
                              style={{
                                width: 100,
                                height: 48,
                                padding: 10,
                                border: selectedHours.includes(hour.label)
                                  ? "none"
                                  : "1px solid #C5DBEC",
                                borderRadius: 8,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                margin: 5,
                                color: selectedHours.includes(hour.label)
                                  ? "#ABABAB"
                                  : "#011627",
                                fontFamily: "Poppins",
                                fontSize: 16,
                                cursor: "pointer",
                                backgroundColor: selectedHours.includes(
                                  hour.label
                                )
                                  ? "#E0E0E0"
                                  : "transparent",
                              }}
                            >
                              {hour.label}
                            </button>
                          );
                        }
                      })}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "50px 0 20px",
                      }}
                    >
                      <button
                        disabled={selectedHours.length > 0}
                        onClick={async () => {
                          setPopUp(false);
                          await api.provider.suggestTime({
                            order: selectedRow.row.data._id,
                            suggested_time: selectedHours,
                          });
                        }}
                        style={{
                          fontFamily: "Poppins",
                          fontSize: 16,
                          fontWeight: "bold",
                          backgroundColor:
                            selectedHours.length > 0
                              ? Theme.colors.pink2
                              : "#bfbfbf",
                          border: "none",
                          color: "#fff",
                          width: 250,
                          height: 50,
                          borderRadius: 12,
                          cursor: "pointer",
                        }}
                      >
                        Sugerir horário
                      </button>
                    </div>
                  </div>
                </div>
              ),
            },
            {
              render: (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100vh",
                    display: confirmPopup ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      padding: "25px",
                      width: "100%",
                      height: 110,
                      maxWidth: 350,
                      backgroundColor: "#fff",
                      borderRadius: 20,
                    }}
                  >
                    <h1
                      style={{
                        fontFamily: "Poppins",
                      }}
                    >
                      Quer mesmo confirmar o pedido?
                    </h1>
                    <div
                      type="button"
                      style={{
                        textAlign: "center",
                        fontFamily: "Poppins",
                        padding: 5,
                        height: 33,
                        position: "absolute",
                        borderRadius: 5,
                        backgroundColor: "#76CB75",
                        bottom: 18,
                        left: 22,
                        cursor: "pointer",
                        color: "#fff",
                      }}
                      onClick={async () => {
                        try {
                          await api.provider.confirmPendingSells({
                            order: selectedRow.id,
                          });
                        } catch {}
                        setConfirmPopup(false);
                      }}
                    >
                      Confirmar
                    </div>
                    <div
                      type="button"
                      style={{
                        textAlign: "center",
                        fontFamily: "Poppins",
                        padding: 5,
                        height: 33,
                        position: "absolute",
                        borderRadius: 5,
                        backgroundColor: theme.colors.pink2,
                        bottom: 18,
                        right: 22,
                        cursor: "pointer",
                        color: "#fff",
                      }}
                      onClick={() => setConfirmPopup(false)}
                    >
                      Cancelar
                    </div>
                  </div>
                </div>
              ),
            },
            {
              render: (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100vh",
                    display: cancelPopup ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      padding: "25px",
                      width: "100%",
                      height: 110,
                      maxWidth: 350,
                      backgroundColor: "#fff",
                      borderRadius: 20,
                    }}
                  >
                    <h1
                      style={{
                        fontFamily: "Poppins",
                      }}
                    >
                      Quer mesmo cancelar o pedido?
                    </h1>
                    <div
                      type="button"
                      style={{
                        textAlign: "center",
                        fontFamily: "Poppins",
                        padding: 5,
                        height: 33,
                        position: "absolute",
                        borderRadius: 5,
                        backgroundColor: "#76CB75",
                        bottom: 18,
                        left: 22,
                        cursor: "pointer",
                        color: "#fff",
                      }}
                      onClick={async () => {
                        try {
                          await api.provider.cancelOrder({
                            order: selectedRow.id,
                          });
                        } catch {}
                        setCancelPopup(false);
                      }}
                    >
                      Confirmar
                    </div>
                    <div
                      type="button"
                      style={{
                        textAlign: "center",
                        fontFamily: "Poppins",
                        padding: 5,
                        height: 33,
                        position: "absolute",
                        borderRadius: 5,
                        backgroundColor: theme.colors.pink2,
                        bottom: 18,
                        right: 22,
                        cursor: "pointer",
                        color: "#fff",
                      }}
                      onClick={() => setCancelPopup(false)}
                    >
                      Cancelar
                    </div>
                  </div>
                </div>
              ),
            },
          ],
        },
      ],
    };
  } else {
    return {
      component: "container",
    };
  }
}

export default OrderControl;
