import http, { Axios } from "axios";
import axios from "axios";

let baseURL = process.env.REACT_APP_API_URL + "/api";
let CEP_BASE_URL = "http://cep.la/";

let publicCEP = http.create({
  baseURL: CEP_BASE_URL,
  headers: {
    Accept: "application/json",
  },
});

let publicHttp = http.create({
  baseURL,
});
let privateHttp = http.create({
  baseURL,
});

const Req = {
  Post: async ({ path, data, query, headers }) =>
    axios
      .post(baseURL + path + (query ? "?" + query : ""), data, { headers })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response?.data) return error.response.data;
        return error;
      }),
  Get: async ({ path, data, query, headers }) =>
    axios
      .post(baseURL + path + "?" + query, data, { headers })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response?.data) return error.response.data;
        return error;
      }),
};

export { publicHttp, privateHttp, publicCEP, Req, baseURL };
