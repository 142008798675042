import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";

function AlertPopUp(props) {
  useEffect(() => {
    if (props.show == true && props.type == "snack") {
      setTimeout(() => {
        props.setTrigger(false);
        if (props.callback) {
          props.callback();
        }
      }, props.time);
    }
  }, [props.show]);

  if (props.type == "popup" || !props.type) {
    return (
      <div
        style={{
          zIndex: 9999,
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100vh",
          display: props.show ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        }}
      >
        <div
          style={{
            display: "flex",
            position: "fixed",
            top:
              props.position == "top" ||
              props.position == "top-left" ||
              props.position == "top-right"
                ? 70
                : "unset",
            bottom:
              props.position == "bottom" ||
              props.position == "bottom-left" ||
              props.position == "bottom-right"
                ? 70
                : props.position == "top" ||
                  props.position == "top-left" ||
                  props.position == "top-right"
                ? "none"
                : "50%",
            left:
              props.position == "left" ||
              props.position == "top-left" ||
              props.position == "bottom-left"
                ? 30
                : "unset",
            right:
              props.position == "right" ||
              props.position == "top-right" ||
              props.position == "bottom-right"
                ? 30
                : "unset",
            padding: "32px",
            fontFamily: "Poppins",
            width: "auto",
            minWidth: 300,
            maxWidth: 500,
            height: "auto",
            minHeight: 22,
            padding: 15,
            borderRadius: 10,
            backgroundColor: props.background ? props.background : "#363165",
            color: props.color ? props.color : "#fff",
          }}
        >
          <div
            style={{
              display: props.icon ? "flex" : "none",
              flex: props.icon ? 0.5 : 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                maxHeight: 40,
              }}
              src={props.icon ? props.icon : ""}
            />
          </div>
          <div
            style={{
              flex: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <p style={{ fontSize: 18, fontWeight: 900, marginBottom: 5 }}>
              {props.title ? props.title : ""}
            </p>
            <p
              style={{
                fontSize: 16,
                textOverflow: "wrap",
                wordBreak: "break-word",
              }}
            >
              {props.message ? props.message : "Message"}
            </p>
          </div>
          <ClearIcon
            style={{
              color: "#787A91",
              position: "absolute",
              top: props.title ? 10 : 5,
              right: props.title ? 10 : 7,
              cursor: "pointer",
            }}
            onClick={() => {
              props.setTrigger(false);
              if (props.callback) {
                props.callback();
              }
            }}
          />
        </div>
      </div>
    );
  } else if (props.type == "snack") {
    return (
      <div
        style={{
          display: props.show ? "flex" : "none",
          fontFamily: "Poppins",
          zIndex: 9999,
          position: "fixed",
          top:
            props.position == "top" ||
            props.position == "top-left" ||
            props.position == "top-right"
              ? 70
              : "unset",
          bottom: props.position
            ? props.position == "bottom" ||
              props.position == "bottom-left" ||
              props.position == "bottom-right"
              ? 70
              : props.position == "top" ||
                props.position == "top-left" ||
                props.position == "top-right"
              ? "none"
              : "50%"
            : 70,
          left: props.position
            ? props.position == "left" ||
              props.position == "top-left" ||
              props.position == "bottom-left"
              ? 30
              : "unset"
            : 30,
          right:
            props.position == "right" ||
            props.position == "top-right" ||
            props.position == "bottom-right"
              ? 30
              : "unset",
          width: "auto",
          minWidth: 200,
          height: "auto",
          padding: 10,
          borderRadius: 10,
          backgroundColor: props.background ? "rgba(150,150,150,0.4)" : "#000",
          color: props.color ? "rgba(50,50,50,1)" : "#fff",
        }}
      >
        <div
          style={{
            display: props.icon ? "flex" : "none",
            flex: props.icon ? 1 : 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              maxHeight: 40,
            }}
            src={props.icon ? props.icon : ""}
          />
        </div>
        <div
          style={{
            flex: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p style={{ fontSize: 18, fontWeight: 900 }}>
            {props.title ? props.title : ""}
          </p>
          <p style={{ fontSize: 16, marginTop: 8 }}>
            {props.message ? props.message : "Message"}
          </p>
        </div>
      </div>
    );
  }
}

export default AlertPopUp;
