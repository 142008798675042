import React, { useEffect, useState } from "react";
import { Render } from "@8_dev/sjson-web";
import Theme from "../../theme";
import Navbar from "../../components/Navbar";
import { useActive } from "../../context/activeContext";
import Switch from "@mui/material/Switch";
import api from "../../service";

const label = { inputProps: { "aria-label": "Switch demo" } };

let ProviderSchedule = () => {
  const [isActive, setIsActive] = useState(false);

  const [pageData, setPageData] = useState({
    daycare: {
      requirements: {
        absence_contagious_diseases: false,
        vaccination_card: false,
        females_cannot_heat: false,
        castrated_males: false,
        stool_exam: false,
        not_accept_puppies: false,
      },
      enabled: false,
    },
  });

  const { active, updateActive } = useActive();

  useEffect(() => {
    (async () => {
      let { data } = await api.provider.getUser();
      if (data.data.info.daycare?.requirements)
        setPageData({ daycare: data.data.info.daycare });
    })();
  }, []);

  useEffect(() => {
    updateActive(5);
  }, []);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  // fazer for e pegar do banco...
  // Carteira de vacinação em dia ( polivalente e anti-rábica )

  return Render({
    component: "container",
    style: {
      display: "flex",
      flexDirection: "column",
    },
    children: [
      Navbar({
        isAdmin: false,
        context: "provider",
        active: 5,
      }),
      {
        component: "container",
        style: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        },
        children: [
          {
            component: "container",
            style: {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              marginTop: "2%",
              padding: "2%",
              backgroundColor: "rgba(155,155,155,0.1)",
              width: "70%",
            },
            children: [
              {
                component: "container",
                style: {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(155,155,155,0.1)",
                  borderRadius: 10,
                  padding: "2%",
                  width: "100%",
                },

                children: [
                  {
                    render: (
                      <div>
                        <p
                          style={{
                            fontSize: 16,
                            fontFamily: "Poppins",
                            fontWeight: "bold",
                            color: "rgba(0,0,0,0.75)",
                            width: "100%",
                            textAlign: "center",
                            marginBottom: 20,
                          }}
                        >
                          Configure abaixo os requisitos necessários para
                          recebimento de pets no seu estabelecimento.
                        </p>
                      </div>
                    ),
                  },
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    },

                    children: [
                      {
                        component: "container",
                        style: {
                          marginRight: "1%",
                          display: "flex",
                          flexDirection: "column",
                        },
                        children: [
                          {
                            render: (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  flex: 1,
                                }}
                              >
                                <Switch
                                  checked={
                                    pageData.daycare.requirements
                                      .absence_contagious_diseases
                                  }
                                  value={
                                    pageData.daycare.requirements
                                      .absence_contagious_diseases
                                  }
                                  onChange={async () => {
                                    api.provider.categoryDaycare({
                                      ...pageData.daycare,
                                      requirements: {
                                        ...pageData.daycare.requirements,
                                        absence_contagious_diseases:
                                          !pageData.daycare.requirements
                                            .absence_contagious_diseases,
                                      },
                                    });
                                    setPageData({
                                      ...pageData,
                                      daycare: {
                                        ...pageData.daycare,
                                        requirements: {
                                          ...pageData.daycare.requirements,
                                          absence_contagious_diseases:
                                            !pageData.daycare.requirements
                                              .absence_contagious_diseases,
                                        },
                                      },
                                    });
                                  }}
                                  size="medium"
                                />
                                <p
                                  style={{
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  Ausência de doenças contagiosas
                                </p>
                              </div>
                            ),
                          },
                          {
                            render: (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  flex: 1,
                                }}
                              >
                                <Switch
                                  checked={
                                    pageData.daycare.requirements
                                      .vaccination_card
                                  }
                                  value={
                                    pageData.daycare.requirements
                                      .vaccination_card
                                  }
                                  onChange={async () => {
                                    api.provider.categoryDaycare({
                                      ...pageData.daycare,
                                      requirements: {
                                        ...pageData.daycare.requirements,
                                        vaccination_card:
                                          !pageData.daycare.requirements
                                            .vaccination_card,
                                      },
                                    });
                                    setPageData({
                                      ...pageData,
                                      daycare: {
                                        ...pageData.daycare,
                                        requirements: {
                                          ...pageData.daycare.requirements,
                                          vaccination_card:
                                            !pageData.daycare.requirements
                                              .vaccination_card,
                                        },
                                      },
                                    });
                                  }}
                                  size="medium"
                                />
                                <p
                                  style={{
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                    flex: 1,
                                  }}
                                >
                                  Carteira de vacinação em dia ( polivalente e
                                  anti-rábica )
                                </p>
                              </div>
                            ),
                          },
                          {
                            render: (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Switch
                                  checked={
                                    pageData.daycare.requirements
                                      .females_cannot_heat
                                  }
                                  value={
                                    pageData.daycare.requirements
                                      .females_cannot_heat
                                  }
                                  onChange={async () => {
                                    api.provider.categoryDaycare({
                                      ...pageData.daycare,
                                      requirements: {
                                        ...pageData.daycare.requirements,
                                        females_cannot_heat:
                                          !pageData.daycare.requirements
                                            .females_cannot_heat,
                                      },
                                    });
                                    setPageData({
                                      ...pageData,
                                      daycare: {
                                        ...pageData.daycare,
                                        requirements: {
                                          ...pageData.daycare.requirements,
                                          females_cannot_heat:
                                            !pageData.daycare.requirements
                                              .females_cannot_heat,
                                        },
                                      },
                                    });
                                  }}
                                  size="medium"
                                />
                                <p
                                  style={{
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                    textAlign: "center",
                                  }}
                                >
                                  Fêmeas não podem estar no cio
                                </p>
                              </div>
                            ),
                          },
                        ],
                      },
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        },
                        children: [
                          {
                            render: (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Switch
                                  checked={
                                    pageData.daycare.requirements
                                      .castrated_males
                                  }
                                  value={
                                    pageData.daycare.requirements
                                      .castrated_males
                                  }
                                  onChange={async () => {
                                    api.provider.categoryDaycare({
                                      ...pageData.daycare,
                                      requirements: {
                                        ...pageData.daycare.requirements,
                                        castrated_males:
                                          !pageData.daycare.requirements
                                            .castrated_males,
                                      },
                                    });
                                    setPageData({
                                      ...pageData,
                                      daycare: {
                                        ...pageData.daycare,
                                        requirements: {
                                          ...pageData.daycare.requirements,
                                          castrated_males:
                                            !pageData.daycare.requirements
                                              .castrated_males,
                                        },
                                      },
                                    });
                                  }}
                                  size="medium"
                                />
                                <p
                                  style={{
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  Machos castrados
                                </p>
                              </div>
                            ),
                          },
                          {
                            render: (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Switch
                                  checked={
                                    pageData.daycare.requirements.stool_exam
                                  }
                                  value={
                                    pageData.daycare.requirements.stool_exam
                                  }
                                  onChange={async () => {
                                    api.provider.categoryDaycare({
                                      ...pageData.daycare,
                                      requirements: {
                                        ...pageData.daycare.requirements,
                                        stool_exam:
                                          !pageData.daycare.requirements
                                            .stool_exam,
                                      },
                                    });
                                    setPageData({
                                      ...pageData,
                                      daycare: {
                                        ...pageData.daycare,
                                        requirements: {
                                          ...pageData.daycare.requirements,
                                          stool_exam:
                                            !pageData.daycare.requirements
                                              .stool_exam,
                                        },
                                      },
                                    });
                                  }}
                                  size="medium"
                                />
                                <p
                                  style={{
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  Exame de fezes (giárdia)
                                </p>
                              </div>
                            ),
                          },
                          {
                            render: (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Switch
                                  checked={
                                    pageData.daycare.requirements
                                      .not_accept_puppies
                                  }
                                  value={
                                    pageData.daycare.requirements
                                      .not_accept_puppies
                                  }
                                  onChange={async () => {
                                    api.provider.categoryDaycare({
                                      ...pageData.daycare,
                                      requirements: {
                                        ...pageData.daycare.requirements,
                                        not_accept_puppies:
                                          !pageData.daycare.requirements
                                            .not_accept_puppies,
                                      },
                                    });
                                    setPageData({
                                      ...pageData,
                                      daycare: {
                                        ...pageData.daycare,
                                        requirements: {
                                          ...pageData.daycare.requirements,
                                          not_accept_puppies:
                                            !pageData.daycare.requirements
                                              .not_accept_puppies,
                                        },
                                      },
                                    });
                                  }}
                                  size="medium"
                                />
                                <p
                                  style={{
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  Não aceitam filhotes (6 meses)
                                </p>
                              </div>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              // {
              //   component: "container",
              //   style: {
              //     display: "flex",
              //     width: "100%",
              //     alignItems: "center",
              //     justifyContent: "center",
              //     backgroundColor: "rgba(155,155,155,0.1)",
              //     borderRadius: 10,
              //     padding: "2%",
              //     marginTop: 20,
              //     marginBottom: 100,
              //   },
              //   children: [
              //     {
              //       component: "container",
              //       style: {
              //         width: "100%",
              //         alignItems: "center",
              //         justifyContent: "center",
              //       },
              //       children: [
              //         {
              //           render: (
              //             <div
              //               style={{
              //                 display: "flex",
              //                 flex: 1,
              //                 flexDirection: "column",
              //               }}
              //             >
              //               <div
              //                 style={{
              //                   width: "100%",
              //                   display: "flex",
              //                   justifyContent: "flex-start",
              //                   alignItems: "center",
              //                   flexDirection: "column",
              //                 }}
              //               >
              //                 <p
              //                   style={{
              //                     fontSize: 30,
              //                     width: "100%",
              //                     fontFamily: "Poppins",
              //                     fontWeight: "bold",
              //                     marginBottom: "3%",
              //                   }}
              //                 >

              //                 </p>
              //                 <p
              //                   style={{
              //                     fontSize: 20,
              //                     fontFamily: "Poppins",
              //                     textAlign: "center",
              //                   }}
              //                 >

              //                 </p>
              //               </div>
              //               <div
              //                 style={{
              //                   width: "100%",
              //                   display: "flex",
              //                   flexDirection: "column",
              //                   justifyContent: "center",
              //                   alignItems: "center",
              //                   margin: "20px 0",
              //                 }}
              //               >
              //                 <Switch
              //                   checked={pageData.daycare.enabled}
              //                   value={pageData.daycare.enabled}
              //                   onChange={async () => {
              //                     api.provider.categoryDaycare({ ...pageData.daycare, enabled: !pageData.daycare.enabled });
              //                     setPageData({...pageData, daycare: { ...pageData.daycare, enabled: !pageData.daycare.enabled } } )}
              //                   }
              //                   size="medium"
              //                   {...label}
              //                 />
              //                 <p
              //                   style={{
              //                     fontSize: 20,
              //                     fontFamily: "Poppins",
              //                     width: 1100,
              //                     textAlign: "center",
              //                   }}
              //                 >
              //                   {isActive ? "Serviço ativo" : "Serviço inativo"}
              //                 </p>
              //               </div>
              //             </div>
              //           ),
              //         },
              //       ],
              //     },
              //   ],
              // },
            ],
          },
        ],
      },
    ],
  });
};

export default ProviderSchedule;
