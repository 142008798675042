import React, { useEffect, useState } from "react";
import { Render } from "@8_dev/sjson-web";
import Theme from "../../theme";
import Navbar from "../../components/Navbar";
import FormRegisterService from "../../components/FormRegisterService";
import { useActive } from "../../context/activeContext";
import service from "../../service";

let ProviderSchedule = () => {
  const { active, updateActive } = useActive();
  useEffect(() => {
    updateActive(3);
  }, []);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return Render({
    component: "container",
    style: {
      width: "100vw",
      display: "flex",
      flexDirection: "column",
    },
    children: [
      Navbar({
        isAdmin: false,
        context: "provider",
        active: 3,
      }),
      {
        component: "container",
        style: {
          width: "100%",
          minHeight: "100vh",
        },
        children: [
          {
            render: <FormRegisterService />,
          },
        ],
      },
    ],
  });
};

export default ProviderSchedule;
