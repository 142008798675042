import React from "react";
import { Render } from "@8_dev/sjson-web";
import Theme from "../../theme";
import Navbar from "../../components/Navbar";

import FormProvider from "../../components/FormRegisterAdmin";
import { useActive } from "../../context/activeContext";
import FormBusiness from "../../components/FormBusiness";
import OrderControl from "../../components/OrderControl";
import AlertPopUp from "../../components/AlertPopUp";
import { useAlert } from "../../context/alertContext";

import homeAdmin from "../../pages/admin";

let DashboardAdmin = () => {
  const { setErrorPopUp, errorPopUp, alertMsg, alertTitle } = useAlert();
  const { active } = useActive();
  return Render({
    component: "container",
    style: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      // backgroundColor: "rgba(100,100,100,1)",
    },
    children: [
      Navbar({
        isAdmin: true,
        context: "admin",
      }),
      // FormProvider({ show: active === 1 }),
      FormProvider({ show: active === 1 }),
      FormBusiness({ show: active === 2 }),
      OrderControl({ show: active === 3 }),
    ],
  });
};

export default DashboardAdmin;
