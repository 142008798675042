const theme = {
  colors: {
    pink1: "#EF1959",
    pink2: "#FF5876",
    pink3: "#FEA6B6",
    pink4: "linear-gradient(180deg, #EF1959 0%, #FF5876 100%)",
    darkBlue: "#011627",
    lightBlue: "#113B5E",
    backgroundBlue: "#F7F7FC",
    white: "#fff",
    darkGreen: "#2EC4B6",
    lightGreen: "#7DE2D9",
    darkGrey: "#3C3C3C",
    lightGrey: "#ABABAB",
    yesGreen: "#2EC461",
    noRed: "#F84040",
  },
  elevations: {
    1: "0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16)",
    2: "0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)",
    3: "0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16)",
    4: "0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)",
    5: "0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16)",
    6: "0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24)",
  },
  fontSizes: {
    tiny: "10",
    small: "12",
    capitalized: "14",
    blockquotes: "20",
    body14: "14",
    body16: "16",
    lead: "14",
  },
  padding: {
    small: 5,
    normal: 10,
    medium: 15,
    big: 35,
  },
  margin: {
    small: 5,
    normal: 10,
    medium: 15,
    big: 35,
  },
};

export default theme;
