import "dotenv";
import { createContext, useEffect, useRef, useState } from "react";
import Core from "@8_dev/front_web_lbry";
import { useAlert } from "./alertContext";

export const SocketContext = createContext();

export default function SocketProvider({ children }) {
  const {
    errorPopUp,
    setErrorPopUp,
    setAlertMsg,
    setAlertTitle,
    setSnackMsg,
    setSnackTitle,
    setSnackAlert,
    setCloseTime,
  } = useAlert();
  const apiURL = process.env.REACT_APP_API_URL;

  const socket = useRef(null);
  const [connected, setConnected] = useState(false);

  const handleEmitMessage = async (action, info) => {
    let token = localStorage.getItem("token");

    if (!token) return;

    socket.current.emit("message", {
      action,
      token,
      info,
    });
  };

  const handleEvents = (action, fn) => {
    socket.current.on(`_message@${action}`, fn);
  };

  const connectWebsocket = async () => {
    socket.current = await Core.module.server.socket.connect(apiURL);

    socket.current.on("connect", () => {
      setConnected(true);

      let token = localStorage.getItem("token");

      if (!token) return;

      socket.current.emit("message", {
        action: "auth",
        info: {
          token: token,
        },
      });
    });

    socket.current.on("disconnect", async () => {
      console.log("Socket disconnected");
    });

    handleEvents("auth/signin", async (data) => {});
    handleEvents("notify", async (data) => {
      setSnackMsg(data.info.msg);
      setSnackTitle(data.info.title);
      setCloseTime(data.info.time);
      setSnackAlert(true);
    });
    handleEvents("alert", async (data) => {
      setAlertMsg(data.info.msg);
      setAlertTitle(data.info.title);
      setErrorPopUp(true);
    });
  };

  useEffect(() => {
    connectWebsocket();

    return () => {
      socket.current.close();
    };
  }, []);

  return (
    <SocketContext.Provider
      value={{
        socket,
        handleEmitMessage,
        handleEvents,
        connected,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}
