import React from "react";
import Theme from "../../theme";
import api from "../../service";
import DeliveryLogo from "../../assets/images/deliveryIcon.svg";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

const formatDate = (date, hour) => {
  if (hour.includes(":")) hour = hour.split(":")[0];
  let eventDate = new Date(date);
  eventDate = new Date(
    eventDate.getUTCFullYear(),
    eventDate.getUTCMonth(),
    eventDate.getUTCDate(),
    hour,
    0,
    0
  );
  return (
    new Intl.DateTimeFormat("pt-br", {
      weekday: "long",
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    }).format(eventDate) +
    " às " +
    new Intl.DateTimeFormat("pt-br", {
      hour: "numeric",
      minute: "numeric",
    }).format(eventDate)
  );
};

function PetWithdrawal(order) {
  let showAddress = false;

  return (
    <div
      style={{
        backgroundColor: "#fff",
        border: "1px solid #E0E0E0",
        borderRadius: 10,
        width: "90%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        paddingBottom: 20,
        marginTop: "2%",
      }}
    >
      <div
        style={{
          width: "100%",
          height: 12,
          borderRadius: "8px 8px 0 0",
          position: "relative",
          top: 0,
          backgroundColor: "#004FC6",
        }}
      />
      <div
        style={{
          width: "100%",
          margin: "30px 0 10px",
          display: "flex",
          height: 70,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#004FC6",
            height: 20,
            width: 20,
            borderRadius: "50%",
          }}
        />
        <div
          style={{
            backgroundColor: "#004FC6",
            height: 2,
            width: "20%",
          }}
        />
        <div
          style={{
            backgroundColor: "#004FC6",
            height: 20,
            width: 20,
            borderRadius: "50%",
          }}
        ></div>
        <div
          style={{
            backgroundColor: "#004FC6",
            height: 2,
            width: "20%",
          }}
        />
        <div
          style={{
            backgroundColor: "#004FC6",
            height: 20,
            width: 20,
            borderRadius: "50%",
          }}
        />
        <div
          style={{
            backgroundColor: "#004FC6",
            height: 2,
            width: "20%",
          }}
        />
        <div
          style={{
            backgroundColor: "#004FC6",
            height: 20,
            width: 20,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              display: "flex",
              fontFamily: "Poppins",
              position: "relative",
              whiteSpace: "nowrap",
              top: -30,
            }}
          >
            Pedido {order.order.id} - Aguardando a retirada do Pet
          </p>
        </div>
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <p
            style={{
              fontFamily: "Poppins",
              fontSize: 16,
              marginBottom: 20,
              marginLeft: 60,
            }}
          >
            Serviços:
          </p>
          <div
            style={{
              maxHeight: 300,
              width: 350,
              overflow: "auto",
              direction: "rtl",
            }}
          >
            {order.order.delivery_service && (
              <div
                style={{
                  background: "#fafafa",
                  width: 300,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px 0px 10px 20px",
                  textAlign: "left",
                  borderRadius: 20,
                  marginBottom: 20,
                }}
              >
                <div style={{ display: "flex", marginBottom: 7 }}>
                  {/* <div
                    style={{
                      width: 50,
                      height: 50,
                      marginRight: 5,
                      backgroundColor: "red",
                    }}
                  /> */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      height: 50,
                      marginRight: 20,
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 600,
                        fontFamily: "Poppins",
                        fontSize: 16,
                      }}
                    >
                      Serviço de busca
                    </p>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 12,
                        marginTop: 2,

                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flext-start",
                        textAlign: "left",
                      }}
                    >
                      <p>
                        {order.order.address.street},{" "}
                        {order.order.address.number}{" "}
                      </p>
                      <p>
                        {order.order.address.district} -{" "}
                        {order.order.address.complement}
                      </p>
                      Hora: {console.log(new Date(order.order.delivery_date))}
                      {new Date(order.order.delivery_date).toLocaleTimeString(
                        "pt-BR",
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                          timeZone: "America/Sao_Paulo",
                        }
                      )}{" "}
                      - {order.order.hour}:00
                    </p>
                  </div>

                  <img
                    src={api.utils.getImage("delivery")}
                    style={{
                      width: 50,
                      height: 50,
                      marginRight: 10,
                    }}
                    onClick={() => console.log(order)}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <p
                    style={{
                      fontWeight: 700,
                      fontFamily: "Poppins",
                      fontSize: 22,
                    }}
                  >
                    R${order.order.delivery_service_value}
                  </p>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 20,
                      marginRight: 5,
                    }}
                  ></p>
                </div>
              </div>
            )}
            {order.order.services.map((service) => {
              if (
                service.service.category.code === 5 ||
                service.service.category.code === 6
              )
                showAddress = true;

              return (
                <div
                  style={{
                    background: "#fafafa",
                    width: 300,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px 0px 10px 20px",
                    textAlign: "left",
                    borderRadius: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ display: "flex", marginBottom: 7 }}>
                    {/* <div
                    style={{
                      width: 50,
                      height: 50,
                      marginRight: 5,
                      backgroundColor: "red",
                    }}
                  /> */}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginRight: 20,
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 600,
                          fontFamily: "Poppins",
                          fontSize: 16,
                        }}
                      >
                        {service.service.category.code == 4
                          ? service.service.category.name
                          : service.service.sub_category.name}
                      </p>
                      <p
                        style={{
                          fontFamily: "Poppins",
                          fontSize: 12,
                          marginTop: 10,
                        }}
                      >
                        {" "}
                        {service.description != ""
                          ? service.description
                          : "Sem mais informações"}
                      </p>
                    </div>
                    <img
                      src={service?.service?.category?.image}
                      style={{
                        width: 50,
                        height: 50,
                        marginRight: 10,
                      }}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <p
                      style={{
                        fontWeight: 700,
                        fontFamily: "Poppins",
                        fontSize: 22,
                      }}
                    >
                      R${" "}
                      {service.value.toString().includes(".") &&
                      service.value != 0
                        ? service.value.toString().split(".").length >= 2
                          ? service.value.toString().replace(".", ",")
                          : `${service.value.toString().replace(".", ",")}0`
                        : `${service.value},00`}
                    </p>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 20,
                        marginRight: 5,
                      }}
                    ></p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 3,
            marginLeft: 20,
          }}
        >
          <p
            style={{
              fontFamily: "Poppins",
              fontSize: 16,
              marginBottom: 20,
            }}
          >
            Detalhes:
          </p>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p
              style={{
                fontSize: 15,
                fontWeight: 600,
                fontFamily: "Poppins",
                marginTop: 20,
              }}
            >
              Cliente: {order.order.people.name}
            </p>
            <p
              style={{
                fontSize: 15,
                fontWeight: 600,
                fontFamily: "Poppins",
                marginTop: 20,
              }}
            >
              Data:{" "}
              {format(order.order.service_date, "EEEE, dd MMMM", {
                locale: ptBR,
                timeZone: "America/Sao_Paulo",
              })}{" "}
              às {order.order.hour}
            </p>
            <p
              style={{
                fontSize: 15,
                fontWeight: 600,
                fontFamily: "Poppins",
                marginTop: 20,
              }}
            >
              PET:
              <span> {order.order.pet.name}</span>
              {", "}
              {order.order.pet.breed.name
                ? order.order.pet.breed.name
                : "Sem raça definida"}
              , porte {order?.order?.pet?.size?.initials}
            </p>

            <div
              style={{
                display: showAddress ? "flex" : "none",
                justifyContent: "start",
                flexDirection: "row",
                alignItems: "start",
                marginTop: "1%",
              }}
            >
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: 14,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flext-start",
                }}
              >
                <p>
                  {order.order.address.street}, {order.order.address.number}{" "}
                </p>
                <p>
                  {order.order.address.district}{" "}
                  {order.order.address.complement}
                </p>
              </p>
            </div>

            <p
              style={{
                fontSize: 15,
                fontWeight: 600,
                fontFamily: "Poppins",
                marginTop: 20,
              }}
            >
              Total: R$
              {order.order.service_value + order.order.delivery_service_value}
            </p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "0 30px 30px 0",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: 10,
              height: 10,
            }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <button
              onClick={async () => {
                await api.provider.finalizeOrder({
                  order: order.order._id,
                });
              }}
              style={{
                fontFamily: "Poppins",
                fontSize: 16,
                fontWeight: "bold",
                backgroundColor: "#004FC6",
                border: "none",
                color: "#fff",
                width: 300,
                height: 55,
                borderRadius: 12,
                cursor: "pointer",
              }}
            >
              O pet já foi!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PetWithdrawal;
