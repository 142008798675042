import React, { useEffect, useState } from "react";
import theme from "../../theme";
import { useActive } from "../../context/activeContext";
import api from "../../service";
import { Render } from "@8_dev/sjson-web";
import { useAuth } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import AlertPopUp from "../../components/AlertPopUp";
import Navbar from "../../components/Navbar";
import { useAlert } from "../../context/alertContext";
import InputMask from "react-input-mask";
import FolderImage from "../../assets/images/folder.png";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";

const inputMask = (mask) => {
  // render();
  // <InputMask
  //   mask // "99.999.999/9999-99"
  //   value={newCad.code}
  //   onChange={(e) =>}
  //   onBlur={(e) => {
  //     if (
  //       newCad.code
  //         .replaceAll(".", "")
  //         .replaceAll("/", "")
  //         .replaceAll("-", "")
  //         .replaceAll("_", "").length > 13
  //     ) {
  //       setInputError(0);
  //       setReplacedCode(
  //         newCad.code
  //           .replaceAll(".", "")
  //           .replaceAll("/", "")
  //           .replaceAll("-", "")
  //       );
  //     } else {
  //       setInputError(2);
  //     }
  //   }}
  // >
  //   {() => (
  //     <input
  //       style={{
  //         height: 50,
  //         width: "70%",
  //         backgroundColor: "#EFF0F7",
  //         border:
  //           inputError == 2 ? `1px solid ${theme.colors.pink2}` : "none",
  //         borderRadius: 16,
  //         padding: "0 20px",
  //         fontFamily: "Poppins",
  //         fontSize: 16,
  //         marginTop: 8,
  //       }}
  //       placeholder="Digite aqui"
  //     />
  //   )}
  // </InputMask>
};

let Page = () => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (e) => {
      for (let file of e) {
        if (e.length && e[0].name.split(".")[1] === "xlsx") {
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet);
            setNewCad({ ...newCad, file: json });
          };
          reader.readAsArrayBuffer(file);
          setInputFileLabel(
            " 🆗 [ " +
              file.name.split(".")[0] +
              " ] " +
              newCad.file.length +
              newCad.file.length ===
              1
              ? " colaborador encontrado!"
              : " colaboradores encontrados!"
          );
        } else {
          setInputFileLabel(" 😕 Arquivo inválido, seleciona outro.");
        }
      }
    },
  });

  const [addPerson, setAddPerson] = useState({
    nome: "",
    cpf: "",
    telefone: "",
    email: "",
  });
  const [showInfo, setShowInfo] = useState(false);
  const [infoLines, setInfoLines] = useState([]);
  const [pageView, setPageView] = useState(1);
  const [inputError, setInputError] = useState(0);
  const { active, updateActive } = useActive();
  const [popup, setPopup] = useState(false);
  const [replacedAdressCode, setReplacedAdressCode] = useState("");
  const { errorPopUp, setErrorPopUp, alertMsg, setAlertMsg, setAlertTitle } =
    useAlert();
  const [inputFileLabel, setInputFileLabel] = useState(
    "Arraste ou clique para fazer o upload"
  );
  const [alertMessage, setAlertMessage] = useState("");
  const [replacedCode, setReplacedCode] = useState("");
  const [newCad, setNewCad] = useState({
    nickname: "",
    name: "",
    code: "",
    email: "",
    database: "",
    password: "",
    address: {
      code: "",
      street: "",
      number: "",
      district: "",
      city: "",
      state: "",
      country: "",
      complement: "",
    },
    file: [],
  });

  const validateEmail = (email) => {
    let validation = /[a-zA-Z0-9]+@[a-zA-Z0-9.-]+\.[a-z]{2,8}(\.[a-z]{2,8})?/;
    if (validation.test(email)) {
      return true;
    } else {
      return false;
    }
  };

  return Render({
    component: "container",
    style: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      backgroundColor: "#F7F7FC",
    },
    children: [
      //navbar
      Navbar({
        isAdmin: false,
        context: "business",
      }),
      // Show Info
      {
        component: "container",
        style: {
          display: showInfo ? "flex" : "None",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: "1%",
          marginTop: "2%",
          borderRadius: 5,
          borderWidth: 20,
          border: "1px solid",
          borderColor: "rgba(255,0,0,0.4)",
          backgroundColor: "rgba(255,255,255,1)",
        },
        children: [
          {
            component: "container",
            style: {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              borderColor: "rgba(100,100,100,0.6)",
              padding: 5,
            },
            children: [
              //Text
              {
                component: "text",
                text:
                  pageView === 1
                    ? "Seu último upload contêm erros"
                    : "Cadastro inválido",
                style: {
                  color: "rgba(255,0,0,1)",
                  fontSize: 16,
                  fontFamily: "Poppins",
                  marginBottom: "5%",
                },
              },
              // Error Lines
              {
                component: "container",
                style: {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  borderColor: "rgba(100,100,100,0.6)",
                  marginTop: "5%",
                },
                children: infoLines,
              },
            ],
          },
        ],
      },
      // content
      {
        component: "container",
        style: {
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: "1%",
          width: "60%",
          borderRadius: 5,
          borderWidth: 20,
          border: "1px solid",
          borderColor: "rgba(100,100,100,0.2)",
          marginTop: "2%",
          paddingBottom: "2%",
          backgroundColor: "rgba(255,255,255,1)",
        },
        children: [
          //menu
          {
            component: "container",
            style: {
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              padding: "1%",
            },
            children: [
              //Text
              {
                component: "text",
                text: "Adicionar colaborador",
                style: {
                  color: "rgba(0,0,0,1)",
                  fontSize: 14,
                  fontWeight: 500,
                  fontFamily: "Poppins",
                },
              },
              // content
              {
                component: "container",
                style: {
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                },
                children: [
                  //menu
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                      width: "100%",
                    },
                    children: [
                      {
                        component: "button",
                        text: "Adicionar por lote",
                        onClick: async (e) => {
                          setPageView(1);
                        },
                        style: {
                          fontFamily: "Poppins",
                          fontSize: 12,
                          fontWeight: pageView === 1 ? "bold" : 300,
                          color:
                            pageView === 1 ? "#000" : "rgba(157, 157, 157, 1)",
                          height: 30,
                          width: "auto",
                          cursor: "pointer",
                          borderRadius: "4px",
                          border: "1px solid",
                          // display: pageView === 1 ? "flex" : "none",
                          backgroundColor:
                            pageView === 1
                              ? "rgba(210, 240, 237, 1)"
                              : "rgba(255, 226, 217, 0)",
                          borderColor:
                            pageView === 1
                              ? "rgba(125, 226, 217, 1)"
                              : "rgba(157, 157, 157, 1)",
                        },
                      },
                      // 0 1 2 3 4 5 6 7 8 9 A B C D E F
                      {
                        component: "button",
                        text: "Adicionar manualmente",
                        onClick: async (e) => {
                          setPageView(0);
                        },
                        style: {
                          marginLeft: 10,
                          fontFamily: "Poppins",
                          fontSize: 12,
                          fontWeight: pageView === 0 ? "bold" : 300,
                          color:
                            pageView === 0 ? "#000" : "rgba(157, 157, 157, 1)",
                          height: 30,
                          width: "auto",
                          cursor: "pointer",
                          borderRadius: "4px",
                          border: "1px solid",
                          // display: pageView === 0 ? "flex" : "none",
                          backgroundColor:
                            pageView === 0
                              ? "rgba(210, 240, 237, 1)"
                              : "rgba(255, 226, 217, 0)",
                          borderColor:
                            pageView === 0
                              ? "rgba(125, 226, 217, 1)"
                              : "rgba(157, 157, 157, 1)",
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          // manual
          {
            component: "container",
            style: {
              display: pageView === 0 ? "flex" : "none",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "flex-start",
              justifyContent: "space-between",
              padding: 5,
              width: "100%",
              marginTop: "5%",
            },
            children: [
              //name
              {
                component: "container",
                style: {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "1%",
                  width: "48%",
                  height: 80,
                },
                children: [
                  //Text
                  {
                    component: "text",
                    text: "Nome Completo",
                    style: {
                      color: "rgba(0,0,0,1)",
                      fontSize: 16,
                      fontFamily: "Poppins",
                    },
                  },
                  //input
                  {
                    component: "input",
                    placeholder: "Digite aqui",
                    value: addPerson.nome,
                    onChange: async (e) => {
                      setAddPerson({ ...addPerson, nome: e.target.value });
                    },
                    onBlur: async (e) => {},
                    style: {
                      marginTop: "5%",
                      width: "100%",
                      height: "100%",
                      borderRadius: 3,
                      color: "rgba(100,100,100,1)",
                      fontSize: 12,
                      fontFamily: "Poppins",
                      borderWidth: 0,
                      backgroundColor: "rgba(100,100,100,0.2)",
                    },
                  },
                ],
              },
              //CPF
              {
                component: "container",
                style: {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: 5,
                  width: "48%",
                  height: 80,
                },
                children: [
                  //Text
                  {
                    component: "text",
                    text: "CPF",
                    style: {
                      color: "rgba(0,0,0,1)",
                      fontSize: 16,
                      fontFamily: "Poppins",
                    },
                  },
                  //input
                  {
                    component: "input",
                    placeholder: "Digite aqui",
                    value: addPerson.cpf,
                    onChange: async (e) => {
                      setAddPerson({ ...addPerson, cpf: e.target.value });
                    },
                    onBlur: async (e) => {},
                    style: {
                      marginTop: "5%",
                      width: "100%",
                      height: "100%",
                      borderRadius: 3,
                      color: "rgba(100,100,100,1)",
                      fontSize: 12,
                      fontFamily: "Poppins",
                      borderWidth: 0,
                      backgroundColor: "rgba(100,100,100,0.2)",
                    },
                  },
                ],
              },
              //Celular
              {
                component: "container",
                style: {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: 5,
                  width: "48%",
                  height: 80,
                  marginTop: "2%",
                },
                children: [
                  //Text
                  {
                    component: "text",
                    text: "Celular",
                    style: {
                      color: "rgba(0,0,0,1)",
                      fontSize: 16,
                      fontFamily: "Poppins",
                    },
                  },
                  //input
                  {
                    component: "input",
                    placeholder: "Digite aqui",
                    value: addPerson.telefone,
                    onChange: async (e) => {
                      setAddPerson({ ...addPerson, telefone: e.target.value });
                    },
                    onBlur: async (e) => {},
                    style: {
                      marginTop: "5%",
                      width: "100%",
                      height: "100%",
                      borderRadius: 3,
                      color: "rgba(100,100,100,1)",
                      fontSize: 12,
                      fontFamily: "Poppins",
                      borderWidth: 0,
                      backgroundColor: "rgba(100,100,100,0.2)",
                    },
                  },
                ],
              },
              //email
              {
                component: "container",
                style: {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: 5,
                  width: "48%",
                  height: 80,
                  marginTop: "2%",
                },
                children: [
                  //Text
                  {
                    component: "text",
                    text: "Email",
                    style: {
                      color: "rgba(0,0,0,1)",
                      fontSize: 16,
                      fontFamily: "Poppins",
                    },
                  },
                  //input
                  {
                    component: "input",
                    placeholder: "Digite aqui",
                    value: addPerson.email,
                    onChange: async (e) => {
                      setAddPerson({ ...addPerson, email: e.target.value });
                    },
                    onBlur: async (e) => {},
                    style: {
                      marginTop: "5%",
                      width: "100%",
                      height: "100%",
                      borderRadius: 3,
                      color: "rgba(100,100,100,1)",
                      fontSize: 12,
                      fontFamily: "Poppins",
                      borderWidth: 0,
                      backgroundColor: "rgba(100,100,100,0.2)",
                    },
                  },
                ],
              },
            ],
          },
          // file-up
          {
            component: "container",
            style: {
              display: pageView === 1 ? "flex" : "none",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "1%",
              width: "100%",
              marginTop: "1%",
            },
            children: [
              //Text
              {
                component: "text",
                text: "Faça o upload da planilha com os funcionários que deseja adicionar.",
                style: {
                  color: "rgba(0,0,0,1)",
                  fontSize: 14,
                  fontWeight: 500,
                  fontFamily: "Poppins",
                },
              },

              {
                component: "button",
                onClick: async () => {
                  api.download.sheetModel("modelo_cadastro.xlsx");
                },
                text: "Baixa aqui o modelo de planilha.",
                style: {
                  color: theme.colors.pink1,
                  fontSize: 14,
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  textDecoration: "underline",
                  marginTop: "2%",
                  cursor: "pointer",
                },
              },
              {
                render: (
                  <div
                    {...getRootProps()}
                    style={{
                      width: "100%",
                      height: 200,
                      background: "#EFF0F7",
                      borderRadius: 10,
                      borderWidth: 2,
                      borderStyle: "dashed",
                      borderColor: theme.colors.lightGreen,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      marginTop: "3%",
                    }}
                  >
                    <img src={FolderImage} />
                    <input
                      {...getInputProps()}
                      id="upload-file"
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setNewCad({ ...newCad, file: [] });
                        e.preventDefault();
                        if (
                          e.target.files.length &&
                          e.target.files[0].name.split(".")[1] === "xlsx"
                        ) {
                          const reader = new FileReader();
                          reader.onload = (e) => {
                            const data = e.target.result;
                            const workbook = XLSX.read(data, { type: "array" });
                            const sheetName = workbook.SheetNames[0];
                            const worksheet = workbook.Sheets[sheetName];
                            const json = XLSX.utils.sheet_to_json(worksheet);
                            setNewCad({ ...newCad, file: json });
                          };
                          reader.readAsArrayBuffer(e.target.files[0]);
                          setInputFileLabel(
                            " 🆗 [ " +
                              e.target.files[0].name.split(".")[0] +
                              " ] carregado! "
                          );
                        } else
                          setInputFileLabel(
                            " 😕 Arquivo inválido, seleciona outro."
                          );
                      }}
                    />
                    <p
                      style={{
                        color: theme.colors.darkBlue,
                        fontSize: 20,
                        fontFamily: "Poppins",
                        marginBottom: 5,
                        marginTop: 20,
                      }}
                    >
                      {inputFileLabel}
                    </p>
                  </div>
                ),
              },
            ],
          },
          // Salvar
          {
            component: "button",
            text: "Salvar",
            onClick: async (e) => {
              let errors = [];
              if (pageView === 1) {
                const { data } = await api.business.registerPeople(newCad.file);
                if (data.data.errors.length) {
                  data.data.errors.map((err) => {
                    console.log(err);
                    errors.push(
                      //Text
                      {
                        component: "text",
                        text:
                          "Linha " +
                          err.num +
                          "; CPF: " +
                          err.code +
                          "; " +
                          err.name +
                          " " +
                          err.error,
                        style: {
                          color: "rgba(0,0,0,1)",
                          fontSize: 12,
                          fontFamily: "Poppins",
                          marginTop: "2%",
                        },
                      }
                    );
                  });
                  setShowInfo(true);
                  setInfoLines(errors);
                } else {
                  setShowInfo(false);
                  errors = [];
                }
              } else {
                // let { data } = await api.provider.register_people(person);
                if (
                  (addPerson.nome.length && addPerson.cpf.length,
                  addPerson.telefone.length && addPerson.email.length)
                ) {
                  const { data } = await api.business.registerPeople([
                    addPerson,
                  ]);
                  if (data.data.errors.length) {
                    data.data.errors.map((err) => {
                      console.log(err);
                      errors.push(
                        //Text
                        {
                          component: "text",
                          text: err.error,
                          style: {
                            color: "rgba(0,0,0,1)",
                            fontSize: 12,
                            fontFamily: "Poppins",
                            marginTop: "2%",
                          },
                        }
                      );
                    });

                    setShowInfo(true);
                    setInfoLines(errors);
                  } else {
                    setShowInfo(true);
                    errors = [];
                    setAddPerson({
                      nome: "",
                      cpf: "",
                      telefone: "",
                      email: "",
                    });
                  }
                }
              }
            },
            style: {
              fontFamily: "Poppins",
              fontSize: 20,
              fontWeight: 500,
              color: "#FFF",
              height: 45,
              width: 180,
              cursor: "pointer",
              borderRadius: 5,
              border: "1px solid",
              backgroundColor: theme.colors.pink1,
              borderColor: theme.colors.pink1,
              marginTop: "3%",
            },
          },
        ],
      },
    ],
  });
};

export default Page;
